import React, { useState } from 'react';
import AddChecklistModal from './ChecklistModal';
import PropTypes, { any } from 'prop-types';
import ActionModal from 'src/components/ActionModal';
import { useTranslation } from 'react-i18next';
import { deleteChecklist } from 'src/api/checklists/deleteChecklist';
import { useCompanyId } from 'src/api/company/useCompanyId';
import { DisplayAllChecklists } from './DisplayAllChecklists';
import useToast from 'src/hooks/useToast';
import { useLoading } from 'src/hooks';

export const ChecklistsSubView = ({
  displayAddChecklistModal,
  setDisplayAddChecklistModal,
  checklists,
  locations,
  teams,
  ...props
}) => {
  const [checklistToDelete, setChecklistToDelete] = useState(null);
  const [checklistToEdit, setChecklistToEdit] = useState(null);
  const { t } = useTranslation();
  const { onError, onSuccess } = useToast();
  const { isLoading, withLoading } = useLoading();
  const { companyId } = useCompanyId();

  const handleDeleteClick = item => {
    setChecklistToDelete(item);
  };

  const handleEditClick = item => {
    setChecklistToEdit(item);
  };

  const shouldDeleteChecklist = async () => {
    try {
      await withLoading(() => deleteChecklist({ companyId, ...checklistToDelete }));
      onSuccess(t('checklistDeleted'));
      setChecklistToDelete(null);
    } catch (error) {
      console.log(error);
      onError('Error deleting checklist');
    }
  };

  return (
    <>
      <DisplayAllChecklists
        {...props}
        onDeleteClick={handleDeleteClick}
        onEditClick={handleEditClick}
        checklists={checklists}
      />
      {displayAddChecklistModal && (
        <AddChecklistModal
          isOpen
          handleClose={setDisplayAddChecklistModal}
          locations={locations}
          teams={teams}
        />
      )}
      {checklistToDelete && (
        <ActionModal
          open
          title={t('deleteChecklistConfirmation')}
          onPrimaryButtonPress={shouldDeleteChecklist}
          onSecondaryButtonPress={() => setChecklistToDelete(null)}
          isLoading={isLoading}
        />
      )}
      {checklistToEdit && (
        <AddChecklistModal
          defaultValues={checklistToEdit}
          isOpen
          locations={locations}
          teams={teams}
          handleClose={() => setChecklistToEdit(null)}
        />
      )}
    </>
  );
};

//TODO: fix any type
ChecklistsSubView.propTypes = {
  displayAddChecklistModal: PropTypes.bool,
  setDisplayAddChecklistModal: PropTypes.func,
  checklists: any,
  locations: any,
  teams: any
};

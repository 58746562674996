import { Box, Container, Typography } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Page from 'src/components/Page';

const ExitView = () => {
  const navigate = useNavigate();

  return (
    <Page title="Sqillz - Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Typography>Welcome back</Typography>
        </Container>
      </Box>
    </Page>
  );
};

export default ExitView;

import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Users = props => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="20" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 7.25C13.63 7.25 15.07 7.64 16.24 8.15C17.32 8.63 18 9.71 18 10.88V12.5H6V10.89C6 9.71 6.68 8.63 7.76 8.16C8.93 7.64 10.37 7.25 12 7.25ZM4 7.5C5.1 7.5 6 6.6 6 5.5C6 4.4 5.1 3.5 4 3.5C2.9 3.5 2 4.4 2 5.5C2 6.6 2.9 7.5 4 7.5ZM5.13 8.6C4.76 8.54 4.39 8.5 4 8.5C3.01 8.5 2.07 8.71 1.22 9.08C0.48 9.4 0 10.12 0 10.93V12.5H4.5V10.89C4.5 10.06 4.73 9.28 5.13 8.6ZM20 7.5C21.1 7.5 22 6.6 22 5.5C22 4.4 21.1 3.5 20 3.5C18.9 3.5 18 4.4 18 5.5C18 6.6 18.9 7.5 20 7.5ZM24 10.93C24 10.12 23.52 9.4 22.78 9.08C21.93 8.71 20.99 8.5 20 8.5C19.61 8.5 19.24 8.54 18.87 8.6C19.27 9.28 19.5 10.06 19.5 10.89V12.5H24V10.93ZM12 0.5C13.66 0.5 15 1.84 15 3.5C15 5.16 13.66 6.5 12 6.5C10.34 6.5 9 5.16 9 3.5C9 1.84 10.34 0.5 12 0.5Z" fill="#6E26FC" />
      </svg>
    </SvgIcon>
  );
};

export default Users;

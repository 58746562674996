import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID
};

export const app = firebase.initializeApp(firebaseConfig);

export var firestore = app.firestore();
export var functions = app.functions('europe-west1');

export const sqillzUsers = firestore.collection('users');

export const getCompanyRef = companyId =>
  firestore.collection('companies').doc(companyId);

export const getCourseRef = companyId =>
  getCompanyRef(companyId).collection('courses');

export const auth = app.auth();

// if (location.hostname === 'localhost') {
//   auth.useEmulator('http://localhost:9099');
//   firestore.useEmulator('localhost', 8080);
// }

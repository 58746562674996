import { useEffect, useState } from 'react';
import { getCompanyRef } from 'src/api/firebase';
import { useCompanyId } from './useCompanyId';

const useCompanyObserver = () => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const { companyId } = useCompanyId();

  useEffect(() => {
    if (!companyId) return;
    const root = getCompanyRef(companyId);

    const observeCompany = observer => {
      return root.onSnapshot(observer);
    };

    const unsubscribe = observeCompany({
      next: async snap => {
        setLoading(true);

        const data = snap.data();
        setData({ ...data, id: snap.id });

        setLoading(false);
      },
      error: setError
    });
    return unsubscribe;
  }, [companyId]);

  return [data, isLoading, error];
};

export default useCompanyObserver;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MUITabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { any, arrayOf, func, node, number, object, string } from 'prop-types';
import { Badge, Box } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  tabsRoot: {
    flex: 1,
    minHeight: 0
  },
  tabsContainer: {
    display: 'flex'
  },
  tabIndicator: {
    display: 'flex',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 24,
      width: '100%',
      backgroundColor: '#6E26FC;'
    }
  },
  badge: {
    right: 16,
    top: 20
  },
  tabRoot: {
    textTransform: 'none',
    color: theme.palette.grey[400],
    padding: 0,
    minWidth: 0,
    minHeight: 0,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(18),
    marginRight: theme.spacing(3),
    '&:focus': {
      opacity: 1
    },
    '&$selected': {
      color: theme.palette.grey[99]
    }
  },
  selected: {}
}));

const TabWithBadge = ({ tab, index, badgeContent, ...props }) => {
  const classes = useStyles();
  return (
    <Badge
      key={tab}
      variant="dot"
      badgeContent={badgeContent || 0}
      color="error"
      classes={{ badge: classes.badge }}
    >
      <Tab
        key={tab}
        disableRipple
        onClick={event => {
          event.preventDefault();
        }}
        label={tab}
        classes={{
          root: classes.tabRoot,
          selected: classes.selected
        }}
        {...props}
      />
    </Badge>
  );
};

const LESSONS = 0;
const LEARNERS = 1;
const SETTINGS = 2;
const RATINGS = 3;

const Tabs = ({
  tabs,
  value,
  handleChange,
  badgeContent,
  tabsSeen,
  children,
  containerStyle
}) => {
  const classes = useStyles();
  const { lessonsSeen, learnersSeen, settingsSeen, ratingsSeen } =
    tabsSeen || {};

  const shouldShowBadge = index => {
    switch (index) {
      case LESSONS:
        return lessonsSeen;
      case LEARNERS:
        return learnersSeen;
      case SETTINGS:
        return settingsSeen;
      case RATINGS:
        return ratingsSeen;
      default:
        return false;
    }
  };

  return (
    <Box pb={1} pt={0} className={clsx(classes.tabsContainer, containerStyle)}>
      <MUITabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          children: <span />
        }}
        classes={{
          root: classes.tabsRoot,
          indicator: classes.tabIndicator
        }}
      >
        {tabs.map((tab, index) => (
          <TabWithBadge
            key={tab}
            tab={tab}
            index={index}
            // badgeContent={tabsSeen ? !shouldShowBadge(index) : false}
          />
        ))}
      </MUITabs>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        flex={1}
      >
        {children}
      </Box>
    </Box>
  );
};

Tabs.propTypes = {
  tabs: arrayOf(string),
  handleChange: func,
  value: any.isRequired,
  badgeContent: any,
  tab: string,
  index: number,
  tabsSeen: object,
  children: node,
  containerStyle: string
};

TabWithBadge.propTypes = {
  tab: string,
  index: number,
  badgeContent: number
};

Tabs.defaultProps = {
  lessonsSeen: true,
  ratingSeen: true,
  settingsSeen: true,
  learnersSeen: true
};

export default Tabs;

import React, { useContext, useEffect, useRef } from 'react';
import { Box, useTheme } from '@material-ui/core';
import Page from 'src/components/Page';
import { AuthContext } from 'src/providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import LoadingIndicator from 'src/components/LoadingIndicator/LoadingIndicator';
import SqillzLogo from 'src/icons/SqillzLogo';

const LoadingView = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const userRef = useRef();
  userRef.current = currentUser;

  const theme = useTheme();

  useEffect(() => {
    setTimeout(() => {
      if (userRef.current) {
        navigate('/app');
      }

      if (userRef.current == null) {
        navigate('/login');
      }
    }, 2000);
  }, [currentUser]);

  return (
    <Page title="Sqillz - Admin">
      <Box
        display="flex"
        height="100vh"
        justifyContent="center"
        alignItems="center"
        bgcolor={theme.palette.background.dark}
        flexDirection="column"
      >
        <SqillzLogo />
        <Box mt={2}>
          <LoadingIndicator />
        </Box>
      </Box>
    </Page>
  );
};

export default LoadingView;

import React from 'react';
import PropTypes, { any, bool } from 'prop-types';
import {
  Box,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField
} from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import Remove from 'src/icons/Remove';
import clsx from 'clsx';
import { useController } from 'react-hook-form';
import CounterText from './CounterText/CounterText';

const useStyles = makeStyles(theme => ({
  textfield: {
    borderRadius: 8,
    background: 'white',
    border: `1px solid ${theme.palette.primary.surface}`,
    padding: theme.spacing(2),
    color: theme.palette.secondary.main,
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'Inter',
    lineHeight: 20,
    '& input::placeholder': {
      color: theme.palette.grey[400],
      opacity: 1,
      fontSize: 14,
      fontWeight: 500,
      fontFamily: 'Inter'
    },
    '& input': {
      padding: '1.5px 0px'
    }
  },
  inputRoot: {
    display: 'flex'
  },
  inputText: {
    fontSize: 16,
    fontWeight: 400,
    paddingTop: 15
  },
  textFieldSingle: {
    display: 'block',
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    width: '100%',
    borderRadius: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: 52,
    border: `1px solid ${theme.palette.primary.surface}`
  },
  textFieldMulti: {
    height: 'auto',
    width: '100%'
  }
}));

const TextInput = ({
  placeholder,
  deleteButton,
  onClickRemoveAlternative,
  textFieldStyle,
  name,
  control,
  defaultValue,
  rules,
  multiline,
  ...rest
}) => {
  const classes = useStyles();
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { isTouched, isDirty },
    formState
  } = useController({
    name,
    control,
    rules: { required: true, ...rules },
    defaultValue
  });

  return multiline ? (
    <Box>
      <TextField
        {...rest}
        defaultValue={defaultValue}
        inputRef={ref}
        onChange={onChange}
        onBlur={onBlur}
        multiline
        rows={4}
        name={name}
        placeholder={placeholder}
        InputProps={{
          disableUnderline: true,
          classes: { input: classes.inputText, root: classes.inputRoot }
        }}
        className={[classes.textFieldSingle, classes.textFieldMulti]}
      />
      <Box mt={1}>
        <CounterText>{`${value?.length ?? 0}/${rules.maxLength}`}</CounterText>
      </Box>
    </Box>
  ) : (
    <InputBase
      {...rest}
      inputRef={ref}
      onChange={onChange}
      onBlur={onBlur}
      name={name}
      defaultValue={defaultValue}
      classes={{
        root: clsx(classes.textfield, textFieldStyle)
      }}
      placeholder={placeholder}
      endAdornment={
        deleteButton ? (
          <InputAdornment position="end">
            <IconButton
              style={{ display: 'flex', justifyContent: 'center' }}
              onClick={onClickRemoveAlternative}
              aria-label="remove question alternative"
            >
              <Remove />
            </IconButton>
          </InputAdornment>
        ) : (
          undefined
        )
      }
      fullWidth
    />
  );
};

TextInput.propTypes = {
  placeholder: PropTypes.string,
  textFieldStyle: PropTypes.string,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  deleteButton: PropTypes.bool,
  multiline: bool,
  onClickRemoveAlternative: PropTypes.func,
  onChange: PropTypes.func,
  control: any,
  rules: any
};

export default TextInput;

import React, { useState, useEffect } from 'react';
import { bool, func, string } from 'prop-types';
import { Box, Typography, useTheme } from '@material-ui/core';
import Backdrop from './BackDrop';
import { useTranslation } from 'react-i18next';
import InputVerificationCode from 'src/components/InputVerificationCode/InputVerificationCode';
import { useCountdown } from 'src/hooks/useCountdown';
import SqillzLogo from 'src/icons/SqillzLogo';

export const ValidateSMS = ({
  isLoading,
  number,
  onCodeFulfill,
  onCodeResend
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const theme = useTheme();
  const { countDown } = useCountdown(60, () => {
    handleResendCode();
  });

  useEffect(() => {
    const hasEnteredValidationCode = value.length === 6 && !value.includes('·');
    if (hasEnteredValidationCode) {
      onCodeFulfill(value);
    }
  }, [value]);

  const handleResendCode = () => {
    onCodeResend();
  };

  return (
    <>
      <Box position="absolute" left={80} top={80}>
        <SqillzLogo />
      </Box>
      {isLoading && <Backdrop message={t('verifyingCode')} />}
      <div>
        <Typography variant="h4" color="textPrimary">
          {t('SMSwasSentTo')}
        </Typography>
        <Typography variant="body2" color="primary">
          {number}
        </Typography>
        <Box py={7}>
          <InputVerificationCode
            autoFocus
            length={2}
            onChange={setValue}
            value={''}
          />
        </Box>
        <Typography
          style={{
            color: theme.palette.text.hint,
            fontSize: 12,
            fontFamily: 'Inter',
            fontWeight: 500
          }}
        >
          {t('sendSMSagain')}{' '}
          <Typography
            display="inline"
            color="textPrimary"
            style={{
              fontSize: 12,
              fontFamily: 'Inter',
              fontWeight: 500
            }}
          >
            {`${countDown}`}
          </Typography>
        </Typography>
      </div>
    </>
  );
};

ValidateSMS.propTypes = {
  number: string,
  onCodeFulfill: func,
  onCodeResend: func,
  isLoading: bool
};

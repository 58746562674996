import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';
import PropTypes, { any } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCompanyId } from 'src/api/company/useCompanyId';
import Backdrop from 'src/views/auth/sms-auth/BackDrop';
import Modal from '../Modal';
import List from './List';

const useStyles = makeStyles(theme => ({}));

const BulkUploadModal = ({
  isOpen,
  title,
  handleClose,
  defaultValues,
  result,
  onClickSave,
  isUploading,
  isFormValid
}) => {
  const theme = useTheme();
  const { companyId } = useCompanyId();
  const classes = useStyles();
  const { t } = useTranslation();

  const columns = [
    { name: 'name' },
    { name: 'phone' },
    { name: 'userRole' },
    { name: 'isNewlyEmployed' }
  ];

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      handleSave={onClickSave}
      handleClose={handleClose}
      width={8}
      isFormValid={isFormValid}
      navBarRightButtonTitle={t('uploadSuccess')}
    >
      {isUploading && <Backdrop message={t('addingUsers')} />}
      <Box display="flex" mt={2} mb={8}>
        <Typography variant="h4" style={{ color: theme.palette.grey[300] }}>
          {t('bulkSuccessCount', { count: result.success.length })}
        </Typography>
        <Box mx={2} />
        <Typography variant="h4" color="textPrimary">
          {t('bulkFailedCount', { count: result.failed.length })}
        </Typography>
      </Box>
      <List columns={columns} data={result.failed} />
    </Modal>
  );
};

BulkUploadModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  introText: PropTypes.string,
  handleClose: PropTypes.func,
  onClickSave: PropTypes.func,
  addLearerSpinner: PropTypes.bool,
  isUploading: PropTypes.bool,
  isFormValid: PropTypes.bool,
  defaultValues: any,
  data: any,
  result: any
};

export default BulkUploadModal;

import { getCompanyRef } from 'src/api/firebase';
import { getTimestamp } from '../utils';

export const editLocation = ({ companyId, locationId, dataToUpdate }) => {
  const root = getCompanyRef(companyId);
  const ref = root.collection('locations');

  const newData = JSON.parse(JSON.stringify(dataToUpdate));
  newData.updatedAt = getTimestamp();

  return ref.doc(locationId).set(newData, { merge: true });
};

import React from 'react';
import { SvgIcon } from '@material-ui/core';

const FormatBold = props => {
  return (
    <SvgIcon {...props}>
      <path
        d="M16.0671 11.6583C16.8754 11.1 17.4421 10.1833 17.4421 9.33333C17.4421 7.45 15.9837 6 14.1087 6H8.90039V17.6667H14.7671C16.5087 17.6667 17.8587 16.25 17.8587 14.5083C17.8587 13.2417 17.1421 12.1583 16.0671 11.6583ZM11.4004 8.08333H13.9004C14.5921 8.08333 15.1504 8.64167 15.1504 9.33333C15.1504 10.025 14.5921 10.5833 13.9004 10.5833H11.4004V8.08333ZM14.3171 15.5833H11.4004V13.0833H14.3171C15.0087 13.0833 15.5671 13.6417 15.5671 14.3333C15.5671 15.025 15.0087 15.5833 14.3171 15.5833Z"
        fill="#181441"
      />
    </SvgIcon>
  );
};

export default FormatBold;

import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, InputBase, makeStyles } from '@material-ui/core';
import Search from '../icons/Search';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  input: {
    fontSize: 12,
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    height: 40,
    padding: theme.spacing(0, 2),
    width: '100%',
    display: 'flex',
    '& input::placeholder': {
      color: theme.palette.grey[400],
      opacity: 1,
      fontSize: 12,
      fontWeight: 500,
      fontFamily: 'Inter'
    }
  },
  icon: {
    fontSize: 18
  }
}));

const SearchInput = ({ onChange, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <InputBase
      className={classes.input}
      placeholder={t('search')}
      onChange={event => onChange(event.target.value)}
      startAdornment={
        <InputAdornment position="start" style={{ marginTop: 2 }}>
          <Search className={classes.icon} />
        </InputAdornment>
      }
      {...rest}
    />
  );
};

SearchInput.propTypes = {
  onChange: PropTypes.func
};

export default SearchInput;

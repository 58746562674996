export default {
  h1: {
    fontWeight: 600,
    fontSize: 39,
    color: '#181441'
  },
  h2: {
    fontWeight: 600,
    fontSize: 32,
    color: '#0B0B0C'
  },
  h3: {
    fontWeight: 600,
    fontSize: 24,
    color: '#85839B'
  },
  h4: {
    fontWeight: 600,
    fontSize: 18,
    color: '#181441'
  },
  h5: {
    fontWeight: 700,
    fontSize: 14,
    color: '#181441'
  },
  h6: {
    fontWeight: 600,
    fontSize: 14,
    color: '#323138'
  },
  body1: {
    fontWeight: 500,
    fontSize: 16,
    color: '#0B0B0C;'
  },

  body2: {
    fontSize: 12,
    color: '#9A98A3',
    fontFamily: 'Inter',
    fontWeight: 500
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: 14,
    color: '#85839B',
    textTransform: 'none'
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: 14,
    color: '#323138',
    textTransform: 'none'
  },
  placeholder: {
    fontWeight: 500,
    fontSize: 14,
    fontFamily: 'Inter',
    color: '#9A98A3'
  },
  overline: {
    fontWeight: 500,
    color: '#181441'
  },
  fontFamily: ['Inter']
};

import { firestoreArrayRemove } from '../utils';
export const removeStreamFromLocation = ({
  batch,
  locationRef,
  streamDocId
}) => {
  batch.set(
    locationRef,
    {
      streams: firestoreArrayRemove(streamDocId)
    },
    { merge: true }
  );
};

import { firestore, getCompanyRef } from 'src/api/firebase';
import { getTimestamp } from '../utils';
import firebase from 'firebase/app';

export const editQuickPost = ({
  companyId,
  showAsHero,
  defaultStream,
  ...post
}) => {
  var batch = firestore.batch();
  const root = getCompanyRef(companyId);

  const postDocId = post.id;
  const postRef = root.collection('posts').doc(postDocId);

  const editPost = () => {
    const updatedPost = {
      ...post,
      isHero: showAsHero,
      updatedAt: getTimestamp(),
      status: ''
    };

    batch.set(postRef, updatedPost, { merge: true });
  };

  const updateStreamPostsCount = ({ streamId, shouldIncreaseCount }) => {
    const streamRef = root.collection('streams').doc(streamId);
    batch.set(
      streamRef,
      {
        postsCount: firebase.firestore.FieldValue.increment(
          shouldIncreaseCount ? 1 : -1
        )
      },
      { merge: true }
    );
  };

  const updateOldStream = id => {
    updateStreamPostsCount({ streamId: id, shouldIncreaseCount: false });
  };

  const updateNewStream = id => {
    updateStreamPostsCount({ streamId: id, shouldIncreaseCount: true });
  };

  if (post.stream && post.defaultStream) {
    updateOldStream(post.defaultStream.id);
    updateNewStream(post.stream.id);
  }
  editPost();

  // return new Promise(res => res('ok'));
  return batch.commit();
};

import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PeopleReadingPhonesIllustration } from 'src/components/Illustrations/PeopleReadingPhonesIllustration';

const useStyles = makeStyles(theme => ({
  emptyQueue: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.spacing(8),
    alignItems: 'center'
  },
  textSection: {
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(1)
    },
    maxWidth: 352,
    marginLeft: theme.spacing(4)
  }
}));

const NoTeamsAdded = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.emptyQueue}>
      <PeopleReadingPhonesIllustration />
      <div className={classes.textSection}>
        <Typography variant="h4">{t('noTeams')}</Typography>
      </div>
    </div>
  );
};

export default NoTeamsAdded;

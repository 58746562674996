import React, { useEffect } from 'react';
import { CircularProgress, ThemeProvider } from '@material-ui/core';
import { CloudinaryContext } from 'cloudinary-react';
import GlobalStyles from 'src/components/GlobalStyles';
import Routes from 'src/routes/routes';
import theme from 'src/theme';
import { ToastifyConfigurations } from './components/Toast/ToastifyConfigurations';
import './utils/prototypes';
import { AuthProvider } from './providers';

const App = () => {
  useEffect(() => {
    window.process = {
      ...window.process
    };
  }, []);

  return false ? (
    <CircularProgress color="primary" />
  ) : (
    <CloudinaryContext cloudName="dwyuadnmg">
      <ToastifyConfigurations />
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Routes />
        </ThemeProvider>
      </AuthProvider>
    </CloudinaryContext>
  );
};

export default App;

/* eslint-disable no-unused-vars */
import React from 'react';
import { Navigate, Route, Routes as DOMRoutes } from 'react-router-dom';
import DashboardLayout from 'src/layouts/dashboard';
import MainLayout from 'src/layouts/main';
import InsightsView from '../views/insights/InsightsView';
import SettingsView from '../views/settings/SettingsView';
import ExitView from '../views/auth/ExitView';
import AuthorizedRoute from './AuthorizedRoute';
import LoadingView from '../views/auth/LoadingView';
import IndexView from '../components/IndexView';
import { useTranslation } from 'react-i18next';
import { ContentView } from '../views/content/ContentView';
import { ChecklistsView } from '../views/checklists/ChecklistsView';
import { SMSAuthView } from '../views/auth/sms-auth/SMSAuthView';
import { NumberNotFoundView } from '../views/auth/sms-auth/NumberNotFoundView';
import { OnBoardingSubView } from '../views/insights/InsightsView/subview/OnBoardingSubView';
import { OnBoardingSubDetailsView } from '../views/insights/InsightsView/subview/OnBoardingSubDetailsView';
import { ActivatedUsers } from '../views/insights/InsightsView/activated-users/ActivatedUsers';
import { Completion } from '../api/insights/completion/Completion';
import LocationView from 'src/views/organization/LocationView/LocationView';
import { OrganizationView } from 'src/views/organization/OrganizationView';
import TeamView from 'src/views/organization/TeamView/TeamView';
import LocationIndexView from 'src/views/organization/LocationView/LocationIndexView';

const Routes = () => {
  const { t } = useTranslation();
  return (
    <DOMRoutes>
      <Route path="/" element={<MainLayout />}>
        <Route path="/" element={<LoadingView />} />
        <Route path="/login" element={<SMSAuthView />} />
        <Route
          path="/login/not-found/:phone"
          element={<NumberNotFoundView />}
        />
        <Route path="/exit" element={<ExitView />} />
      </Route>
      <AuthorizedRoute path="/app" element={<DashboardLayout />}>
        <Route path="/" element={<Navigate to="insights" />} />
        <Route path="/organization" element={<IndexView title={t('orgs')} />}>
          <Route path="/" element={<OrganizationView />} />
          <Route path="/teams/:team" element={<TeamView />} />
          <Route path="/locations" element={<LocationIndexView />}>
            <Route path="/:location" element={<LocationView />} />
            <Route path="/:location/teams/:team" element={<TeamView />} />
          </Route>
        </Route>
        <Route path="/insights" element={<IndexView title={t('insights')} />}>
          <Route path="/" element={<InsightsView />} />
          <Route path="/onboarding" element={<OnBoardingSubView />} />
          <Route
            path="/onboarding/streamdetail"
            element={<OnBoardingSubDetailsView />}
          />
          <Route path="/activatedusers" element={<ActivatedUsers />} />
          <Route path="/completion" element={<Completion />} />
        </Route>
        <Route path="/settings" element={<IndexView title={t('settings')} />}>
          <Route path="/" element={<SettingsView />} />
        </Route>
        <Route path="/content" element={<IndexView title={t('content')} />}>
          <Route path="/" element={<ContentView />} />
        </Route>
        <Route path="/checklists" element={<IndexView title={t('checklists')} />}>
          <Route path="/" element={<ChecklistsView />} />
        </Route>
      </AuthorizedRoute>
    </DOMRoutes>
  );
};

export default Routes;

import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Check = props => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_4909_26033)">
        <path d="M9.00016 16.1698L4.83016 11.9998L3.41016 13.4098L9.00016 18.9998L21.0002 6.99984L19.5902 5.58984L9.00016 16.1698Z" />
      </g>
      <defs>
        <clipPath id="clip0_4909_26033">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Check;

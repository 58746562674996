import React from 'react';
import { SvgIcon } from '@material-ui/core';

const FormatUnorderedList = props => {
  return (
    <SvgIcon {...props}>
      <path
        d="M5.55078 11C4.85911 11 4.30078 11.5583 4.30078 12.25C4.30078 12.9417 4.85911 13.5 5.55078 13.5C6.24245 13.5 6.80078 12.9417 6.80078 12.25C6.80078 11.5583 6.24245 11 5.55078 11ZM5.55078 6C4.85911 6 4.30078 6.55833 4.30078 7.25C4.30078 7.94167 4.85911 8.5 5.55078 8.5C6.24245 8.5 6.80078 7.94167 6.80078 7.25C6.80078 6.55833 6.24245 6 5.55078 6ZM5.55078 16C4.85911 16 4.30078 16.5667 4.30078 17.25C4.30078 17.9333 4.86745 18.5 5.55078 18.5C6.23411 18.5 6.80078 17.9333 6.80078 17.25C6.80078 16.5667 6.24245 16 5.55078 16ZM8.05078 18.0833H19.7174V16.4167H8.05078V18.0833ZM8.05078 13.0833H19.7174V11.4167H8.05078V13.0833ZM8.05078 6.41667V8.08333H19.7174V6.41667H8.05078Z"
        fill="#181441"
      />
    </SvgIcon>
  );
};

export default FormatUnorderedList;

import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Drawer, List, makeStyles } from '@material-ui/core';
import Organization from 'src/icons/Organization';
import Content from 'src/icons/Content';
import Checklists from 'src/icons/Checklists';
import Settings from 'src/icons/Settings';
import Logo from 'src/components/Logo';
import { auth } from 'src/api/firebase';
import { useTranslation } from 'react-i18next';
import Logout from 'src/icons/Logout';
import Insights from 'src/icons/Insights';
import { bool, func } from 'prop-types';
import NavItem from './NavItem';

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: 194
  },
  desktopDrawer: {
    width: 220,
    height: '100%',
    backgroundColor: theme.palette.background.dark
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const signOut = async () => {
    try {
      await auth.signOut();
    } catch (error) {}
  };

  const { t } = useTranslation();

  const items = [
    {
      href: '/app/insights',
      title: t('insights'),
      icon: Insights
    },
    // {
      //   href: '/app/teams',
      //   title: t('teams'),
      //   icon: Organization
      // },
      {
        href: '/app/checklists',
        title: t('checklists'),
        icon: Checklists
      },
      {
        href: '/app/content',
        title: t('content'),
        icon: Content
      },
      {
        href: '/app/organization',
        title: t('orgs'),
        icon: Organization
      },
      {
        href: '/app/settings',
        title: t('settings'),
        icon: Settings
      },
    /*     {
          href: '/app/courses',
          title: 'TBR - ' + t('courses'),
          icon: Courses
        },
        {
          href: '/app/flashcards',
          title: 'TBR - ' + t('flashcards'),
          icon: FlashIcon
        },
        {
          href: '/app/polls',
          title: 'TBR - ' + t('polls'),
          icon: Polls
        }, */
  ];

  const content = (
    <Box height="90%" display="flex" flexDirection="column" marginTop="20%">
      <Box
        pt={9}
        pb={7}
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Link to="/app/insights">
          <Logo style={{ marginBottom: 30, marginTop: 30 }} />
        </Link>
      </Box>
      <Box pr={3}>
        <List>
          {items.map(item => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box display="flex" flexGrow={1} />
      <NavItem title={t('logOut')} onClick={signOut} icon={Logout} href={'*'} />
    </Box>
  );

  return (
    <>
      <Drawer
        anchor="left"
        classes={{ paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
      >
        {content}
      </Drawer>
      {/* <Hidden mdDown> */}
      <Drawer
        anchor="left"
        classes={{ paper: classes.desktopDrawer }}
        open
        variant="persistent"
      >
        {content}
      </Drawer>
      {/* </Hidden> */}
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: func,
  openMobile: bool
};

export default NavBar;

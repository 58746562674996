/* eslint-disable react/prop-types */
import React, { useState, useMemo } from 'react';
import { Box, makeStyles, Grid } from '@material-ui/core';
import PropTypes, { any } from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCompanyId } from 'src/api/company/useCompanyId';
import ActionModal from 'src/components/ActionModal';
import Modal from 'src/components/Modals/Modal';
import TextFieldSection from 'src/components/TextFieldSection';
import { editChecklist } from 'src/api/checklists/editChecklist';
import { addChecklist } from 'src/api/checklists/addChecklist';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicator from '../../../../icons/DragIndicator';
import TaskCard from './TaskCard';
import useToast from 'src/hooks/useToast';
import {
  useAllLocationsOption,
  useAllTeamsOption,
  useLoading
} from 'src/hooks';
import TextFieldTitle from 'src/components/TextFieldTitle';
import IconLabelButton from 'src/components/Buttons/IconLabelButton';

const useStyles = makeStyles(theme => ({
  textFieldSection: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  textFieldTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  textFieldPlacement: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  checkBoxSection: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3.5),
    marginTop: theme.spacing(3.5)
  },
  dropdownContainer: {
    background: theme.palette.common.white,
    borderRadius: 8,
    border: `1px solid ${theme.palette.primary.surface}`,
    padding: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'space-between'
  },
  dropdownMenuItem: {
    color: theme.palette.secondary.main,
    fontSize: 14,
    fontWeight: 400,
    padding: theme.spacing(-2),
    minWidth: 550
  },
  gridContainer: {
    marginBottom: theme.spacing(3)
  },
  gridItem: {
    width: '100%',
    marginBottom: -24
  },
  taskContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    left: -38
  },
  dragIndicator: {
    cursor: 'grab',
    marginRight: 16
  },
  listItemTextClasses: {
    textTransform: 'none'
  }
}));

const frequencyData = [
  { id: 0, name: 'When needed' },
  { id: 1, name: 'Daily' },
  { id: 7, name: 'Weekly' },
  { id: 30, name: 'Monthly' },
  { id: 365, name: 'Yearly' }
];

const notificationFrequencyData = [
  { id: 0, name: 'Never' },
  { id: 1, name: 'When checklist is submitted' },
  { id: 2, name: 'When tasks are not ok' },
  { id: 3, name: 'When tasks are not ok or has comments' }
];

const reorder = (list, startIndex, endIndex) => {
  const result = list.map(x => x);
  const removed = result.splice(startIndex, 1)[0];
  result.splice(endIndex, 0, removed);

  return result;
};

//generate a 20 character random string
const generateRandomString = () => {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < 20; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const Task = ({ task, onTaskChange, onClickDeleteTask, index }) => {
  const classes = useStyles();
  return (
    <Draggable draggableId={task.draggableId} index={index}>
      {provided => (
        <Box
          className={classes.taskContainer}
          flexDirection="column"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div {...provided.dragHandleProps} className={classes.dragIndicator}>
            <DragIndicator />
          </div>
          <TaskCard
            index={index}
            key={task.draggableId}
            task={task}
            onTaskChange={value => onTaskChange(value, index)}
            onClickDeleteTask={onClickDeleteTask}
            titlePlaceholder="Task name..."
            descriptionPlaceholder="Description..."
          />
        </Box>
      )}
    </Draggable>
  );
};

const TaskList = React.memo(function TaskList(props) {
  // eslint-disable-next-line react/prop-types
  const { tasks, onTaskChange, onClickDeleteTask } = props;
  // eslint-disable-next-line react/prop-types
  return tasks.map((task, index) => (
    <Task
      key={task.draggableId}
      task={task}
      onTaskChange={onTaskChange}
      onClickDeleteTask={onClickDeleteTask}
      index={index}
    />
  ));
});

const ChecklistModal = ({
  isOpen,
  handleClose,
  defaultValues,
  locations,
  teams
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { companyId } = useCompanyId();
  const { isLoading, withLoading } = useLoading();
  const {
    isLoading: isLoadingDraft,
    withLoading: withLoadingDraft
  } = useLoading();
  const { onError, onSuccess } = useToast();
  const [isActionModalOpen, setActionModalOpen] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [
    isSaveBeforeLeavingModalOpen,
    setIsSaveBeforeLeavingModalOpen
  ] = useState(false);

  const allTeamsOption = useAllTeamsOption();
  const allLocationsOption = useAllLocationsOption();

  //Prepare tasks with draggable id
  defaultValues?.tasks.map(task => (task.draggableId = task.id));

  // Frequency
  const defaultFrequency = defaultValues
    ? [frequencyData.find(item => item.id === defaultValues.checklistFrequency)]
    : [];

  // Access locations
  const defaultAccessLocations = locations?.filter(item =>
    defaultValues?.locationIds?.includes(item.id)
  );
  if (defaultValues?.allLocationsAccess) {
    defaultAccessLocations.push(allLocationsOption);
  }

  // Access teams
  const defaultAccessTeams = teams.filter(item =>
    defaultValues?.teamIds?.includes(item.id)
  );
  if (defaultValues?.allTeamsAccess) {
    defaultAccessTeams.push(allTeamsOption);
  }

  // Notifications frequency
  const defaultNotificationFrequency = defaultValues
    ? [
        notificationFrequencyData.find(
          item => item.id === defaultValues.notificationFrequency.frequency
        )
      ]
    : [];

  // Notifications teams
  const defaultNotificationTeams = teams.filter(item =>
    defaultValues?.notificationFrequency?.teamIds.includes(item.id)
  );
  if (
    defaultValues?.notificationFrequency?.teamIds.includes(allTeamsOption.id)
  ) {
    defaultNotificationTeams.push(allTeamsOption);
  }

  // Sort Tasks
  const defaultTasks = defaultValues?.tasks.sort((a, b) => a.index - b.index);

  const [selectedFrequency, setSelectedFrequency] = useState(defaultFrequency);
  const [selectedAccessLocations, setSelectedAccessLocations] = useState(
    defaultAccessLocations ?? []
  );
  const [selectedAccessTeams, setSelectedAccessTeams] = useState(
    defaultAccessTeams ?? []
  );
  const [
    selectedNotificationFrequency,
    setSelectedNotificationFrequency
  ] = useState(defaultNotificationFrequency);
  const [selectedNotificationTeams, setSelectedNotificationTeams] = useState(
    defaultNotificationTeams ?? []
  );
  const [tasks, setTasks] = useState(
    defaultTasks ?? [{ draggableId: generateRandomString(), index: 0 }]
  );

  const isTasksValid =
    tasks.length > 0 && tasks.every(task => task.title?.trim().length > 0);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { isValid, isDirty, dirtyFields, errors },
    control,
    getFieldState
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: useMemo(
      () => ({
        title: defaultValues?.title || '',
        description: defaultValues?.description || '',
        frequency: selectedFrequency,
        accessLocations: selectedAccessLocations,
        accessTeams: selectedAccessTeams,
        notificationFrequency: selectedNotificationFrequency,
        notificationTeams: selectedNotificationTeams,
        tasks: tasks
      }),
      [
        selectedFrequency,
        selectedAccessLocations,
        selectedAccessTeams,
        selectedNotificationFrequency,
        selectedNotificationTeams,
        tasks
      ]
    )
  });

  const checkIfFieldIsValid = field => {
    const fieldState = getFieldState(field);

    if (defaultValues) {
      return !getFieldState(field).error;
    }
    return !getFieldState(field).isDirty ? false : !getFieldState(field).error;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newTaskOrder = reorder(
      tasks,
      result.source.index,
      result.destination.index
    );

    setTasks(newTaskOrder);
    setValue('tasks', newTaskOrder, {
      shouldValidate: true,
      shouldDirty: true
    });
  }

  const handleEditChecklist = async dataToUpdate => {
    try {
      await editChecklist({ companyId, defaultTasks, ...dataToUpdate });
      onSuccess(t('checklistEdited'));
      handleClose();
    } catch (error) {
      console.log({ error });
      onError(t('checklistEditError'), error.message);
    }
  };

  const shouldCreateChecklist = async data => {
    await addChecklist({ companyId, ...data });
    onSuccess(t('checklistCreated'));
    handleClose();
  };

  const extractChangedFields = (data, changedFields) => {
    const dataToUpdate = { id: defaultValues.id };
    changedFields.forEach(item => {
      dataToUpdate[item] = data[item];
    });
    return dataToUpdate;
  };

  const onSubmit = async (data, saveAsDraft = false) => {
    const isEditing = Boolean(defaultValues);
    const areAllAccessLocationsSelected = checkAllAccessLocationsSelected(
      data.accessLocations
    );
    const areAllAccessTeamsSelected = checkAllAccessTeamsSelected(
      data.accessTeams
    );

    try {
      if (isEditing) {
        const changedFields = Object.keys(dirtyFields);
        const dataToUpdate = extractChangedFields(data, changedFields);

        if (areAllAccessLocationsSelected) {
          dataToUpdate.accessLocations = filterAllLocationsOption(
            data.accessLocations
          );
        }

        if (areAllAccessTeamsSelected) {
          dataToUpdate.accessTeams = filterAllTeamsOption(data.accessTeams);
        }

        if (saveAsDraft) {
          dataToUpdate.isDraft = true;
          dataToUpdate.isPublished = false;
          await withLoadingDraft(() =>
            handleEditChecklist({
              id: defaultValues.id,
              allLocationsAccess: areAllAccessLocationsSelected,
              allTeamsAccess: areAllAccessTeamsSelected,
              ...dataToUpdate
            })
          );
        } else {
          dataToUpdate.isDraft = false;
          dataToUpdate.isPublished = true;
          await withLoading(() =>
            handleEditChecklist({
              id: defaultValues.id,
              allLocationsAccess: areAllAccessLocationsSelected,
              allTeamsAccess: areAllAccessTeamsSelected,
              ...dataToUpdate
            })
          );
        }
      } else {
        if (areAllAccessTeamsSelected) {
          data.accessTeams = filterAllTeamsOption(data.accessTeams);
        }

        if (areAllAccessLocationsSelected) {
          data.accessLocations = filterAllLocationsOption(data.accessLocations);
        }

        if (saveAsDraft) {
          data.isDraft = true;
          data.isPublished = false;
          await withLoadingDraft(() =>
            shouldCreateChecklist({
              ...data,
              allLocationsAccess: areAllAccessLocationsSelected,
              allTeamsAccess: areAllAccessTeamsSelected
            })
          );
        } else {
          await withLoading(() =>
            shouldCreateChecklist({
              ...data,
              allLocationsAccess: areAllAccessLocationsSelected,
              allTeamsAccess: areAllAccessTeamsSelected
            })
          );
        }
      }
    } catch (error) {
      console.log({ error });
      onError(t('checklistCreateError'));
    }
  };

  const checkAllAccessTeamsSelected = teams =>
    teams.some(item => item.id === allTeamsOption.id);

  const checkAllAccessLocationsSelected = locations =>
    locations.some(item => item.id === allLocationsOption.id);

  const filterAllTeamsOption = teams =>
    teams.filter(item => item.id !== allTeamsOption.id);

  const filterAllLocationsOption = locations =>
    locations.filter(item => item.id !== allLocationsOption.id);

  const onTaskChange = (task, index) => {
    const newTasks = [...tasks];
    newTasks[index] = task;

    setTasks(newTasks);
    setValue('tasks', newTasks, { shouldValidate: true, shouldDirty: true });
  };

  // Frequency
  const onClickChooseFrequency = frequency => {
    setValue('frequency', [frequency], {
      shouldValidate: true,
      shouldDirty: true
    });
    setSelectedFrequency([frequency]);
  };

  // Access locations
  const onClickChooseAccessLocation = location => {
    const isAllLocationsOption = location.id === allLocationsOption.id;
    const isAccessLocationAlreadySelected = selectedAccessLocations.some(
      item => item.id === location.id
    );
    const areAllLocationsSelected = selectedAccessLocations.some(
      item => item.id === allLocationsOption.id
    );

    if (isAllLocationsOption) {
      handleAllAccessLocationsOption(isAccessLocationAlreadySelected);
    } else {
      handleAccessLocationSelection(
        isAccessLocationAlreadySelected,
        areAllLocationsSelected,
        location
      );
    }
  };

  const handleAccessLocationSelection = (
    isAccessLocationAlreadySelected,
    areAllLocationsSelected,
    location
  ) => {
    let newLocations;

    if (isAccessLocationAlreadySelected) {
      newLocations = removeSelectedAccessLocation(
        location,
        areAllLocationsSelected
      );
    } else {
      newLocations = addSelectedAccessLocation(location);
    }

    setValue('accessLocations', newLocations, {
      shouldValidate: true,
      shouldDirty: true
    });
    setSelectedAccessLocations(newLocations);
  };

  const addSelectedAccessLocation = location => {
    const newLocations = [...selectedAccessLocations, location];

    return newLocations;
  };

  const removeSelectedAccessLocation = (location, areAllLocationsSelected) => {
    let newLocations = selectedAccessLocations.filter(
      item => item.id !== location.id
    );

    if (areAllLocationsSelected) {
      return newLocations.filter(item => item.id !== allLocationsOption.id);
    }
    return newLocations;
  };

  // Access teams
  const onClickChooseAccessTeam = team => {
    const isAllTeamsOption = team.id === allTeamsOption.id;
    const isAccessTeamAlreadySelected = selectedAccessTeams.some(
      item => item.id === team.id
    );
    const areAllTeamsSelected = selectedAccessTeams.some(
      item => item.id === allTeamsOption.id
    );

    if (isAllTeamsOption) {
      handleAllAccessTeamsOption(isAccessTeamAlreadySelected);
    } else {
      handleAccessTeamSelection(
        isAccessTeamAlreadySelected,
        areAllTeamsSelected,
        team
      );
    }
  };

  const handleAccessTeamSelection = (
    isAccessTeamAlreadySelected,
    areAllTeamsSelected,
    team
  ) => {
    let newTeams;

    if (isAccessTeamAlreadySelected) {
      newTeams = removeSelectedAccessTeam(team, areAllTeamsSelected);
    } else {
      newTeams = addSelectedAccessTeam(team);
    }

    if (selectedNotificationTeams.length > 0) {
      const newSelectedNotificationTeams = selectedNotificationTeams.filter(
        notificationTeam =>
          newTeams.some(team => team.id === notificationTeam.id)
      );

      setValue('notificationTeams', newSelectedNotificationTeams, {
        shouldValidate: true,
        shouldDirty: true
      });
      setSelectedNotificationTeams(newSelectedNotificationTeams);
    }

    setValue('accessTeams', newTeams, {
      shouldValidate: true,
      shouldDirty: true
    });
    setSelectedAccessTeams(newTeams);
  };

  const addSelectedAccessTeam = team => {
    const newTeams = [...selectedAccessTeams, team];

    return newTeams;
  };

  const removeSelectedAccessTeam = (team, areAllTeamsSelected) => {
    let newTeams = selectedAccessTeams.filter(item => item.id !== team.id);

    if (areAllTeamsSelected) {
      return newTeams.filter(item => item.id !== allTeamsOption.id);
    }
    return newTeams;
  };

  // Notifications frequency
  const onClickChooseNotificationFrequency = frequency => {
    const isFrequencyAlreadySelected = selectedFrequency === frequency.id;

    if (isFrequencyAlreadySelected) {
      return;
    } else {
      setValue('notificationFrequency', [frequency], {
        shouldValidate: true,
        shouldDirty: true
      });
      setSelectedNotificationFrequency([frequency]);
    }
  };

  // Notifications teams
  const onClickChooseNotificationTeam = team => {
    const isAllTeamsOption = team.id === allTeamsOption.id;
    const isNotificationTeamAlreadySelected = selectedNotificationTeams.some(
      item => item.id === team.id
    );
    const areAllTeamsSelected = selectedNotificationTeams.some(
      item => item.id === allTeamsOption.id
    );

    if (isAllTeamsOption) {
      handleAllNotificationTeamsOption(isNotificationTeamAlreadySelected);
    } else {
      handleNotificationTeamSelection(
        isNotificationTeamAlreadySelected,
        areAllTeamsSelected,
        team
      );
    }
  };

  const handleNotificationTeamSelection = (
    isNotificationTeamAlreadySelected,
    areAllTeamsSelected,
    team
  ) => {
    let newTeams;

    if (isNotificationTeamAlreadySelected) {
      newTeams = removeSelectedNotificationTeam(team, areAllTeamsSelected);
    } else {
      newTeams = addSelectedNotificationTeams(team);
    }

    setValue('notificationTeams', newTeams, {
      shouldValidate: true,
      shouldDirty: true
    });
    setSelectedNotificationTeams(newTeams);
  };

  const addSelectedNotificationTeams = team => {
    const newTeams = [...selectedNotificationTeams, team];

    return newTeams;
  };

  const removeSelectedNotificationTeam = (team, areAllTeamsSelected) => {
    let newTeams = selectedNotificationTeams.filter(
      item => item.id !== team.id
    );

    if (areAllTeamsSelected) {
      return newTeams.filter(item => item.id !== allTeamsOption.id);
    }
    return newTeams;
  };

  // All Access Locations option
  const handleAllAccessLocationsOption = isAccessLocationAlreadySelected => {
    if (isAccessLocationAlreadySelected) {
      resetAccessLocationSelection();
    } else {
      selectAllAccessLocations();
    }
  };

  const resetAccessLocationSelection = () => {
    setValue('accessLocations', [], {
      shouldValidate: true,
      shouldDirty: true
    });
    setSelectedAccessLocations([]);
  };

  const selectAllAccessLocations = () => {
    const allLocations = [allLocationsOption, ...locations];
    setValue('accessLocations', allLocations, {
      shouldValidate: true,
      shouldDirty: true
    });
    setSelectedAccessLocations(allLocations);
  };

  // All Access teams option
  const handleAllAccessTeamsOption = isAccessTeamAlreadySelected => {
    if (isAccessTeamAlreadySelected) {
      resetAccessTeamSelection();
    } else {
      selectAllAccessTeams();
    }
  };

  const resetAccessTeamSelection = () => {
    setValue('notificationTeams', [], {
      shouldValidate: true,
      shouldDirty: true
    });
    setSelectedNotificationTeams([]);

    setValue('accessTeams', [], { shouldValidate: true, shouldDirty: true });
    setSelectedAccessTeams([]);
  };

  const selectAllAccessTeams = () => {
    const allTeams = [allTeamsOption, ...teams];
    setValue('accessTeams', allTeams, {
      shouldValidate: true,
      shouldDirty: true
    });
    setSelectedAccessTeams(allTeams);
  };

  // All Teams Notifications option
  const handleAllNotificationTeamsOption = isNotificationTeamAlreadySelected => {
    if (isNotificationTeamAlreadySelected) {
      resetNotificationTeamSelection();
    } else {
      selectAllNotificationTeams();
    }
  };

  const resetNotificationTeamSelection = () => {
    setValue('notificationTeams', [], {
      shouldValidate: true,
      shouldDirty: true
    });
    setSelectedNotificationTeams([]);
  };

  const selectAllNotificationTeams = () => {
    const allTeams = [selectedNotificationTeams, ...teams];
    setValue('notificationTeams', allTeams, {
      shouldValidate: true,
      shouldDirty: true
    });
    setSelectedNotificationTeams(allTeams);
  };

  const data = [
    {
      id: 'title',
      name: 'title',
      placeholder: t('writeSomething'),
      register,
      control,
      breakpoints: { xs: 12 },
      title: t('name'),
      isValid: checkIfFieldIsValid('title'),
      defaultValue: defaultValues?.title
    },
    {
      id: 'description',
      name: 'description',
      placeholder: t('writeSomething'),
      register,
      control,
      breakpoints: { xs: 12 },
      title: t('checklistDescription'),
      isValid: !errors['description'],
      defaultValue: defaultValues?.description,
      multiline: true,
      rules: {
        required: false,
        maxLength: 150
      }
    },
    {
      id: 'frequency',
      name: 'frequency',
      control,
      register,
      breakpoints: { xs: 12 },
      title: t('repeat'),
      // subtitle: t('howOftenHint'),
      placeholder: t('repeatPlaceholder'),
      isValid: !!selectedFrequency.length,
      onOptionClick: onClickChooseFrequency,
      options: [
        {
          values: frequencyData
        }
      ],
      useRadioOptions: true,
      selectedOption: selectedFrequency
    },
    {
      id: 'accessLocations',
      name: 'accessLocations',
      title: t('targetAudience'),
      // subtitle: t('accessWhoHint'),
      control,
      register,
      breakpoints: { xs: 6 },
      placeholder: t('checklistLocationsPlaceholder'),
      isValid:
        selectedAccessLocations.length > 0 && selectedAccessTeams.length > 0,
      onOptionClick: onClickChooseAccessLocation,
      options: [
        {
          values: [allLocationsOption]
        },
        {
          values: locations
        }
      ],
      selectedOption: selectedAccessLocations
    },
    {
      title: 'invisible',
      // subtitle: 'invisible',
      id: 'accessTeams',
      name: 'accessTeams',
      control,
      register,
      breakpoints: { xs: 6 },
      placeholder: t('checklistsTeamsPlaceholder'),
      isValid:
        selectedAccessLocations.length > 0 && selectedAccessTeams.length > 0,
      onOptionClick: onClickChooseAccessTeam,
      options: [
        {
          values: [allTeamsOption]
        },
        {
          values: teams
        }
      ],
      selectedOption: selectedAccessTeams,
      defaultValue: defaultValues?.accessTeams
    },
    {
      title: t('whenNotifications'),
      // subtitle: t('whenNotificationsHint'),
      id: 'notificationFrequency',
      name: 'notificationFrequency',
      control,
      register,
      breakpoints: { xs: 6 },
      isValid:
        selectedNotificationFrequency.length > 0 &&
        (selectedNotificationFrequency?.[0]?.id === 0 ||
          selectedNotificationTeams.length > 0),
      placeholder: t('whenNotificationsPlaceholder'),
      onOptionClick: onClickChooseNotificationFrequency,
      options: [
        {
          values: notificationFrequencyData
        }
      ],
      useRadioOptions: true,
      selectedOption: selectedNotificationFrequency,
      defaultValue: defaultValues?.notificationFrequency
    },
    {
      title: 'invisible',
      // subtitle: 'invisible',
      id: 'notificationTeams',
      name: 'notificationTeams',
      control,
      register,
      breakpoints: { xs: 6 },
      placeholder: t('checklistsTeamsPlaceholder'),
      isValid:
        selectedNotificationFrequency?.[0]?.id === 0 &&
        selectedNotificationTeams.length > 0,
      disabled: selectedNotificationFrequency?.[0]?.id === 0,
      onOptionClick: onClickChooseNotificationTeam,
      options: [
        {
          values: selectedAccessTeams.filter(
            team => team.id !== allTeamsOption.id
          )
        }
      ],
      selectedOption: selectedNotificationTeams,
      defaultValue: defaultValues?.notificationTeams,
      rules: {
        required: selectedNotificationFrequency?.[0]?.id === 0 ? false : true
      }
    }
  ];

  const toggleActionModal = () => {
    setActionModalOpen(prevState => !prevState);
  };

  const toggleSaveBeforeLeavingModal = () => {
    setIsSaveBeforeLeavingModalOpen(prevState => !prevState);
  };

  const handleDeleteTask = async () => {
    try {
      if (taskToDelete) {
        const newTaskArray = tasks.filter(
          task => task.draggableId !== taskToDelete
        );
        setTasks(newTaskArray);
        setValue('tasks', newTaskArray, {
          shouldValidate: true,
          shouldDirty: true
        });
        setTaskToDelete(null);
        toggleActionModal();
      } else {
        toggleActionModal();
      }
    } catch (error) {}
  };

  return (
    <Modal
      isOpen={isOpen}
      title={defaultValues ? t('editChecklist') : t('createChecklist')}
      handleClose={handleClose}
      handleSave={handleSubmit(data => onSubmit(data, false))}
      handleSaveDraft={handleSubmit(data => onSubmit(data, true))}
      isFormValid={isValid && isDirty && isTasksValid}
      isLoading={isLoading}
      isLoadingDraft={isLoadingDraft}
    >
      <form id="hook-form" noValidate>
        <Grid container className={classes.gridContainer}>
          {data.map(({ id, breakpoints, ...rest }) => (
            <Grid
              item
              pb={0}
              key={id}
              {...breakpoints}
              className={classes.gridItem}
            >
              <TextFieldSection
                key={id}
                {...rest}
                listItemTextClasses={classes.listItemTextClasses}
              />
            </Grid>
          ))}
        </Grid>
        <TextFieldTitle title={t('tasksTitle')} isValid={isTasksValid} />
        <Box
          width="70%"
          sx={{ paddingLeft: 30, paddingTop: 24, marginBottom: 250 }}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <TaskList
                    tasks={tasks}
                    onTaskChange={onTaskChange}
                    onClickDeleteTask={id => {
                      setTaskToDelete(id);
                      setActionModalOpen(true);
                    }}
                  />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <IconLabelButton
            label={t('addTask')}
            onClick={() => {
              setTasks([
                ...tasks,
                {
                  draggableId: generateRandomString(),
                  title: '',
                  description: '',
                  index: tasks.length
                }
              ]);
            }}
            underline
          />
        </Box>
      </form>
      <ActionModal
        open={isActionModalOpen}
        title={t('deleteTaskConfirmation')}
        primaryButtonTitle={t('deleteTask')}
        onPrimaryButtonPress={handleDeleteTask}
        onSecondaryButtonPress={toggleActionModal}
      />
      <ActionModal
        open={isSaveBeforeLeavingModalOpen}
        title={t('leaveBeforeSavingConfirmation')}
        primaryButtonTitle={t('saveAsDraft')}
        onPrimaryButtonPress={handleDeleteTask}
        onSecondaryButtonPress={toggleSaveBeforeLeavingModal}
      />
    </Modal>
  );
};

//TODO: fix any type
ChecklistModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  introText: PropTypes.string,
  handleClose: PropTypes.func,
  onClickSave: PropTypes.func,
  addLoaderSpinner: PropTypes.bool,
  defaultValues: any,
  locations: any,
  teams: any
};

export default ChecklistModal;

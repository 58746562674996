import {
  Box,
  Snackbar,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { Cancel, CheckCircle, Error } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles(theme => ({
  snackbar: {
    position: 'relative',
    width: '100%',
    marginTop: 35
  },
  container: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    padding: theme.spacing(1),
    display: 'flex',
    position: 'relative'
  },
  cancelButton: {
    borderRadius: '50%',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0.1,
    position: 'absolute',
    right: -10,
    top: -10
  }
}));

const ErrorMessage = ({
  SuccessIcon = ({ style }) => <CheckCircle style={style} />,
  ErrorIcon = ({ style }) => <Error style={style} />,
  errorMessage,
  successMessage,
  error = true,
  closeMessage,
  isOpen
}) => {
  const classes = useStyles;
  const { t } = useTranslation();
  const theme = useTheme();
  const errorIconColor = '#F27F6F';
  const successIconColor = '#009973';

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={error ? 7000 : 3000}
      onClose={closeMessage}
      className={classes(theme).snackbar}
    >
      <Box
        className={classes(theme).container}
        style={{
          backgroundColor: error ? '#F8D0C8' : theme.palette.primary.light
        }}
        alignItems="center"
        direction="row"
      >
        {error
          ? ErrorIcon && (
              <ErrorIcon
                style={{
                  marginRight: theme.spacing(1),
                  color: errorIconColor
                }}
              />
            )
          : SuccessIcon && (
              <SuccessIcon
                style={{
                  marginRight: theme.spacing(1),
                  color: successIconColor
                }}
              />
            )}

        <Typography
          variant="body2"
          style={{ color: '#181441', fontWeight: 500 }}
        >
          {error ? errorMessage : successMessage}
        </Typography>
        {closeMessage && (
          <div onClick={closeMessage} className={classes(theme).cancelButton}>
            <Cancel
              style={{
                color: error ? errorIconColor : successIconColor
              }}
            />
          </div>
        )}
      </Box>
    </Snackbar>
  );
};

ErrorMessage.propTypes = {
  SuccessIcon: PropTypes.func,
  ErrorIcon: PropTypes.func,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  error: PropTypes.bool,
  closeMessage: PropTypes.func,
  isOpen: PropTypes.bool
};

export default ErrorMessage;

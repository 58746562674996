import { useEffect, useState } from 'react';
import { getCompanyRef } from 'src/api/firebase';
import { useCompanyId } from '../company/useCompanyId';

const useAllTeams = () => {
  const { companyId } = useCompanyId();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [teams, setTeams] = useState([]);
  const root = getCompanyRef(companyId);

  const observeAllTeams = observer => {
    return root
      .collection('teams')
      .orderBy('name')
      .onSnapshot(observer);
  };

  useEffect(() => {
    try {
      const unsubscribe = observeAllTeams({
        next: async snap => {
          let items = [];
          for (const doc of snap.docs) {
            const data = doc.data();

            items.push({
              id: doc.id,
              ...doc.data()
            });
          }
          setTeams(items);
          setLoading(false);
        },
        error: setError
      });
      return unsubscribe;
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }, []);

  return [teams, isLoading, error];
};

export default useAllTeams;

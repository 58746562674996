import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ListHead from 'src/components/Lists/ListHead';
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useTheme
} from '@material-ui/core';
import styled from 'styled-components';
import DeleteIcon from '../document/DeleteIcon';
import ArrowRightCentered from 'src/icons/ArrowRightCentered';
import QuickPostDocument from 'src/icons/QuickPostDocument';
import QuickPostWebLink from 'src/icons/QuickPostWebLink';
import { useTranslation } from 'react-i18next';

const QuickPostTableList = ({
  columns,
  data,
  buttonIcon,
  onListItemClick,
  avatar
}) => {
  const classes = useStyles();
  const [displayEditModal, setDisplayEditModal] = useState(false);
  const [clickedEdit, setClickedEdit] = useState({});
  const theme = useTheme();
  const { t } = useTranslation();

  const handleOpenLink = url => {
    if (!url) return;

    if (url.startsWith('https://') || url.startsWith('http://')) {
      window.open(url, '_blank');
      return;
    }
    window.open(`https://${url}`);
  };

  const displayIcon = ({ postType, url }) => {
    return (
      <Box mr={1}>
        <IconButton onClick={() => handleOpenLink(url)}>
          {postType === 'link' && <QuickPostWebLink />}
          {postType === 'document' && <QuickPostDocument />}
        </IconButton>
      </Box>
    );
  };

  const handleClose = () => {
    setDisplayEditModal(false);
  };

  const handleEditClick = data => {
    if (typeof onListItemClick === 'function') {
      onListItemClick(data);
    }
  };

  if (!data) {
    return null;
  }

  const getIcon = () => {
    if (buttonIcon === 'delete') {
      return <DeleteIcon />;
    }
    return <ArrowRightCentered />;
  };

  return (
    <Box>
      <Box className={classes.wrapper} mt={1}>
        <ListHead columns={columns} hasIcon={true} />
        <Grid container direction="column" justify="space-between">
          {data?.map((rowData, i) => (
            <Grid item key={i}>
              <Grid container className={classes.gridContainerInner}>
                {columns?.map((title, j) => (
                  <Grid
                    item
                    zeroMinWidth
                    xs
                    key={j}
                    justify={'center'}
                    className={classes.gridItemInner}
                  >
                    <div className={classes.image}>
                      {j === 0 &&
                        avatar &&
                        displayIcon({
                          postType: rowData?.postType,
                          url: rowData?.url
                        })}
                      <Typography
                        variant={j === 0 ? 'h6' : 'subtitle2'}
                        noWrap
                        className={classes.title}
                      >
                        {rowData[title?.name]}
                      </Typography>
                    </div>
                  </Grid>
                ))}
                <Box
                  visibility={
                    typeof onListItemClick === 'function' ? 'visible' : 'hidden'
                  }
                >
                  <IconButton onClick={() => handleEditClick(rowData)}>
                    {getIcon()}
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

const DeleteButtonWrapper = styled.div`
  cursor: pointer;
  margin-top: 18px;
`;

QuickPostTableList.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  avatar: PropTypes.bool,
  RightIcon: PropTypes.func,
  onListItemClick: PropTypes.func,
  fullData: PropTypes.array,
  buttonIcon: PropTypes.string,
  fontVariant: PropTypes.string,
  defaultColor: PropTypes.string,
  postType: PropTypes.string
};

export default QuickPostTableList;

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    height: '100%'
  },
  image: {
    display: 'flex',
    alignItems: 'center',
    height: 24
  },
  gridContainerInner: {
    backgroundColor: theme.palette.common.white,
    height: 54,
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(0, 3, 0, 2),
    border: `1px solid ${theme.palette.common.white}`,
    paddingLeft: 0,
    '&:hover': {
      border: '1px solid #6E26FC'
    }
  },
  gridItemInner: {
    marginTop: 16,
    marginLeft: 16
  },
  makeCenter: {
    marginTop: theme.spacing(2.5),
    marginRight: theme.spacing(1)
  },
  makeCenter2: {
    marginTop: '6px'
  },
  title: {
    color: theme.palette.grey[800]
  },
  avatar: {
    marginRight: theme.spacing(2),
    background: theme.palette.common.white
  }
}));

import React from 'react';
import { SvgIcon } from '@material-ui/core';

const LocationsIcon = props => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_3984_72966)">
        <path
          d="M12.75 2C8.88 2 5.75 5.13 5.75 9C5.75 14.25 12.75 22 12.75 22C12.75 22 19.75 14.25 19.75 9C19.75 5.13 16.62 2 12.75 2ZM12.75 11.5C11.37 11.5 10.25 10.38 10.25 9C10.25 7.62 11.37 6.5 12.75 6.5C14.13 6.5 15.25 7.62 15.25 9C15.25 10.38 14.13 11.5 12.75 11.5Z"
          // eslint-disable-next-line react/prop-types
          fill={props.dark ? 'black' : "#9A98A3"}
        />
      </g>
      <defs>
        <clipPath id="clip0_3984_72966">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.75)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default LocationsIcon;

import React from 'react';
import PropTypes from 'prop-types';

const EmojiComponent = ({ expanded, onChange, config }) => {

  const handleChange = (event) => {
    onChange(event.target.innerHTML);
  };

  const EmojiModal = () => {
    const { emojis } = config;
    return (
      <div
        className='rdw-emoji-modal'
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        {
          emojis.map((emoji, index) => (
            <span
              key={index}
              className="rdw-emoji-icon"
              alt=""
              onClick={handleChange}
            >{emoji}</span>
          ))
        }
      </div>
    );
  };

  if (expanded) {
    return (
      <div
        className="rdw-emoji-wrapper"
        aria-haspopup="true"
        aria-label="rdw-emoji-control"
        aria-expanded={expanded}
      >
        <EmojiModal />
      </div>
    );
  } else {
    return null;
  }
};

EmojiComponent.propTypes = {
  expanded: PropTypes.bool,
  onExpandEvent: PropTypes.func,
  onChange: PropTypes.func,
  config: PropTypes.object,
};

export default EmojiComponent;

import { Box, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import ArrowRight2 from 'src/icons/ArrowRight2';
import DocumentIcon from 'src/icons/DocumentIcon';
import LocationsIcon from 'src/icons/LocationsIcon';
import PostIcon from 'src/icons/PostIcon';
import TeamsIcon2 from 'src/icons/TeamsIcon2';
import { ProgressCircle } from './ProgressCircleV1';
import useCompletionUsers from 'src/api/insights/useCompletionUsers';
import { useOnboardingStreams } from 'src/api/insights/useOnboardingStreams';

const Overview = ({ data }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [users] = useCompletionUsers();
  const { insights, streams } = useOnboardingStreams();

  if (!data) {
    return null;
  }

  const values = data?.engagement?.map(item => item.value) ?? [];
  const highestValue = Math.max(...values);

  const getPercentage = (current, total) => {
    const calculation = (current / total) * 100;
    return Number.isNaN(calculation) ? 0 : Math.floor(calculation);
  };

  const getAverageCompletion = values => {
    const average = _.mean(values);
    return average;
  };

  return (
    <div className={classes.root} media="print">
      <Grid container>
        <Grid item container xs={8}>
          <Grid item xs={12} className={classes.diagram}>
            <Box mb={1} className={classes.diagramTitleWrapper}>
              <Typography variant="h4" className={classes.diagramTitle}>
                {t('activity')}
              </Typography>
              <Typography variant="body2" className={classes.diagramSubTitle}>
                {t('stats')}
              </Typography>
            </Box>
            {/* <Divider className={classes.dividerSpace1} /> */}
            <Box className={classes.hideOnPrint} width="100%" height="100%">
              <ResponsiveContainer width="98%" height="82%">
                <AreaChart
                  width={460}
                  height={300}
                  data={data?.engagement}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient
                      id="GradientGreen"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="5%" stopColor="rgba(168,139,235,1)" />
                      <stop offset="99%" stopColor="rgba(168,139,235,0.1)" />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey="name"
                    style={{
                      fontSize: '12px',
                      fontFamily: 'Inter',
                      fontWeight: 'bold'
                    }}
                  />
                  <YAxis
                    type="number"
                    domain={[0, highestValue]}
                    style={{ fontSize: '12px', fontFamily: 'Inter' }}
                  />
                  <Area
                    type="monotone"
                    dataKey="value"
                    stroke="#6E26FC"
                    strokeWidth="2px"
                    fillOpacity="1"
                    fill="url(#GradientGreen)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Box>
            <Box className={classes.showOnPrint} width="100%" height="100%">
              <AreaChart
                width={650}
                height={275}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient
                    id="GradientGreen"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="10%" stopColor="rgba(0, 198, 150, 0.6)" />
                    <stop offset="99%" stopColor="rgba(0, 198, 150, 0)" />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="name"
                  style={{
                    fontSize: '12px',
                    fontFamily: 'Inter',
                    fontWeight: 'bold'
                  }}
                />
                <YAxis
                  type="number"
                  domain={[0, 100]}
                  style={{ fontSize: '12px', fontFamily: 'Inter' }}
                />
                <Area
                  type="monotone"
                  dataKey="completed"
                  stroke="#6E26FC"
                  strokeWidth="2px"
                  fillOpacity="1"
                  fill="url(#GradientGreen)"
                />
              </AreaChart>
            </Box>
          </Grid>
          <Grid container spacing={3} style={{ padding: 0 }}>
            <Grid xs item className={classes.percent}>
              <Box
                className={classes.circleCard}
                onClick={() => navigate('/app/insights/activatedusers')}
              >
                <Typography variant="h4" className={classes.circleTitle}>
                  {t('activatedUsers')}
                  <ArrowRight2 />
                </Typography>
                <Divider className={classes.dividerSpace} />
                <div className={classes.subTitleText}>
                  <p>{t('signedIn').toUpperCase()}</p>
                  <p>
                    {data.activatedUsers?.completed}
                    {'/'}
                    {data.activatedUsers?.total}
                  </p>
                </div>
                <Box
                  display="flex"
                  flex={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <ProgressCircle
                    value={getPercentage(
                      data.activatedUsers?.completed,
                      data.activatedUsers?.total
                    )}
                    maybeSize={185}
                    maybeMainInsideText={
                      getPercentage(
                        data?.activatedUsers?.completed,
                        data?.activatedUsers?.total
                      ) + '%'
                    }
                    maybeSubtitleInsideText={t('average')}
                    trailClass={classes.purpleCircle}
                    singleColor="#6E26FC"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs className={classes.percent}>
              <Box
                className={classes.circleCard}
                onClick={() =>
                  navigate('/app/insights/onboarding', { state: { streams } })
                }
              >
                <Typography variant="h4" className={classes.circleTitle}>
                  {t('onboardingStreams')}
                  <ArrowRight2 />
                </Typography>
                <Divider className={classes.dividerSpace} />
                <div className={classes.subTitleText}>
                  <p>{t('fullyOnboarded').toUpperCase()}</p>
                  <p>
                    {insights.completed}
                    {'/'}
                    {insights.total}
                  </p>
                </div>
                <Box
                  display="flex"
                  flex={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <ProgressCircle
                    value={getPercentage(insights.completed, insights.total)}
                    maybeSize={185}
                    maybeMainInsideText={
                      getPercentage(insights.completed, insights.total) + '%'
                    }
                    maybeSubtitleInsideText={t('onboarded')}
                    trailClass={classes.purpleCircle}
                    singleColor="#6E26FC"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs className={classes.percent}>
              <Box
                className={classes.circleCard}
                onClick={() => navigate('/app/insights/completion')}
              >
                <Typography variant="h4" className={classes.circleTitle}>
                  {t('completionRatio')}
                  <ArrowRight2 />
                </Typography>
                <Divider className={classes.dividerSpace} />
                <div className={classes.subTitleText}>
                  <p>{t('fullyCompleted').toUpperCase()}</p>
                  <p>
                    {users?.filter(item => item.completionValue >= 100)
                      .length ?? 0}
                    {'/'}
                    {users.length}
                  </p>
                </div>
                <Box
                  display="flex"
                  flex={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <ProgressCircle
                    value={getAverageCompletion(
                      users.map(item => item.completionValue)
                    )}
                    maybeSize={185}
                    maybeMainInsideText={
                      users
                        ? getAverageCompletion(
                            users.map(item => item.completionValue)
                          ).toFixed() + '%'
                        : '0'
                    }
                    maybeSubtitleInsideText={t('average')}
                    trailClass={classes.purpleCircle}
                    singleColor="#6E26FC"
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={4}>
          <Grid item xs sm className={classes.amounts}>
            <p className={classes.postTitle}>{t('postAndDocs')}</p>
            <Box className={classes.totals}>
              <div className={classes.left}>
                <PostIcon />
                <Typography variant="body2" className={classes.label}>
                  {t('posts').toUpperCase()}
                </Typography>
              </div>
              <Typography variant="h3" className={classes.sum}>
                {data.postsCount}
              </Typography>
            </Box>
            <Box className={classes.totals}>
              <div className={classes.left}>
                <DocumentIcon />
                <Typography variant="body2" className={classes.label}>
                  {t('documents').toUpperCase()}
                </Typography>
              </div>
              <Typography variant="h3" className={classes.sum}>
                {data.documentsCount}
              </Typography>
            </Box>
            <p className={classes.postTitle}>{t('usersAndTeams')}</p>
            <Box className={classes.totals}>
              <div className={classes.left}>
                <LocationsIcon dark="true" />
                <Typography variant="body2" className={classes.label}>
                  {t('locations').toUpperCase()}
                </Typography>
              </div>
              <Typography variant="h3" className={classes.sum}>
                {data.locationsCount}
              </Typography>
            </Box>
            <Box className={classes.totals}>
              <div className={classes.left}>
                <TeamsIcon2 />
                <Typography variant="body2" className={classes.label}>
                  {t('teams').toUpperCase()}
                </Typography>
              </div>
              <Typography variant="h3" className={classes.sum}>
                {data.teamsCount}
              </Typography>
            </Box>
            <Box className={classes.totals}>
              <div className={classes.left}>
                <DocumentIcon />
                <Typography variant="body2" className={classes.label}>
                  {t('users').toUpperCase()}
                </Typography>
              </div>
              <Typography variant="h3" className={classes.sum}>
                {data.usersCount}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
Overview.propTypes = {
  data: PropTypes.object,
  commonSums: PropTypes.array,
  commonPercentages: PropTypes.array
};

export default Overview;

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(3),
    '@media print {@page {size: landscape}}': {
      background: theme.palette.common.white
    }
  },
  circleCard: {
    cursor: 'pointer',
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    // width: '100%',
    height: '100%',
    border: 'solid 2px transparent',
    alignItems: 'center',
    '&:hover': { border: 'solid 2px #6e26fc' }
    // marginRight: theme.spacing(2),
    // '&:last-child': {
    //   marginRight: theme.spacing(0)
    // }
    // '@media print': {
    //   marginRight: theme.spacing(0)
    // }
  },
  postTitle: {
    color: '#24103D',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 'bold',
    padding: '3%'
  },
  circleTitle: {
    margin: theme.spacing(2, 0, 2, 0),
    display: 'flex',
    justifyContent: 'space-between',
    /*   justify-content: flex-start | flex-end | center | space-between | space-around | space-evenly
     */
    width: '90%',
    fontWeight: 'bold'
  },
  dividerSpace: {
    marginBottom: theme.spacing(1),
    width: '88%'
  },
  subTitleText: {
    display: 'flex',
    width: '80%',
    fontFamily: 'Inter',
    justifyContent: 'space-between',
    fontSize: '11px',
    color: '#9A98A3',
    fontWeight: 'bold'
  },
  dividerSpace1: {
    marginBottom: theme.spacing(3),
    marginLeft: '2%',
    marginRight: '2%'
  },
  dividerSpace2: {
    marginLeft: '7%',
    marginRight: '2%',
    width: '96%'
  },
  subContainer: {
    display: 'flex',
    width: '100%',
    opacity: 0
  },
  circleSubtitle: {
    paddingLeft: theme.spacing(2),
    width: '100%',
    marginBottom: theme.spacing(2),
    fontSize: 11,
    fontWeight: 'bold'
  },
  circleSubvalue: {
    paddingRight: theme.spacing(2),
    fontSize: 11,
    fontWeight: 'bold'
  },
  diagram: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(1.5),
    height: 320
  },
  diagramTitle: {
    paddingLeft: theme.spacing(2),
    margin: theme.spacing(2, 0, 1, 0)
    // width: '100%'
  },
  diagramTitleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  diagram2Title: {
    paddingLeft: theme.spacing(2),
    margin: theme.spacing(2, 0, 1, 0),
    display: 'inline-block',
    width: '100%'
  },
  diagramSubvalue: {
    paddingRight: theme.spacing(2),
    fontSize: 11,
    fontWeight: 'bold',
    display: 'inline-block',
    paddingTop: 10
  },
  diagramSubTitle: {
    paddingRight: theme.spacing(2),
    fontSize: 14,
    paddingTop: 10
  },
  diagram2Inner: {
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    height: '90%'
  },
  amounts: {
    height: 280,
    paddingLeft: theme.spacing(2),
    '@media print': {
      // width: 400,
      marginTop: 32
    }
  },
  percent: {
    height: '100%',
    marginTop: theme.spacing(3)
  },
  average: {
    borderRadius: theme.spacing(1.5)
  },
  averageWrapper: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    borderBottomRightRadius: theme.spacing(1.5),
    borderBottomLeftRadius: theme.spacing(1.5),
    marginLeft: theme.spacing(3),
    height: '83%',
    width: '100%'
  },
  averageChartWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    '@media print': {
      display: 'none'
    }
  },
  averageTitleWrapper: {
    marginLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    borderTopRightRadius: theme.spacing(1.5),
    borderTopLeftRadius: theme.spacing(1.5),
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  totals: {
    display: 'flex',
    height: 97,
    backgroundColor: theme.palette.common.white,
    margin: theme.spacing(0, 0, 2, 1),
    borderRadius: theme.spacing(1.5),
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(3),
    width: '103%'
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    width: 140,
    paddingLeft: theme.spacing(3)
  },
  img: {
    marginRight: theme.spacing(1),
    width: 26
  },
  starImg: {
    marginTop: 10,
    marginRight: theme.spacing(1),
    width: 10,
    height: 10
  },
  sum: {
    color: theme.palette.secondary.main,
    fontSize: 24
  },
  label: {
    display: 'inline-block',
    fontWeight: 'bold',
    paddingLeft: theme.spacing(2)
  },
  blueCircle: {
    color: '#D0CFDA'
  },
  purpleCircle: {
    color: '#dac8fe'
  },
  chartPrint: {
    width: '100%',
    height: '100%'
  },
  circlesPrint: {},
  responsiveContainer: {
    height: '100%',
    '@media print': {
      display: 'none'
    }
  },
  showOnPrint: {
    display: 'none',
    '@media print': {
      display: 'flex'
    }
  },
  hideOnPrint: {
    '@media print': {
      display: 'none'
    }
  }
}));

import { makeStyles } from '@material-ui/core';
import { bool } from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  circleIndicator: {
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    background: ({ isValid }) =>
      isValid ? theme.palette.success.main : theme.palette.error.main,
    marginRight: theme.spacing(3)
  }
}));

const CircleIndicator = ({ isValid }) => {
  const classes = useStyles({ isValid });
  return <div className={classes.circleIndicator} />;
};

CircleIndicator.propTypes = {
  isValid: bool
};

export default CircleIndicator;

import { any, func } from 'prop-types';
import React from 'react';
import { Box } from '@material-ui/core';
import ChecklistCard from './ChecklistCard';

export const DisplayAllChecklists = ({ checklists, onDeleteClick, onEditClick }) => {
  return (
    <Box style={{marginBottom: '100px'}}>
      {checklists?.map((element, index) => (
        <ChecklistCard
          key={element.id}
          title={element.title}
          isPublished={element.isPublished}
          isDraft={element.isDraft}
          locationsCount={element.locationIds.length}
          teamsCount={element.teamIds.length}
          taskCount={element.taskIds.length}
          onDeleteClick={() => onDeleteClick(element)}
          onEditClick={() => onEditClick(element)}
        />
      ))}
    </Box>
  )
}

DisplayAllChecklists.propTypes = {
  checklists: any,
  onDeleteClick: func,
  onEditClick: func
};

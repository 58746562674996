import { useEffect, useState, useCallback, useContext } from 'react';
import { AuthContext } from 'src/providers/AuthProvider';
import { getCompanyRef } from 'src/api/firebase';

const useAllUsers = () => {
  const { companyId } = useContext(AuthContext);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);

  const root = getCompanyRef(companyId);

  const observeAllUsers = observer => {
    return root.collection('users').onSnapshot(observer);
  };

  const fetchUser = useCallback(async ref => {
    const snap = await ref.get();
    if (snap.exists) {
      return {
        id: snap.id,
        ...snap.data()
      };
    }
    return null;
  }, []);

  useEffect(() => {
    try {
      const unsubscribe = observeAllUsers({
        next: async snap => {
          let items = [];
          const users = [];
          for (const doc of snap.docs) {
            const data = doc.data();
            if (data.name) {
              items.push({
                id: doc.id,
                ...doc.data()
              });
            }
          }
          setUsers(items);
          setLoading(false);
        },
        error: setError
      });
      return unsubscribe;
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }, []);

  return [users, isLoading, error];
};

export default useAllUsers;

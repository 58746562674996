import React from 'react';
import { SvgIcon } from '@material-ui/core';

const FormatUnderline = props => {
  return (
    <SvgIcon {...props}>
      <path
        d="M12.3333 15.6667C15.0917 15.6667 17.3333 13.425 17.3333 10.6667V4H15.25V10.6667C15.25 12.275 13.9417 13.5833 12.3333 13.5833C10.725 13.5833 9.41667 12.275 9.41667 10.6667V4H7.33333V10.6667C7.33333 13.425 9.575 15.6667 12.3333 15.6667ZM6.5 17.3333V19H18.1667V17.3333H6.5Z"
        fill="#181441"
      />
    </SvgIcon>
  );
};

export default FormatUnderline;

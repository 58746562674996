import React from 'react';
import ArrowBack from 'src/icons/ArrowTest';
import { IconButton } from '@material-ui/core';
import { func } from 'prop-types';

const BackButton = ({ onClick }) => {
  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <IconButton color="secondary" onClick={onClick}>
      <ArrowBack />
    </IconButton>
  );
};

BackButton.propTypes = {
  onClick: func
};

export default BackButton;

import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import BackButton from 'src/components/Buttons/BackButton';
import ChevronRight from 'src/icons/ChevronRight';

const LocationIndexView = () => {
  const {
    state: { location: selectedLocation, team }
  } = useLocation();

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box>
      <Box marginTop={-6} marginLeft={-1.5} position="absolute">
        <BackButton
          onClick={() =>
            navigate(-1, {
              state: { tabIndex: 2 }
            })
          }
        />
      </Box>
      <Box
        marginTop={10}
        flexDirection="row"
        display="flex"
        alignItems="center"
      >
        <Typography style={{ color: '#676570' }} variant="h3">
          {t('locations')}
        </Typography>
        <ChevronRight />
        <Typography style={{ color: '#676570' }} variant="h3">
          {selectedLocation?.name || team.name}
        </Typography>
        {team && (
          <>
            <ChevronRight />
            <Typography style={{ color: '#676570' }} variant="h3">
              {team.name}
            </Typography>
          </>
        )}
      </Box>
      <Outlet />
    </Box>
  );
};

export default LocationIndexView;

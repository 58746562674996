import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Insights = props => {
  return (
    <SvgIcon {...props}>
      <svg width="18" viewBox="-2.5 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 4.2H3V14H0V4.2ZM5.6 0H8.4V14H5.6V0ZM11.2 8H14V14H11.2V8Z" fill="white" />
      </svg>
    </SvgIcon>
  );
};

export default Insights;

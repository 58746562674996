import {
  IconButton,
  InputAdornment,
  makeStyles,
  Typography
} from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  textfield: {
    borderRadius: 8,
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary.surface}`,
    padding: theme.spacing(1),
    color: theme.palette.secondary.main,
    fontSize: 14,
    fontWeight: 400,
    height: 52
  }
}));

const TextField = ({
  placeholder,
  defaultValue,
  endIcon,
  onClickEndIcon,
  disabled,
  onChange,
  errorMessage,
  style,
  ...props
}) => {
  const classes = useStyles();

  return (
    <>
      <InputBase
        className={clsx(classes.textfield, style)}
        style={errorMessage?.length > 0 ? { borderColor: '#B00020' } : {}}
        placeholder={placeholder}
        defaultValue={defaultValue}
        inputProps={{ 'aria-label': 'naked' }}
        endAdornment={
          endIcon && (
            <InputAdornment position="end">
              <IconButton
                style={{ display: 'flex', justifyContent: 'center' }}
                onClick={onClickEndIcon}
              >
                {endIcon}
              </IconButton>
            </InputAdornment>
          )
        }
        onChange={onChange}
        disabled={disabled}
        fullWidth
        {...props}
      />
      <Typography style={{ color: '#B00020' }} variant="subtitle1">
        {errorMessage}
      </Typography>
    </>
  );
};

TextField.propTypes = {
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  endIcon: PropTypes.any,
  style: PropTypes.any,
  onClickEndIcon: PropTypes.func,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string
};

export default TextField;

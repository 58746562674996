import { firestore, getCompanyRef } from 'src/api/firebase';

export const editTeam = async ({ companyId, isChatEnabled, team, name }) => {
  if (!companyId || !team || !name) {
    throw new Error('Missing required parameters');
  }

  const root = getCompanyRef(companyId);
  var batch = firestore.batch();

  const teamRef = root.collection('teams').doc(team.id);

  batch.set(teamRef, { name, isChatEnabled }, { merge: true });

  return batch.commit().then(() => {
    return { ...team, name, isChatEnabled };
  });
};

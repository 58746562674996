import { Button, ListItem, makeStyles, useTheme } from '@material-ui/core';
// import { useTheme } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    justifyContent: 'flex-start',
    alignItems: 'center',
    textTransform: 'none',
    borderRadius: 0,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    width: '100%',
    height: 66
  },
  icon: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(3),
    color: theme.palette.common.white
  },
  title: {
    marginRight: 'auto',
    color: theme.palette.common.white,
    fontSize: 14
  }
}));

const NavItem = ({ className, href, icon: Icon, title, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      <Button
        activeStyle={{ backgroundColor: theme.palette.secondary.surface }}
        className={classes.button}
        component={RouterLink}
        to={href}
      >
        {Icon && <Icon className={classes.icon} />}
        <span className={classes.title}>{title}</span>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;

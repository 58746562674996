import { firestore, getCompanyRef } from 'src/api/firebase';

export const deleteTeam = ({ companyId, teamId }) => {
  var batch = firestore.batch();
  const root = getCompanyRef(companyId);

  const teamRef = root.collection('teams').doc(teamId);

  batch.delete(teamRef);

  return batch.commit();
};

/* eslint-disable react/prop-types */
import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import BackButton from 'src/components/Buttons/BackButton';
import ViewHeader from 'src/components/ViewHeader';
import ArrowRightCentered from 'src/icons/ArrowRightCentered';
import OnBoardingDetailsTable from './OnBoardingDetailsTable';

export const OnBoardingSubDetailsView = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const onClickGoBack = () => navigate(-1);
  const state = location.state;
  const name = state.name || '';

  return (
    <div className={classes.mainWrapper}>
      <Box>
        <div
          className={classes.backButtonWrapper}
          onClick={() => onClickGoBack()}
        >
          <BackButton />
        </div>
        <Typography variant="h3" className={classes.routerText}>
          <>
            {t('insights')} <ArrowRightCentered height={30} />{' '}
            {t('introduction')} <ArrowRightCentered height={30} /> {name}
          </>
        </Typography>
      </Box>
      <ViewHeader
        title={name}
        subtitle={t('usersCount', { count: state.users.length })}
        containerStyle={classes.containerStyle}
      />
      <OnBoardingDetailsTable data={state.users} />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    paddingTop: theme.spacing(10)
  },
  containerStyle: {
    marginTop: theme.spacing(3)
  },
  backButtonWrapper: {
    position: 'absolute',
    marginTop: '-50px',
    zIndex: 1200,
    cursor: 'pointer',
    marginLeft: '-12px'
  },
  routerText: {
    color: '#676570'
  }
}));

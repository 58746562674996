import React from 'react';
import { Checkbox as MUICheckbox, makeStyles } from '@material-ui/core';
import { bool } from 'prop-types';
import CheckBoxIcon from 'src/icons/CheckBoxIcon';
import DisabledCheckBoxIcon from 'src/icons/DisabledCheckBoxIcon';
import UncheckedIcon from 'src/icons/UncheckedIcon';
import DisabledUncheckedIcon from 'src/icons/DisabledUncheckedIcon';

const useStyles = makeStyles(theme => ({
  icon: {
    width: 22,
    height: 22
  }
}));

const CheckBox = ({ whiteBackground, checked, disabled, ...props }) => {
  const classes = useStyles();
  const uncheckedIcon = disabled ? <DisabledUncheckedIcon className={classes.icon} /> : <UncheckedIcon className={classes.icon} />;
  const checkedIcon = disabled ? <DisabledCheckBoxIcon className={classes.icon} /> : <CheckBoxIcon className={classes.icon} />

  return (
    <MUICheckbox
      icon={uncheckedIcon}
      checkedIcon={checkedIcon}
      color="primary"
      checked={checked || false}
      edge="start"
      disableRipple
      disabled={disabled}
      {...props}
    />
  );
};

CheckBox.propTypes = {
  whiteBackground: bool,
  disabled: bool,
  checked: bool
};

export default CheckBox;

import { firestore, getCompanyRef } from 'src/api/firebase';
import { getTimestamp } from '../utils';
import { addStreamToTeam } from './addStreamToTeam';
import { addStreamToLocation } from './addStreamToLocation';

export const addStream = ({ companyId, ...stream }) => {
  var batch = firestore.batch();
  const root = getCompanyRef(companyId);
  const { isOnboardingStream, name, roles, teams, locations } = stream;

  const availableForAllLocations = locations.some(
    item => item.id === 'all-locations'
  );

  const availableForAllTeams = teams.some(item => item.id === 'all-teams');

  const streamTeams = teams.filter(item => item.id !== 'all-teams');
  const streamLocations = locations.filter(item => item.id !== 'all-locations');

  const streamDocId = root.collection('streams').doc().id;
  const streamRef = root.collection('streams').doc(streamDocId);

  const usersCount = streamTeams.reduce(
    (prev, curr) => prev + curr.usersCount,
    0
  );

  const addNewStream = () => {
    const newStream = {
      isOnboardingStream,
      name: name.trim(),
      roles: roles?.map(item => item.name.toLowerCase()),
      teamsCount: streamTeams.length,
      locationsCount: streamLocations.length,
      completedPostsCount: 0,
      locations: streamLocations.map(item => item.id),
      teams: streamTeams.map(item => item.id),
      usersCount,
      postsCount: 0,
      createdAt: getTimestamp(),
      id: streamRef.id,
      availableForAllLocations,
      availableForAllTeams
    };

    batch.set(streamRef, newStream, { merge: true });
  };

  const updateTeamsWithNewStream = () => {
    streamTeams.forEach(team => {
      const teamRef = root.collection('teams').doc(team.id);
      addStreamToTeam({ batch, teamRef, streamDocId });
    });
  };

  const updateLocationsWithNewStream = () => {
    streamLocations.forEach(location => {
      const locationRef = root.collection('locations').doc(location.id);
      addStreamToLocation({ batch, locationRef, streamDocId });
    });
  };

  addNewStream();
  updateTeamsWithNewStream();
  updateLocationsWithNewStream();

  return batch.commit();
};

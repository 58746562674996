import React from 'react';
import MUIBackdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { string } from 'prop-types';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: 'rgba(0, 0, 0, 0.6)'
  }
}));

// eslint-disable-next-line react/prop-types
const Backdrop = ({ message }) => {
  const classes = useStyles();

  return (
    <div>
      <MUIBackdrop className={classes.backdrop} open={true}>
        <Box
          width={327}
          height={163}
          borderRadius={16}
          display="flex"
          flexDirection="column"
          bgcolor="white"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <CircularProgress size={32} color="primary" thickness={6} />
          <Typography type="body1">{message}</Typography>
        </Box>
      </MUIBackdrop>
    </div>
  );
};

Backdrop.defaultProps = {
  message: string
};

export default Backdrop;

import {
  Avatar,
  Box,
  makeStyles,
  Typography,
  useTheme
} from '@material-ui/core';
import PropTypes, { any, arrayOf, number, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import IconLabelButton from 'src/components/Buttons/IconLabelButton';
import CheckBox from 'src/components/CheckBox';
import { useCompanyId } from 'src/api/company/useCompanyId';
import { addUser } from 'src/api/users/addUser';
import { deleteUser } from 'src/api/users/deleteUser';
import { updateUser } from 'src/api/users/updateUser';
import DeleteOutline from 'src/icons/DeleteOutline';
import TextFieldSection from '../../TextFieldSection';
import Modal from '../Modal';
import ActionModal from 'src/components/ActionModal';
import {
  useAllLocationsOption,
  useAllTeamsOption,
  useLoading
} from 'src/hooks';
import useToast from 'src/hooks/useToast';

const useStyles = makeStyles(theme => ({
  textFieldSection: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  textFieldTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  textFieldPlacement: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  checkBoxSection: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3.5)
  },
  dropdownContainer: {
    background: theme.palette.common.white,
    borderRadius: 8,
    border: `1px solid ${theme.palette.primary.surface}`,
    padding: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'space-between'
  },
  dropdownMenuItem: {
    color: theme.palette.secondary.main,
    fontSize: 14,
    fontWeight: 400,
    padding: theme.spacing(-2),
    minWidth: 550
  }
}));

const rolesData = [
  { id: '1', name: 'learner' },
  { id: '2', name: 'creator' },
  { id: '3', name: 'admin' }
];

const UserModal = ({
  isOpen,
  title,
  handleClose,
  defaultValues,
  locations,
  teams
}) => {
  const allLocationsOption = useAllLocationsOption();
  const allTeamsOption = useAllTeamsOption();

  const theme = useTheme();
  const { onError, onSuccess } = useToast();
  const [isActionModalOpen, setActionModalOpen] = useState(false);
  const defaultRoles = rolesData?.filter(
    item => item.name === defaultValues?.roles?.[0]
  );
  const { companyId } = useCompanyId();
  const classes = useStyles();
  const { t } = useTranslation();

  const [newlyEmployed, setNewlyEmployed] = useState(
    defaultValues?.newlyEmployed ?? true
  );

  const defaultLocations = defaultValues?.locations ?? [];
  const defaultTeams = defaultValues?.teams ?? [];

  const [selectedLocation, setSelectedLocation] = useState([
    ...defaultLocations,
    ...(defaultValues?.allLocationsAccess ? [allLocationsOption] : [])
  ]);

  const [selectedTeam, setSelectedTeam] = useState([
    ...defaultTeams,
    ...(defaultValues?.allTeamsAccess ? [allTeamsOption] : [])
  ]);
  const [selectedRole, setSelectedRole] = useState(defaultRoles ?? []);
  const { isLoading, withLoading } = useLoading();
  const isEditing = !!defaultValues?.id;

  const initialState = {
    newlyEmployed: defaultValues?.newlyEmployed ?? true,
    name: defaultValues?.name?.full ?? '',
    jobTitle: defaultValues?.jobTitle ?? '',
    phone: defaultValues?.phone ?? '',
    role: selectedRole,
    location: selectedLocation,
    team: selectedTeam
  };

  const {
    register,
    setValue,
    handleSubmit,
    formState: { isValid, isDirty, dirtyFields, errors },
    control,
    trigger,
    getFieldState
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: initialState
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  const onSubmit = async data => {
    try {
      if (isEditing) {
        await handleEdit(data);
      } else {
        await handleCreate(data);
      }
    } catch (error) {
      console.log({ error });
      onError("Couldn't create user");
    }
  };

  const handleEdit = async data => {
    const changedFields = Object.keys(dirtyFields);
    const dataToUpdate = extractChangedFields(data, changedFields);
    const areAllLocationsSelected = checkAllLocationsSelected(data.location);
    const areAllTeamsSelected = checkAllTeamsSelected(data.team);

    if (areAllLocationsSelected) {
      dataToUpdate.location = filterAllLocationsOption(data.location);
      dataToUpdate.allLocationsAccess = true;
    }

    if (areAllTeamsSelected) {
      dataToUpdate.team = filterAllTeamsOption(data.team);
      dataToUpdate.allTeamsAccess = true;
    }

    await withLoading(() =>
      updateUser({
        companyId,
        allLocationsAccess: areAllLocationsSelected,
        allTeamsAccess: areAllTeamsSelected,
        ...dataToUpdate,
        currentTeams: filterAllTeamsOption(defaultValues?.teams ?? []),
        currentLocations: filterAllLocationsOption(
          defaultValues?.locations ?? []
        )
      })
    );

    handleClose();
  };

  const extractChangedFields = (data, changedFields) => {
    const dataToUpdate = { id: defaultValues.id };
    changedFields.forEach(item => {
      dataToUpdate[item] = data[item];
    });
    return dataToUpdate;
  };

  const handleCreate = async data => {
    const areAllTeamsSelected = checkAllTeamsSelected(data.team);
    const areAllLocationsSelected = checkAllLocationsSelected(data.location);

    if (areAllTeamsSelected) {
      data.team = filterAllTeamsOption(data.team);
    }

    if (areAllLocationsSelected) {
      data.location = filterAllLocationsOption(data.location);
    }

    await withLoading(() =>
      addUser({
        companyId,
        areAllTeamsSelected,
        areAllLocationsSelected,
        ...data
      })
    );

    onSuccess(t('userCreated'));
    handleClose();
  };

  const checkAllTeamsSelected = teams =>
    teams.some(item => item.id === allTeamsOption.id);

  const checkAllLocationsSelected = locations =>
    locations.some(item => item.id === allLocationsOption.id);

  const filterAllTeamsOption = teams =>
    teams.filter(item => item.id !== allTeamsOption.id);

  const filterAllLocationsOption = locations =>
    locations.filter(item => item.id !== allLocationsOption.id);

  const onClickChooseRole = role => {
    setValue('role', role, { shouldValidate: true, shouldDirty: true });
    setSelectedRole([role]);
  };

  const onClickChooseLocation = location => {
    const isAllLocationsOption = location.id === allLocationsOption.id;
    const isLocationAlreadySelected = selectedLocation.some(
      item => item.id === location.id
    );
    const areAllLocationsSelected = selectedLocation.some(
      item => item.id === allLocationsOption.id
    );

    if (isAllLocationsOption) {
      handleAllLocationsOption(isLocationAlreadySelected);
    } else {
      handleLocationSelection(
        isLocationAlreadySelected,
        areAllLocationsSelected,
        location
      );
    }
  };

  const handleAllLocationsOption = isLocationAlreadySelected => {
    if (isLocationAlreadySelected) {
      resetLocationSelection();
    } else {
      selectAllLocations();
    }
  };

  const resetLocationSelection = () => {
    setValue('location', [], { shouldValidate: true, shouldDirty: true });
    setSelectedLocation([]);
  };

  const selectAllLocations = () => {
    const allLocations = [allLocationsOption, ...locations];
    setValue('location', allLocations, {
      shouldValidate: true,
      shouldDirty: true
    });
    setSelectedLocation(allLocations);
  };

  const handleLocationSelection = (
    isLocationAlreadySelected,
    areAllLocationsSelected,
    location
  ) => {
    let newLocations;

    if (isLocationAlreadySelected) {
      newLocations = removeSelectedLocation(location, areAllLocationsSelected);
    } else {
      newLocations = addSelectedLocation(location);
    }

    setSelectedLocation(newLocations);
    setValue('location', newLocations, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  const removeSelectedLocation = (location, areAllLocationsSelected) => {
    let newLocations = selectedLocation.filter(item => item.id !== location.id);

    if (areAllLocationsSelected) {
      return newLocations.filter(item => item.id !== allLocationsOption.id);
    }
    return newLocations;
  };

  const addSelectedLocation = location => {
    const newLocations = [...selectedLocation, location];
    return newLocations;
  };

  const handleAllTeamsOption = isTeamAlreadySelected => {
    if (isTeamAlreadySelected) {
      resetSelection();
    } else {
      selectAllTeams();
    }
  };

  const resetSelection = () => {
    setSelectedTeam([]);
    setValue('team', [], { shouldValidate: true, shouldDirty: true });
  };

  const selectAllTeams = () => {
    const allTeams = [allTeamsOption, ...teams];
    setSelectedTeam(allTeams);
    setValue('team', allTeams, { shouldValidate: true, shouldDirty: true });
  };

  const handleTeamSelection = (
    isTeamAlreadySelected,
    areAllTeamsSelected,
    team
  ) => {
    let newTeams;

    if (isTeamAlreadySelected) {
      newTeams = removeSelectedTeam(team, areAllTeamsSelected);
    } else {
      newTeams = addSelectedTeam(team);
    }

    setSelectedTeam(newTeams);
    setValue('team', newTeams, { shouldValidate: true, shouldDirty: true });
  };

  const removeSelectedTeam = (team, areAllTeamsSelected) => {
    let newTeams = selectedTeam.filter(item => item.id !== team.id);

    if (areAllTeamsSelected) {
      return newTeams.filter(item => item.id !== allTeamsOption.id);
    }
    return newTeams;
  };

  const addSelectedTeam = team => {
    const newTeams = [...selectedTeam, team];
    return newTeams;
  };

  const onClickChooseTeam = team => {
    const isAllTeamsOption = team.id === allTeamsOption.id;
    const isTeamAlreadySelected = selectedTeam.some(
      item => item.id === team.id
    );
    const areAllTeamsSelected = selectedTeam.some(
      item => item.id === allTeamsOption.id
    );

    if (isAllTeamsOption) {
      handleAllTeamsOption(isTeamAlreadySelected);
    } else {
      handleTeamSelection(isTeamAlreadySelected, areAllTeamsSelected, team);
    }
  };

  const checkIfFieldIsValid = field => {
    if (defaultValues) {
      return !getFieldState(field).error;
    }
    const fieldState = getFieldState(field);
    return !getFieldState(field).isDirty ? false : !getFieldState(field).error;
  };

  const data = [
    {
      id: 'name',
      placeholder: t('namePlaceholder'),
      title: t('name'),
      isValid: checkIfFieldIsValid('name'),
      control,
      name: 'name',
      defaultValue: defaultValues?.name?.full
    },
    {
      id: 'phone',
      placeholder: t('enterPhoneNR'),
      title: t('phoneNR'),
      isValid: checkIfFieldIsValid('phone'),
      control,
      name: 'phone',
      defaultValue: defaultValues?.phone,
      rules: {
        required: true,
        pattern: {
          value: /^\+46 *7[02369] *\d{4} *\d{3}$/,
          message: 'Not valid number'
        }
      }
    },
    {
      id: 'jobTitle',
      placeholder: t('EnterJobTitle'),
      title: t('jobTitle'),
      isValid: true,
      control,
      name: 'jobTitle',
      defaultValue: defaultValues?.jobTitle ?? '',
      rules: { required: false }
    },
    {
      id: 'role',
      name: 'role',
      control,
      register,
      title: t('role'),
      placeholder: t('selectRole'),
      isValid: !!selectedRole.length,
      onOptionClick: onClickChooseRole,
      options: [
        {
          values: rolesData
        }
      ],
      selectedOption: selectedRole
    },
    {
      id: 'location',
      name: 'location',
      title: t('location'),
      control,
      register,
      disabled: !isEditing && defaultValues?.locations,
      placeholder: t('selectLocation'),
      isValid: selectedLocation.length > 0,
      onOptionClick: onClickChooseLocation,
      options: [
        {
          values: [allLocationsOption]
        },
        {
          values: locations
        }
      ],
      selectedOption: selectedLocation
    },
    {
      id: 'team',
      name: 'team',
      title: t('team'),
      control,
      disabled: !isEditing && defaultValues?.teams,
      register,
      placeholder: t('selectTeam'),
      isValid: selectedTeam.length > 0,
      onOptionClick: onClickChooseTeam,
      options: [
        {
          values: [allTeamsOption]
        },
        {
          values: teams
        }
      ],
      selectedOption: selectedTeam
    }
  ];

  const handleNewlyEmployedChange = () => {
    setValue('newlyEmployed', !newlyEmployed, {
      shouldValidate: true,
      shouldDirty: true
    });
    setNewlyEmployed(!newlyEmployed);
  };

  const onDeleteUserPress = () => {
    toggleActionModal();
  };

  const toggleActionModal = () => {
    setActionModalOpen(prevState => !prevState);
  };

  const handleDeleteUser = async () => {
    const dataToDelete = {
      ...defaultValues,
      teams: filterAllTeamsOption(defaultValues.teams),
      locations: filterAllLocationsOption(defaultValues.locations)
    };

    try {
      await withLoading(() => deleteUser({ companyId, ...dataToDelete }));
      handleClose();
    } catch (error) {
      onError("Couldn't delete user");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      handleClose={handleClose}
      isFormValid={isValid && isDirty}
      isLoading={isLoading}
    >
      <form id="hook-form" noValidate onSubmit={handleSubmit(onSubmit)}>
        {defaultValues?.imageUrl && (
          <Box ml={3} mt={3} mb={7}>
            <Avatar
              src={defaultValues.imageUrl}
              alt={'user'}
              style={{ width: 124, height: 124 }}
            />
          </Box>
        )}
        {data.map(({ id, ...rest }) => (
          <TextFieldSection key={id} {...rest} />
        ))}

        {defaultValues ? (
          <Box ml={3}>
            <IconLabelButton
              icon={() => <DeleteOutline />}
              label={t('deleteUser')}
              underline
              color={theme.palette.secondary.main}
              onClick={onDeleteUserPress}
            />
          </Box>
        ) : (
          <div className={classes.checkBoxSection}>
            <CheckBox
              {...register('newlyEmployed')}
              whiteBackground
              checked={newlyEmployed}
              onChange={handleNewlyEmployedChange}
            />
            <Typography variant="subtitle2">
              {t('newlyEmployedCheckbox')}
            </Typography>
          </div>
        )}
      </form>
      <ActionModal
        open={isActionModalOpen}
        title={t('deleteUserConfirmation')}
        primaryButtonTitle={t('deleteUser')}
        onSecondaryButtonPress={toggleActionModal}
        onPrimaryButtonPress={handleDeleteUser}
        isLoading={isLoading}
      />
    </Modal>
  );
};

UserModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  introText: PropTypes.string,
  handleClose: PropTypes.func,
  onClickSave: PropTypes.func,
  addLearerSpinner: PropTypes.bool,
  defaultValues: any,
  teams: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
      usersCount: number.isRequired,
      locations: shape({
        id: string.isRequired,
        name: string.isRequired
      })
    })
  ).isRequired,
  locations: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
      usersCount: number.isRequired,
      teamsCount: number.isRequired
    })
  ).isRequired
};

export default UserModal;

import { useState, useEffect, useCallback, useRef } from 'react';

export const useLoading = () => {
  const [isLoading, setLoading] = useState(false);

  const isMountedRef = useRef(true);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const withLoading = useCallback(async promise => {
    try {
      if (isMountedRef.current) {
        setLoading(true);
      }

      const result = await promise();

      if (isMountedRef.current) {
        setLoading(false);
      }

      return result;
    } catch (error) {
      if (isMountedRef.current) {
        setLoading(false);
      }
      throw error;
    }
  }, []);

  return {
    isLoading,
    withLoading
  };
};

import React from 'react';
import { SvgIcon } from '@material-ui/core';

const More = props => {
  return (
    <SvgIcon {...props}>
      <path
        d="M12 9C13.1046 9 14 8.10457 14 7C14 5.89543 13.1046 5 12 5C10.8954 5 10 5.89543 10 7C10 8.10457 10.8954 9 12 9Z"
        fill="#9A98A3"
      />
      <path
        d="M12 19C13.1046 19 14 18.1046 14 17C14 15.8954 13.1046 15 12 15C10.8954 15 10 15.8954 10 17C10 18.1046 10.8954 19 12 19Z"
        fill="#9A98A3"
      />
    </SvgIcon>
  );
};

export default More;

import React from 'react';
import { SvgIcon } from '@material-ui/core';

const DeleteIcon = props => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_2396_74457)">
        <path
          d="M6 21L18 21L18 7L6 7L6 21ZM19 4L15.5 4L14.5 3L9.5 3L8.5 4L5 4L5 6L19 6L19 4Z"
          fill="#C6355C"
        />
      </g>
      <defs>
        <clipPath id="clip0_2396_74457">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default DeleteIcon;

import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        backgroundColor: theme.palette.background.lightBlue,
        display: 'flex',

        '@media print': {
          backgroundColor: theme.palette.common.white,
          width: '97%',
          marginLeft: 16
        }
      },
      a: {
        textDecoration: 'none'
      },
      '#root': {
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.background.lightBlue,
        paddinBottom: theme.spacing(3),
        '@media print': {
          backgroundColor: theme.palette.common.white,
          margin: 64
        }
      }
    }
  })
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;

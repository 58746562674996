import { Avatar, Box, Grid, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { DEFAULT_AVATAR_IMAGE } from 'src/api/users/addUser';
import ListHead from './ListHead';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    height: '100%'
  },
  gridContainerInner: {
    backgroundColor: theme.palette.common.white,
    height: 54,
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(1.5),
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 3, 0, 2),
    border: `1px solid ${theme.palette.common.white} `,
    paddingLeft: 0,
    '&:hover': {
      border: '1px solid #6E26FC'
    }
  },
  gridItemInner: {
    marginLeft: 16
  },

  title: {
    color: theme.palette.grey[500],
    textTransform: 'capitalize'
  },
  dataRow: {
    display: 'flex',
    alignItems: 'center'
  },
  image: {
    display: 'flex',
    height: 26
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const List = ({
  columns,
  data,
  avatar,
  RightIcon,
  fullData,
  fontVariant,
  defaultColor
}) => {
  const classes = useStyles();

  const displayContent = team => {
    let teamArray = team?.split(',');
    const moreThanOne =
      teamArray?.length > 1 ? ` (+${teamArray.length - 1})` : '';
    return teamArray?.[0] + moreThanOne;
  };

  return (
    <div className={classes.wrapper} mt={1}>
      <ListHead columns={columns} RightIcon={RightIcon} hasIcon={RightIcon} />
      <Grid container direction="column" justify="space-between">
        {data?.map((rowData, i) => (
          <Grid item key={i}>
            <Grid container className={classes.gridContainerInner}>
              {columns.map((title, j) => (
                <Grid
                  item
                  zeroMinWidth
                  xs
                  key={j}
                  className={classes.gridItemInner}
                >
                  <Box flexDirection="row" display="flex">
                    {j === 0 && avatar && (
                      <Avatar
                        className={classes.avatar}
                        src={rowData.imageUrl || DEFAULT_AVATAR_IMAGE}
                      />
                    )}
                    <Typography
                      variant={fontVariant ? fontVariant : 'h6'}
                      noWrap
                      className={classes.title}
                      style={{
                        color: j === 0 ? '#323138' : defaultColor,
                        display: 'flex'
                      }}
                    >
                      <div className={classes.dataRow}>
                        {rowData[title.name] === rowData.Team
                          ? displayContent(rowData.Team)
                          : rowData[title.name] === rowData.Location
                          ? displayContent(rowData.Location)
                          : rowData[title.name]}
                      </div>
                    </Typography>
                  </Box>
                </Grid>
              ))}
              {RightIcon && <RightIcon obj={fullData[i]}></RightIcon>}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

List.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  avatar: PropTypes.bool,
  RightIcon: PropTypes.func,
  fullData: PropTypes.array,
  fontVariant: PropTypes.string,
  defaultColor: PropTypes.string
};

export default List;

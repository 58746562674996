import React from 'react';
import { SvgIcon } from '@material-ui/core';

const TeamsIcon = props => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_3984_72971)">
        <path
          d="M12.5 12.75C14.13 12.75 15.57 13.14 16.74 13.65C17.82 14.13 18.5 15.21 18.5 16.38V18H6.5V16.39C6.5 15.21 7.18 14.13 8.26 13.66C9.43 13.14 10.87 12.75 12.5 12.75ZM4.5 13C5.6 13 6.5 12.1 6.5 11C6.5 9.9 5.6 9 4.5 9C3.4 9 2.5 9.9 2.5 11C2.5 12.1 3.4 13 4.5 13ZM5.63 14.1C5.26 14.04 4.89 14 4.5 14C3.51 14 2.57 14.21 1.72 14.58C0.98 14.9 0.5 15.62 0.5 16.43V18H5V16.39C5 15.56 5.23 14.78 5.63 14.1ZM20.5 13C21.6 13 22.5 12.1 22.5 11C22.5 9.9 21.6 9 20.5 9C19.4 9 18.5 9.9 18.5 11C18.5 12.1 19.4 13 20.5 13ZM24.5 16.43C24.5 15.62 24.02 14.9 23.28 14.58C22.43 14.21 21.49 14 20.5 14C20.11 14 19.74 14.04 19.37 14.1C19.77 14.78 20 15.56 20 16.39V18H24.5V16.43ZM12.5 6C14.16 6 15.5 7.34 15.5 9C15.5 10.66 14.16 12 12.5 12C10.84 12 9.5 10.66 9.5 9C9.5 7.34 10.84 6 12.5 6Z"
          fill="#9A98A3"
        />
      </g>
      <defs>
        <clipPath id="clip0_3984_72971">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default TeamsIcon;

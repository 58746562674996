import React from 'react';
import { SvgIcon } from '@material-ui/core';

const DocumentIcon = props => {
  return (
    <SvgIcon {...props}>
      <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.0100002 0.201172L0 20.2012H16V6.20117L10 0.201172H0.0100002ZM9 7.20117V1.70117L14.5 7.20117H9Z" fill="#24103D" />
      </svg>
    </SvgIcon>
  );
};

export default DocumentIcon;

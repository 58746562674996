import { Box, Typography, makeStyles } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import IconLabelButton from 'src/components/Buttons/IconLabelButton';
import UserModal from 'src/components/Modals/UserModal/UserModal';
import SearchInput from 'src/components/SearchInput';
import UsersList from 'src/components/UsersList';
import styled from 'styled-components';
import { utils, read } from 'xlsx';
import { uniqueId } from 'lodash';
import Backdrop from 'src/views/auth/sms-auth/BackDrop';
import { useCompanyId } from 'src/api/company/useCompanyId';
import useToast from 'src/hooks/useToast';
import { onBulkAddUsers } from 'src/cloud-functions/onBulkAddUsers';
import BulkUploadModal from 'src/components/Modals/BulkUploadModal/BulkUploadModal';
import LoadingIndicator from 'src/components/LoadingIndicator/LoadingIndicator';
import ViewHeader from 'src/components/ViewHeader';
import ChevronRight from 'src/icons/ChevronRight';
import BackButton from 'src/components/Buttons/BackButton';
import useUsersByLocationId from 'src/api/teams/useUsersByLocationId';

const useStyles = makeStyles(theme => ({
  addTeamButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(4)
  },
  content: {
    padding: theme.spacing(2)
  },
  list: {
    width: '100%',
    marginRight: theme.spacing(3)
  },
  containerStyle: {
    marginTop: theme.spacing(2)
  },
  mainWrapper: {
    paddingTop: theme.spacing(0)
  },
  backButtonWrapper: {
    position: 'absolute',
    marginTop: '-52px',
    marginLeft: '-9px',
    zIndex: 1200,
    cursor: 'pointer'
  }
}));

const LocationView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    state: { location, teams, locations }
  } = useLocation();
  const { t } = useTranslation();
  console.log({ location, teams, locations });

  const { companyId } = useCompanyId();
  const [data, isLoading] = useUsersByLocationId({ locationId: location.id });
  const [selectedUser, setSelectedUser] = useState(null);
  const hiddenFileInput = useRef(null);
  const [bulkData, setBulkData] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [addLearnerModalOpen, setAddLearnerModalOpen] = useState(false);
  const [dataToUpload, setDataToUpload] = useState(null);
  const { onSuccess, onError } = useToast();

  const onSearchUsers = searchText => {
    setSearchText(searchText);
  };

  if (isLoading) {
    return (
      <Box mt={2}>
        <LoadingIndicator />;
      </Box>
    );
  }

  const getData = () => {
    return data.filter(user => {
      const isIncludedInSearch =
        user.name?.full?.toLowerCase().includes(searchText.toLowerCase()) ||
        user.roles[0]?.toLowerCase().includes(searchText.toLowerCase()) ||
        user.locations[0]?.name
          ?.toLowerCase()
          .includes(searchText.toLowerCase());
      return isIncludedInSearch;
    });
  };

  const openFilePicker = () => {
    hiddenFileInput.current.click();
  };

  const mapUsers = data => {
    const onlyValidUsers = data.filter(item => item.isValid);

    const modifiedUsers = onlyValidUsers.map(item => {
      const names = item.name.value.split(' ');
      const [first, ...rest] = names;
      const name = {
        first,
        last: rest.join(''),
        full: item.name.value
      };
      return {
        phone: item.phone.value,
        name,
        roles: [item.userRole.value.toLowerCase()],
        newlyEmployed: item.isNewlyEmployed.value === 'Yes',
        jobTitle: item.jobTitle.value,
        teams: [{ id: team.id, name: team.name }]
      };
    });
    return modifiedUsers;
  };

  const uploadData = async data => {
    const modifiedUsers = mapUsers(data);

    const dataToUpload = {
      companyId,
      users: modifiedUsers
    };

    const result = {
      success: data.filter(item => item.isValid),
      failed: data.filter(item => !item.isValid)
    };

    if (result.failed.length > 0) {
      setBulkData(result);
      setDataToUpload(dataToUpload);
      return;
    }

    setIsUploading(true);
    shouldSave(dataToUpload);
  };

  const validateData = data => {
    const phoneRegex = /^([+]46)\s*(7[0236])\s*(\d{4})\s*(\d{3})$/;

    return data.map(item => {
      const isPhoneValid = phoneRegex.test(`${item.phone.trim()}`);
      const isNameValid = item.name.trim().split(' ').length > 1;
      const isNewlyEmployed = item.isNewlyEmployed === 'Yes';
      const isRoleValid = ['admin', 'creator', 'learner'].includes(
        item.userRole
      );

      const validatedData = {
        phone: {
          isValueValid: isPhoneValid,
          value: item.phone.trim()
        },
        name: {
          isValueValid: isNameValid,
          value: item.name.trim()
        },
        userRole: {
          isValueValid: isRoleValid,
          value: item.userRole
        },
        jobTitle: {
          isValueValid: true,
          value: item.jobTitle
        },
        isNewlyEmployed: {
          isValueValid: true,
          value: isNewlyEmployed ? 'Yes' : 'No'
        },
        isValid: isPhoneValid && isNameValid && isRoleValid
      };
      return validatedData;
    });
  };

  const readUploadFile = e => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        const result = JSON.parse(JSON.stringify(json));
        const normalizeData = result.map((item, index) => ({
          id: uniqueId(),
          name: `${item['First Name']} ${item['Last Name'] ?? ''}` ?? '',
          phone: item['Phone'] ?? 0,
          userRole: item['User Role'] ?? 'learner',
          isNewlyEmployed: item['Newly Employed'] ?? 'No',
          jobTitle: item['Job Title'] ?? ''
        }));

        const validatedData = validateData(normalizeData);
        uploadData(validatedData);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const shouldSave = data => {
    handleSave(data);
  };

  const handleSave = async data => {
    try {
      setIsUploading(true);
      setDataToUpload(null);
      await onBulkAddUsers(data);

      onSuccess(t('usersAddedSuccess'));
    } catch (error) {
      onError(t('err.default'));
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <>
      {isUploading && <Backdrop message={t('addingUsers')} />}
      <input
        style={{ display: 'none' }}
        type="file"
        onChange={readUploadFile}
        name="excel"
        accept=".xlsx, .xls"
        ref={hiddenFileInput}
      />
      {bulkData && (
        <BulkUploadModal
          isOpen={true}
          title={t('summary')}
          isFormValid={!!dataToUpload}
          introText={t('learnerInfo')}
          handleClose={() => setBulkData(null)}
          onClickSave={() => shouldSave(dataToUpload)}
          result={bulkData}
          isUploading={isUploading}
        />
      )}
      {addLearnerModalOpen && (
        <UserModal
          isOpen={addLearnerModalOpen}
          title={t('addUser')}
          introText={t('learnerInfo')}
          handleClose={() => setAddLearnerModalOpen(false)}
          teams={teams}
          locations={locations}
        />
      )}
      {selectedUser && (
        <UserModal
          isOpen={true}
          title={t('detailedInformation')}
          introText={t('learnerInfo')}
          handleClose={() => setSelectedUser(null)}
          defaultValues={selectedUser}
          teams={teams}
          locations={locations}
        />
      )}

      {!location && (
        <Box marginTop={4} marginLeft={-1.5} position="absolute">
          <BackButton onClick={() => navigate(-1)} />
        </Box>
      )}
      <div className={classes.mainWrapper}>
        {!location && (
          <Box pl={0} pt={10}>
            <Box flexDirection="row" display="flex" alignItems="center">
              <Typography style={{ color: '#676570' }} variant="h3">
                {t('Teams')}
              </Typography>
              <ChevronRight />
              <Typography style={{ color: '#676570' }} variant="h3">
                {team.name}
              </Typography>
            </Box>
          </Box>
        )}
        <ViewHeader
          title={location?.name}
          subtitle={`${location?.usersCount} Learners`}
          containerStyle={classes.containerStyle}
        />
        <Box mr={2} display="flex" justifyContent={'flex-end'} paddingTop={2}>
          <Box mr={3}>
            <IconLabelButton
              label={t('addMany')}
              onClick={openFilePicker}
              underline
            />
          </Box>
          <IconLabelButton
            label={t('newUserAdd')}
            onClick={() => setAddLearnerModalOpen(true)}
            underline
          />
        </Box>
        <SearchWrapper>
          <SearchInput value={searchText} onChange={onSearchUsers} />
        </SearchWrapper>
        <UsersList data={getData()} onClickUser={setSelectedUser} />
      </div>
    </>
  );
};

export default LocationView;

const SearchWrapper = styled.div`
  margin-bottom: 10px;
  width: 50%;
`;

import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Organization = props => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 3.5C9.72386 3.5 9.5 3.72386 9.5 4V8C9.5 8.27614 9.72386 8.5 10 8.5H14C14.2761 8.5 14.5 8.27614 14.5 8V4C14.5 3.72386 14.2761 3.5 14 3.5H10ZM8 4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4V8C16 9.10457 15.1046 10 14 10H12.75V11.25H17C17.9665 11.25 18.75 12.0335 18.75 13V14H20C21.1046 14 22 14.8954 22 16V20C22 21.1046 21.1046 22 20 22H16C14.8954 22 14 21.1046 14 20V16C14 14.8954 14.8954 14 16 14H17.25V13C17.25 12.8619 17.1381 12.75 17 12.75H7C6.86192 12.75 6.75 12.8619 6.75 13V14H8C9.10457 14 10 14.8954 10 16V20C10 21.1046 9.10457 22 8 22H4C2.89543 22 2 21.1046 2 20V16C2 14.8954 2.89543 14 4 14H5.25V13C5.25 12.0335 6.03352 11.25 7 11.25H11.25V10H10C8.89543 10 8 9.10457 8 8V4ZM4 15.5C3.72386 15.5 3.5 15.7239 3.5 16V20C3.5 20.2761 3.72386 20.5 4 20.5H8C8.27614 20.5 8.5 20.2761 8.5 20V16C8.5 15.7239 8.27614 15.5 8 15.5H4ZM16 15.5C15.7239 15.5 15.5 15.7239 15.5 16V20C15.5 20.2761 15.7239 20.5 16 20.5H20C20.2761 20.5 20.5 20.2761 20.5 20V16C20.5 15.7239 20.2761 15.5 20 15.5H16Z'
      />
    </SvgIcon>
  );
};

export default Organization;

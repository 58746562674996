import React from 'react';
import { SvgIcon } from '@material-ui/core';

const ArrowTest = props => {
  return (
    <SvgIcon {...props}>
      <path
        d="M9.71729 19L3.00021 12L9.71729 5"
        stroke="#181441"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <line
        x1="4.26709"
        y1="11.9683"
        x2="20.0002"
        y2="11.9683"
        stroke="#181441"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default ArrowTest;

import { makeStyles } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthContext } from 'src/providers/AuthProvider';
import LoadingView from 'src/views/auth/LoadingView';
import { auth } from '../../api/firebase';
import { NavBar } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.lightBlue,
    height: '100%',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(27.5)
    },

    '@media print': {
      backgroundColor: theme.palette.common.white
    }
  },
  wrapper: {
    height: '100%',
    width: '100%',
    paddingTop: 0,
    padding: theme.spacing(0, 13)
  },
  nav: {
    '@media print': {
      display: 'none'
    }
  }
}));

const DashboardLayout = () => {
  const classes = useStyles();
  const { userData } = useContext(AuthContext);

  useEffect(() => {
    if (!auth.currentUser) return;
    const refreshToken = async () => {
      const getIdToken = await auth.currentUser.getIdToken();
      const result = await auth.currentUser.getIdTokenResult();
      if (!result.claims.companyId) {
        await auth.currentUser.getIdToken(true);
      }
    };
    refreshToken();
  }, []);

  if (!userData) {
    return <LoadingView />;
  }
  const { avatar, firstName, lastName } = userData;

  return (
    <div className={classes.root} media="print">
      {/* <div className={classes.nav}>
        <TopBar />
      </div> */}
      <div className={classes.nav}>
        <NavBar />
      </div>
      <div className={classes.wrapper}>
        <Outlet />
      </div>
    </div>
  );
};

export default DashboardLayout;

import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import BackButton from 'src/components/Buttons/BackButton';
import ViewHeader from 'src/components/ViewHeader';
import ArrowRightCentered from 'src/icons/ArrowRightCentered';
import OnBoardingTable from './OnBoardingTable';

export const OnBoardingSubView = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const streams = location.state?.streams ?? [];
  const onClickGoBack = () => navigate('/app/insights');

  return (
    <div className={classes.mainWrapper}>
      <Box>
        <Box
          className={classes.backButtonWrapper}
          onClick={() => onClickGoBack()}
        >
          <BackButton onClick={() => onClickGoBack()} />
        </Box>
        <Typography variant="h3" className={classes.routeText}>
          {t('insights')} <ArrowRightCentered height={30} /> {t('introduction')}
        </Typography>
      </Box>
      <ViewHeader
        title={t('OnboardingStreams')}
        subtitle={t('streamsCount', { count: streams.length ?? 0 })}
        containerStyle={classes.containerStyle}
      />
      <OnBoardingTable insights={streams} />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    paddingTop: theme.spacing(10)
  },
  routeText: {
    color: '#676570',
    fontFamily: 'Inter'
  },
  root: {
    minHeight: '70vh'
  },
  backButtonWrapper: {
    position: 'absolute',
    marginTop: '-50px',
    zIndex: 1200,
    cursor: 'pointer',
    marginLeft: '-12px'
  },
  addTeamButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(2)
  },
  containerStyle: {
    marginTop: theme.spacing(3)
  }
}));

import { useEffect, useState } from 'react';
import { getCompanyRef } from 'src/api/firebase';
import { useCompanyId } from '../company/useCompanyId';

const useStreams = () => {
  const { companyId } = useCompanyId();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [streams, setStreams] = useState([]);

  const root = getCompanyRef(companyId);

  const observeStreams = observer => {
    return root
      .collection('streams')
      .orderBy('name', 'asc')
      .onSnapshot(observer);
  };

  useEffect(() => {
    try {
      const unsubscribe = observeStreams({
        next: async snap => {
          let items = [];
          for (const doc of snap.docs) {
            items.push({
              id: doc.id,
              ...doc.data()
            });
          }
          setStreams(items);
          setLoading(false);
        },
        error: setError
      });
      return unsubscribe;
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }, []);

  return [streams, isLoading, error];
};

export default useStreams;

import { Box, makeStyles, useTheme } from '@material-ui/core';
import { convertToRaw, convertFromRaw, EditorState, RichUtils } from 'draft-js';
import { any, func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormatBold from 'src/icons/FormatBold';
import Emoji from'src/icons/Emoji';
import FormatItalic from 'src/icons/FormatItalic';
import FormatOrderedList from 'src/icons/FormatOrderedList';
import FormatUnderline from 'src/icons/FormatUnderline';
import FormatUnorderedList from 'src/icons/FormatUnorderedList';
import 'draft-js/dist/Draft.css';
import './styles.css';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FormatBoldSharp } from '@material-ui/icons';
import { ToolbarButton } from './ToolbarButton';

const config = {
  options: [],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['bold', 'italic', 'underline'],
    bold: { icon: FormatBoldSharp, className: undefined },
    italic: { icon: FormatItalic, className: undefined },
    underline: { icon: FormatUnderline, className: undefined }
  },
  fontSize: {
    icon: 20,
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined
  },
  fontFamily: {
    options: [
      'Arial',
      'Georgia',
      'Impact',
      'Tahoma',
      'Times New Roman',
      'Verdana'
    ],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered', 'ordered'],
    unordered: { icon: FormatUnorderedList, className: undefined },
    ordered: { icon: FormatOrderedList, className: undefined }
  },
  emoji: {
    inDropdown: true,
    className: 'editorEmojiButton',
    emojis: [
      '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
      '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
      '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
      '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
      '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
      '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
      '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
      '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
      '✅', '❎', '💯',
    ],
  },
};

const useStyles = makeStyles(theme => ({
  counterText: {
    color: theme.palette.grey[500]
  },
  editor: {
    '.Draftail-Editor': {
      borderRadius: 0,
      backgroundColor: 'transparent',
      border: '0px'
    }
  }
}));

const CONTROLS = [
  {
    type: 'inline',
    style: 'BOLD',
    icon: FormatBold
  },
  {
    type: 'inline',
    style: 'ITALIC',
    icon: FormatItalic
  },
  {
    type: 'inline',
    style: 'UNDERLINE',
    icon: FormatUnderline
  },
  {
    type: 'emoji',
    icon: Emoji,
    config: {
      emojis: [
        '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
        '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
        '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
        '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
        '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
        '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
        '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
        '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
        '✅', '❎', '💯',
      ],
    }
  }
];

const TextEditor = ({ placeholder, setText, text, raw }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const { t } = useTranslation();
  const defaultPlaceholder = t('text_editor.placeholder');
  const theme = useTheme();
  const [isStateEmpty, setIsStateEmpty] = useState(true);

  useEffect(() => {
    if (raw) {
      try {
        const rawText = JSON.parse(raw);
        if (rawText) {
          const contentState = convertFromRaw(rawText);
          setEditorState(EditorState.createWithContent(contentState));
        }
      } catch (error) {
        console.log(error)
      }
    }
  }, []);

  const handleControlClick = ({ type, style, event }) => {
    event.preventDefault();
    let nextState;

    if (type === 'inline') {
      nextState = RichUtils.toggleInlineStyle(editorState, style);
    }

    if (type === 'block') {
      nextState = RichUtils.toggleBlockType(editorState, style);
    }

    nextState && setEditorState(nextState);
  };

  var contentState = editorState.getCurrentContent();
  var shouldShowPlaceholder = true;
  if (!contentState.hasText()) {
    if (
      contentState
        .getBlockMap()
        .first()
        .getType() !== 'unstyled'
    ) {
      shouldShowPlaceholder = false;
    }
  }

  const handleEditorChange = editorState => {
    if (!editorState) return;
    const hasText = editorState.getCurrentContent().hasText();
    if (!hasText) {
      setIsStateEmpty(true);
      let newState = EditorState.createEmpty();
      setEditorState(newState);
    } else {
      setIsStateEmpty(false);
    }

    setEditorState(editorState);
    const stringified = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    setText(stringified);
  };

  const getButtons = () =>
    CONTROLS.map((item, index) => {
      const first = index == 0 || null;
      const last = CONTROLS.length - 1 === index || null;
      const Icon = item.icon;
      return (
        <ToolbarButton
          key={index}
          first={first}
          last={last}
          style={item.style}
          type={item.type}
          config={item.config}
        >
          <Icon />
        </ToolbarButton>
      );
    });

  return (
    <Box
      mt={1}
      minHeight={165}
      bgcolor={theme.palette.common.white}
      border={1}
      borderColor={theme.palette.primary.surface}
      borderRadius={8}
    >
      <Box
        style={{
          fontFamily: theme.typography.fontFamily,
          fontSize: 16,
          fontWeight: '400',
          lineHeight: '20px',
          color: theme.palette.secondary.main
        }}
      >
        <Editor
          stripPastedStyles={true}
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          placeholder={
            shouldShowPlaceholder && (placeholder || defaultPlaceholder)
          }
          editorStyle={{
            paddingLeft: 20,
            paddingRight: 20,
            fontSize: 16,
            fontFamily: 'Inter',
            fontWeight: '400',
            color: '#181441',
            border: 0,
          }}
          wrapperStyle={{
            borderRadius: 8,
            border: 0,
          }}
          toolbarCustomButtons={getButtons()}
          toolbarStyle={{
            padding: 0,
            borderRadius: theme.spacing(2),
            flexWrap: 'noWrap',
            border: 0,
          }}
          toolbar={config}
        />
      </Box>
    </Box>
  );
};

TextEditor.propTypes = {
  placeholder: string,
  text: string,
  setText: func,
  raw: any
};

export default TextEditor;

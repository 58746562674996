import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import React from 'react';
import List from 'src/components/Lists/List';
import { array } from 'prop-types';

const OnBoardingDetailsTable = ({ data }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.mainWrapper}>
      <List
        columns={[
          { name: t('insightsStreamTitleDetails1'), align: 'left' },
          { name: t('insightsStreamTitleDetails2'), align: 'left' },
          { name: t('insightsStreamTitleDetails3'), align: 'left' },
          { name: t('insightsStreamTitleDetails4'), align: 'left' }
        ]}
        fontVariant={'subtitle2'}
        defaultColor={'#181441'}
        data={data.map(item => ({
          [t('insightsStreamTitleDetails1')]: item.name || '-',
          [t('insightsStreamTitleDetails2')]: item.location || '-',
          [t('insightsStreamTitleDetails3')]: item.team || '-',
          [t('insightsStreamTitleDetails4')]: item.completed || '-'
        }))}
        avatar
        fullData={data}
      ></List>
    </div>
  );
};

export default OnBoardingDetailsTable;

OnBoardingDetailsTable.propTypes = {
  data: array
};

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    marginTop: '1%'
  }
}));

const mockData = [
  {
    name: 'John Doe',
    location: 'Sweden',
    team: `Marketing`,
    completed: 'No'
  },
  {
    name: 'Arasto Sahbaei',
    location: 'Abu Dhabi',
    team: `Engineering`,
    completed: 'Yes'
  },
  {
    name: 'Mr Göthenbergers',
    location: 'Ghana',
    team: `Sales`,
    completed: 'No'
  }
];

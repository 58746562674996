import { firestore, getCompanyRef } from 'src/api/firebase';
import { getTimestamp } from '../utils';
import firebase from 'firebase/app';

export const updateUser = ({ companyId, ...user }) => {
  var batch = firestore.batch();
  const root = getCompanyRef(companyId);
  const {
    role,
    team,
    location,
    name,
    newlyEmployed,
    phone,
    jobTitle,
    id,
    currentTeams,
    currentLocations,
    allTeamsAccess,
    allLocationsAccess
  } = user;

  const updatedUser = {
    phone,
    jobTitle,
    updatedAt: getTimestamp(),
    newlyEmployed,
    allLocationsAccess,
    allTeamsAccess
  };

  if (team) {
    currentTeams?.forEach(({ id }) => {
      const shouldRemoveUserFromTeam = !team.some(item => item.id === id);

      const teamRef = root.collection('teams').doc(id);
      if (shouldRemoveUserFromTeam) {
        batch.update(teamRef, {
          usersCount: firebase.firestore.FieldValue.increment(-1)
        });
      }
    });

    team?.forEach(({ id }) => {
      const shouldAddUserToTeam = !currentTeams?.some(item => item.id === id);

      const teamRef = root.collection('teams').doc(id);
      if (shouldAddUserToTeam) {
        batch.update(teamRef, {
          usersCount: firebase.firestore.FieldValue.increment(1)
        });
      }
    });

    updatedUser.teams = team.map(item => ({ id: item.id, name: item.name }));
    updatedUser.teamIds = team.map(item => item.id);
  }

  if (name) {
    const values = name.split(' ');
    updatedUser.name = {
      first: values[0],
      full: name
    };

    if (values.length > 1) {
      updatedUser.name.last = values[1];
    }
  }

  if (location) {
    currentLocations?.forEach(({ id }) => {
      const shouldRemoveUserFromLocations = location.id !== id;

      if (shouldRemoveUserFromLocations) {
        const locationRef = root.collection('locations').doc(id);
        batch.update(locationRef, {
          usersCount: firebase.firestore.FieldValue.increment(-1)
        });
      }
    });

    const shouldAddUserToLocation = !currentLocations?.some(
      item => item.id === location.id
    );

    if (shouldAddUserToLocation) {
      location.forEach(location => {
        const locationRef = root.collection('locations').doc(location.id);
        batch.update(locationRef, {
          usersCount: firebase.firestore.FieldValue.increment(1)
        });
      });
    }

    updatedUser.locations = location.map(item => ({
      id: item.id,
      name: item.name
    }));
    updatedUser.locationIds = location.map(item => item.id);
  }

  if (role) {
    updatedUser.roles = [role.name.toLowerCase()];
  }

  const userRef = root.collection('users').doc(id);

  const dataToUpdate = JSON.parse(JSON.stringify(updatedUser));

  batch.set(userRef, dataToUpdate, { merge: true });

  return batch.commit();
};

import { firestore, getCompanyRef } from 'src/api/firebase';

export const deleteStream = ({ companyId, ...stream }) => {
  var batch = firestore.batch();
  const root = getCompanyRef(companyId);

  if (stream?.id) {
    const ref = root.collection('streams').doc(stream.id);
    batch.delete(ref);
  }
  return batch.commit();
};

import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { getCompanyRef } from 'src/api/firebase';
import { useCompanyId } from '../company/useCompanyId';

const useDocumentsObserver = () => {
  const { companyId } = useCompanyId();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [documents, setDocuments] = useState([]);
  const root = getCompanyRef(companyId);

  const observePosts = observer => {
    return root
      .collection('documents')
      .orderBy('title', 'asc')
      .onSnapshot(observer);
  };

  useEffect(() => {
    try {
      const unsubscribe = observePosts({
        next: async snap => {
          let items = [];

          for (const doc of snap.docs) {
            const { createdAt, author, ...rest } = doc.data();
            items.push({
              id: doc.id,
              ...rest,
              name: rest.title,
              date: dayjs(createdAt.toDate()).format('YYYY-MM-DD'),
              uploadedBy: author?.name,
              postType: rest.document.type,
              url: rest.document.url
            });
          }
          setDocuments(items);
          setLoading(false);
        },
        error: setError
      });
      return unsubscribe;
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }, []);

  return [documents, isLoading, error];
};

export default useDocumentsObserver;

import { useEffect, useState } from 'react';
import { getCompanyRef } from 'src/api/firebase';
import { useCompanyId } from '../company/useCompanyId';

const useChecklists = () => {
  const { companyId } = useCompanyId();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [checklists, setChecklists] = useState([]);

  const root = getCompanyRef(companyId);

  const observeChecklists = observer => {
    return root
      .collection('checklists')
      .orderBy('title', 'asc')
      .onSnapshot(observer);
  };

  useEffect(() => {
    try {
      const unsubscribe = observeChecklists({
        next: async snap => {
          let checklistsWithTasksPromises = snap.docs.map(async (doc) => {
            const checklistData = doc.data();
            // Fetch tasks for each checklist
            const tasksSnapshot = await doc.ref.collection('tasks').get();
            const tasks = tasksSnapshot.docs.map(taskDoc => ({
              id: taskDoc.id,
              ...taskDoc.data()
            }));

            return {
              id: doc.id,
              ...checklistData,
              tasks
            };
          });

          // Wait for all checklists and their tasks to be fetched
          const items = await Promise.all(checklistsWithTasksPromises);
          setChecklists(items);
          setLoading(false);
        },
        error: setError
      });
      return unsubscribe;
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }, [companyId]);

  return [checklists, isLoading, error];
};

export default useChecklists;

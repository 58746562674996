import React from 'react';
import { SvgIcon } from '@material-ui/core';

const PostIcon = props => {
  return (
    <SvgIcon {...props}>
      <svg width="22" height="22" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0.400391H2V14.4004H0V0.400391ZM4 0.400391H6V14.4004H4V0.400391ZM22 0.400391H8V14.4004H22V0.400391ZM10 12.4004L12.5 9.25039L14.29 11.4004L16.79 8.18039L20 12.4004H10Z" fill="#24103D" />
      </svg>
    </SvgIcon>
  );
};

export default PostIcon;

import { firestore, getCompanyRef } from 'src/api/firebase';
import { getTimestamp } from '../utils';
import firebase from 'firebase/app';

export const DEFAULT_AVATAR_IMAGE =
  'https://res.cloudinary.com/dwyuadnmg/image/upload/v1661412223/sqillz-2/profile_placeholder_circle_xoekmt.png';
export const addUser = ({ companyId, ...user }) => {
  var batch = firestore.batch();
  const root = getCompanyRef(companyId);
  const {
    role,
    team,
    location,
    name,
    newlyEmployed,
    phone,
    jobTitle,
    areAllTeamsSelected,
    areAllLocationsSelected
  } = user;

  const allTeams = team.map(item => ({ id: item.id, name: item.name }));

  const allTeamsIds = team.map(item => item.id);

  allTeams.forEach(({ id }) => {
    const teamRef = root.collection('teams').doc(id);

    batch.update(teamRef, {
      usersCount: firebase.firestore.FieldValue.increment(1)
    });
  });

  location.forEach(({ id }) => {
    const locationRef = root.collection('locations').doc(id);
    batch.update(locationRef, {
      usersCount: firebase.firestore.FieldValue.increment(1)
    });
  });

  const userDocId = root.collection('users').doc().id;
  const userRef = root.collection('users').doc(userDocId);

  const newUser = {
    locations: location.map(item => ({ id: item.id, name: item.name })),
    locationIds: location.map(item => item.id),
    teams: allTeams.map(item => ({ id: item.id, name: item.name })),
    teamIds: allTeamsIds,
    name: {
      first: name.split(' ')[0],
      last: name.split(' ')[1] ?? '',
      full: name
    },
    createdAt: getTimestamp(),
    roles: [role.name.toLowerCase()],
    newlyEmployed,
    phone: phone.trim(),
    jobTitle,
    insights: {
      totalPosts: 0
    },
    imageUrl: DEFAULT_AVATAR_IMAGE,
    allLocationsAccess: areAllLocationsSelected,
    allTeamsAccess: areAllTeamsSelected
  };

  batch.set(userRef, newUser);

  return batch.commit();
};

import { SvgIcon } from '@material-ui/core';
import React from 'react';

const Edit = props => {
  return (
    <SvgIcon {...props}>
      <path d="M10.3289 10.1658L4.81634 15.688L4.48822 18.1203C4.29134 19.468 4.1929 20.6185 4.25853 20.6513C4.32415 20.7171 5.4726 20.6513 6.7851 20.487L9.21324 20.1583L14.7258 14.6361C19.9102 9.40979 20.2383 9.01535 20.2383 7.83203C20.2383 6.12279 18.7945 4.67651 17.0883 4.67651C15.907 4.67651 15.5133 5.00521 10.3289 10.1658ZM17.8758 6.84593C18.7289 7.43759 18.7945 7.8649 18.0398 8.68665C17.5148 9.27831 17.482 9.27831 16.5633 8.39082C15.7101 7.56907 15.6773 7.47046 16.2023 6.91167C16.8586 6.18853 16.957 6.18853 17.8758 6.84593ZM15.3164 9.77136L16.1039 10.626L12.1992 14.5375C9.18042 17.5287 8.03198 18.449 7.27729 18.5476C6.35854 18.6463 6.29291 18.5805 6.39135 17.6602C6.45697 16.9041 7.37573 15.7866 10.3289 12.7954C12.4289 10.6917 14.2336 8.94961 14.3648 8.94961C14.4633 8.94961 14.8898 9.31118 15.3164 9.77136Z" />
    </SvgIcon>
  );
};

export default Edit;

import { func, string } from 'prop-types';
import React from 'react';
import ReactInputVerificationCode from 'react-input-verification-code';

import './index.css';
const InputVerificationCode = ({ onChange, value }) => {
  return (
    <div className="custom-styles">
      <ReactInputVerificationCode
        autoFocus
        length={6}
        placeholder=""
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

InputVerificationCode.propTypes = {
  onChange: func,
  value: string
};

export default InputVerificationCode;

import { firestore, getCompanyRef } from 'src/api/firebase';
import { getTimestamp } from '../utils';

export const addTeam = async ({ companyId, name, isChatEnabled }) => {
  var batch = firestore.batch();
  const root = getCompanyRef(companyId);

  const teamDocId = root.collection('teams').doc().id;
  const teamRef = root.collection('teams').doc(teamDocId);

  const newTeam = {
    name,
    usersCount: 0,
    createdAt: getTimestamp(),
    isChatEnabled
  };

  batch.set(teamRef, newTeam);

  return batch.commit().then(() => {
    return { ...newTeam, id: teamDocId };
  });
};

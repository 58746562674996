import { firestore, getCompanyRef } from 'src/api/firebase';
import { getTimestamp } from '../utils';
import { addStreamToLocation } from './addStreamToLocation';
import { removeStreamFromLocation } from './removeStreamFromLocation';
import { addStreamToTeam } from './addStreamToTeam';
import { removeStreamFromTeam } from './removeStreamFromTeam';
import { addTeamToStream } from './addTeamToStream';
import { removeTeamFromStream } from './removeTeamFromStream';

export const editStream = ({ companyId, ...stream }) => {
  var batch = firestore.batch();
  const root = getCompanyRef(companyId);

  const streamDocId = stream.id;
  const streamRef = root.collection('streams').doc(streamDocId);

  const includesAllTeams = item => item?.id === 'all-teams';
  const includesAllLocations = item => item?.id === 'all-locations';

  const removesAllTeams = item => item?.id !== 'all-teams';
  const removesAllLocations = item => item?.id !== 'all-locations';

  const getLocationRef = location =>
    root.collection('locations').doc(location.id);

  const { post, teams, prevTeams, prevLocations, locations, ...rest } = stream;

  const streamTeams = teams?.filter(removesAllTeams);
  const streamLocations = locations?.filter(removesAllLocations);

  const updatedStream = {
    ...rest,
    updatedAt: getTimestamp()
  };

  if (stream.roles) {
    updatedStream.roles = stream.roles.map(item => item.name);
  }

  const newLocations = streamLocations;

  const locationsToAdd = streamLocations?.filter(
    item => !prevLocations.includes(item.id)
  );

  const teamsToAdd = streamTeams?.filter(item => !prevTeams.includes(item.id));

  const teamsToRemove = prevTeams?.filter(
    item => !streamTeams?.some(team => team.id === item)
  );

  const locationsToRemove = prevLocations?.filter(
    item => !streamLocations?.some(location => location.id === item)
  );

  if (newLocations) {
    updatedStream.locations = newLocations.map(item => item.id);
    updatedStream.locationsCount = newLocations.length;
  }

  if (teamsToAdd) {
    const availableForAllTeams = teams?.some(includesAllTeams);
    updatedStream.availableForAllTeams = availableForAllTeams;
    updatedStream.teamsCount = streamTeams.length;
    teamsToAdd.forEach(team => {
      const teamRef = root.collection('teams').doc(team.id);
      addStreamToTeam({ batch, teamRef, streamDocId });
    });
    teamsToAdd.forEach(team => {
      addTeamToStream({ batch, streamRef, teamID: team.id });
    });
  }

  if (teamsToRemove) {
    const availableForAllTeams = teams?.some(includesAllTeams);
    updatedStream.availableForAllTeams = availableForAllTeams;
    teamsToRemove.forEach(teamID => {
      const teamRef = root.collection('teams').doc(teamID);
      removeStreamFromTeam({ batch, teamRef, streamDocId });
    });

    teamsToRemove.forEach(teamID => {
      removeTeamFromStream({ batch, streamRef, teamID });
    });
  }

  if (locationsToAdd) {
    const availableForAllLocations = locations.some(includesAllLocations);

    updatedStream.availableForAllLocations = availableForAllLocations;
    locationsToAdd.forEach(location => {
      const locationRef = getLocationRef(location);
      addStreamToLocation({ batch, locationRef, streamDocId });
    });
  }

  if (locationsToRemove) {
    const availableForAllLocations = locations.some(includesAllLocations);

    updatedStream.availableForAllLocations = availableForAllLocations;
    locationsToRemove.filter(removesAllLocations).forEach(location => {
      const locationRef = getLocationRef(location);
      removeStreamFromLocation({ batch, locationRef, streamDocId });
    });
  }

  batch.set(streamRef, updatedStream, { merge: true });

  return batch.commit();
};

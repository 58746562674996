import React from 'react';
import { SvgIcon } from '@material-ui/core';

const CheckBoxIcon = props => {
  return (
    <SvgIcon {...props}>
      <path
        d="M21 0H3C1.34315 0 0 1.34315 0 3V21C0 22.6569 1.34315 24 3 24H21C22.6569 24 24 22.6569 24 21V3C24 1.34315 22.6569 0 21 0Z"
        fill="#6E26FC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9252 8.21798C17.1204 8.41324 17.1204 8.72982 16.9252 8.92508L10.6395 15.2108C10.5457 15.3046 10.4185 15.3573 10.2859 15.3573C10.1533 15.3573 10.0261 15.3046 9.93235 15.2108L7.07516 12.3536C6.8799 12.1584 6.8799 11.8418 7.07516 11.6465C7.27042 11.4513 7.587 11.4513 7.78226 11.6465L10.2859 14.1502L16.218 8.21798C16.4133 8.02272 16.7299 8.02272 16.9252 8.21798Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default CheckBoxIcon;

import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import BackButton from 'src/components/Buttons/BackButton';
import UsersList from 'src/components/UsersList';
import ViewHeader from 'src/components/ViewHeader';
import useActivatedUsers from 'src/api/insights/useActivatedUsers';
import ArrowRightCentered from 'src/icons/ArrowRightCentered';

export const ActivatedUsers = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onClickGoBack = () => navigate(-1);
  const [data, isLoading, error] = useActivatedUsers();

  const arrayOfObjToString = arr =>
    !!arr?.length ? arr?.map(obj => obj.name).join(', ') : '-';

  const mapData = () =>
    !!data.length
      ? data?.map(user => ({
          imageUrl: user?.imageUrl,
          [t('name')]: user?.name?.full ?? 'No name',
          [t('location')]: arrayOfObjToString(user?.locations),
          [t('team')]: arrayOfObjToString(user?.teams),
          // [t('role')]: getRoles(user?.roles),
          [t('activated')]: user.activated
        }))
      : null;

  const columns = [
    { name: t('name'), align: 'left' },
    { name: t('location'), align: 'left' },
    { name: t('team'), align: 'center' },
    // { name: t('role'), align: 'center' },
    { name: t('activated'), align: 'center' }
  ];

  return (
    <div className={classes.mainWrapper}>
      <Box>
        <div
          className={classes.backButtonWrapper}
          onClick={() => onClickGoBack()}
        >
          <BackButton />
        </div>
        <Typography variant="h3" className={classes.routeText}>
          {t('insights')} <ArrowRightCentered height={30} />{' '}
          {t('activatedUsers')}
        </Typography>
      </Box>
      <ViewHeader
        title={t('activatedUsers')}
        containerStyle={classes.containerStyle}
        subtitle={`${(data && data.length) || 0} ${t('users')}`}
      />
      <UsersList columns={columns} columnsData={mapData()} data={data} />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    paddingTop: theme.spacing(10)
  },
  routeText: {
    color: '#676570'
  },
  root: {
    minHeight: '70vh'
  },
  backButtonWrapper: {
    position: 'absolute',
    marginTop: '-50px',
    zIndex: 1200,
    cursor: 'pointer',
    marginLeft: '-12px'
  },
  addTeamButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(2)
  },
  containerStyle: {
    marginTop: theme.spacing(3)
  }
}));

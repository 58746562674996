import { firestoreArrayUnion } from '../utils';
export const addTeamToStream = ({ batch, streamRef, teamID }) => {
  batch.set(
    streamRef,
    {
      teams: firestoreArrayUnion(teamID)
    },
    { merge: true }
  );
};

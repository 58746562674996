import React from 'react';
import { SvgIcon } from '@material-ui/core';

const FormatItalic = props => {
  return (
    <SvgIcon {...props}>
      <path
        d="M11.0345 6V8.5H12.8762L10.0262 15.1667H7.70117V17.6667H14.3678V15.1667H12.5262L15.3762 8.5H17.7012V6H11.0345Z"
        fill="#181441"
      />
    </SvgIcon>
  );
};

export default FormatItalic;

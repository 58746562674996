import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { getCompanyRef } from 'src/api/firebase';
import { useCompanyId } from '../company/useCompanyId';

const usePostsObserver = () => {
  const { companyId } = useCompanyId();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [posts, setPosts] = useState([]);
  const root = getCompanyRef(companyId);

  const observePosts = observer => {
    return root
      .collection('posts')
      .where('isQuickPost', '==', true)
      .onSnapshot(observer);
  };

  useEffect(() => {
    try {
      const unsubscribe = observePosts({
        next: async snap => {
          let items = [];

          for (const doc of snap.docs) {
            const { createdAt, stream, author, ...rest } = doc.data();
            if (author) {
              items.push({
                id: doc.id,
                ...rest,
                date: dayjs(createdAt?.toDate()).format('YYYY-MM-DD'),
                stream: stream?.name,
                streamId: stream?.id,
                uploadedBy: author?.name
              });
            }
          }
          setPosts(items);
          setLoading(false);
        },
        error: setError
      });
      return unsubscribe;
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }, []);

  return [posts, isLoading, error];
};

export default usePostsObserver;

import { Box } from '@material-ui/core';
import { func, number } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAllUsers from 'src/api/teams/useAllUsers';
import { CounterText, TabPanel } from 'src/components';
import SearchInput from 'src/components/SearchInput';
import UsersList from 'src/components/UsersList';
import { USERS_TAB } from './OrganizationView';

const Users = ({ currentIndex, onClickUser }) => {
  const [users, isLoading, error] = useAllUsers();
  const [searchText, setSearchText] = useState('');
  const { t } = useTranslation();

  const searchFilter = user => {
    if (!user) {
      return false;
    }

    const lowerSearchText = searchText.toLowerCase();

    const isNameMatch = user.name?.full
      ?.toLowerCase()
      .includes(lowerSearchText);
    const isRoleMatch = user.roles?.[0]
      ?.toLowerCase()
      .includes(lowerSearchText);
    const isLocationMatch = user.locations?.[0]?.name
      ?.toLowerCase()
      .includes(lowerSearchText);

    return isNameMatch || isRoleMatch || isLocationMatch;
  };

  return (
    <TabPanel value={currentIndex} index={USERS_TAB}>
      <CounterText>{t('usersCount', { count: users?.length })}</CounterText>
      <Box width="43%" mb={2}>
        <SearchInput value={searchText} onChange={setSearchText} />
      </Box>
      <UsersList data={users.filter(searchFilter)} onClickUser={onClickUser} />
    </TabPanel>
  );
};

Users.propTypes = {
  currentIndex: number.isRequired,
  onClickUser: func.isRequired
};

export default Users;

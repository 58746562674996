import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';
import PropTypes, { any } from 'prop-types';
import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import IconLabelButton from 'src/components/Buttons/IconLabelButton';
import { useCompanyId } from 'src/api/company/useCompanyId';
import DeleteOutline from 'src/icons/DeleteOutline';
import ActionModal from 'src/components/ActionModal';
import TextFieldSection from 'src/components/TextFieldSection';
import Modal from 'src/components/Modals/Modal';
import { AuthContext } from 'src/providers/AuthProvider';
import { addQuickPost } from 'src/api/posts/addQuickPost';
import { editQuickPost } from 'src/api/posts/editQuickPost';
import LinkPreview from 'src/components/LinkPreview/LinkPreview';
import CheckBox from 'src/components/CheckBox';
import { deletePost } from 'src/api/posts/deletePost';
import { useLoading } from 'src/hooks';
import useToast from 'src/hooks/useToast';

const useStyles = makeStyles(theme => ({
  checkBoxSection: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3.5),
    marginBottom: theme.spacing(3)
  }
}));

const QuickPostModal = ({
  isOpen,
  title,
  handleClose,
  handleConfirmationModal,
  defaultValues,
  documents,
  streams
}) => {
  const { onError, onSuccess } = useToast();
  const classes = useStyles();
  const [previewData, setPreviewData] = useState(null);
  const theme = useTheme();

  const {
    userData: { name, id: userId }
  } = useContext(AuthContext);

  const [isActionModalOpen, setActionModalOpen] = useState(false);
  const { companyId } = useCompanyId();
  const { t } = useTranslation();

  const defaultStream = streams.find(
    item => item.name === defaultValues?.stream
  );
  const [selectedStream, setSelectedStream] = useState(
    defaultStream ? [defaultStream] : []
  );

  const [showAsHero, setShowAsHero] = useState(defaultValues?.isHero ?? false);
  const [selectedDocument, setSelectedDocument] = useState(
    defaultValues?.document ? [defaultValues?.document] : []
  );
  const { withLoading, isLoading } = useLoading();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { isValid, errors, isDirty, dirtyFields },
    control,
    trigger,
    watch,
    getFieldState
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      ...(documents
        ? { document: selectedDocument }
        : { url: defaultValues?.url }),
      description: defaultValues?.description,
      stream: selectedStream,
      hashtags: defaultValues?.hashtags.toString(),
      showAsHero: defaultValues?.isHero ?? false
    }
  });
  const weblink = watch('url');

  const prepareData = data => {
    const {
      title,
      url,
      description,
      stream,
      document,
      hashtags,
      postType,
      showAsHero
    } = data;

    const preparedData = {
      title,
      isHero: showAsHero,
      views: 0,
      likesCount: 0,
      commentsCount: 0,
      stream,
      author: {
        id: userId,
        name: name.full
      },
      hashtags: hashtags?.match(/#[a-z]+/gi) ?? [],
      url: url,
      imageUrl: previewData ?? '',
      description,
      postType
    };

    if (document) {
      preparedData.url = document.url;
      preparedData.document = document;
      preparedData.imageUrl =
        'https://res.cloudinary.com/dwyuadnmg/image/upload/v1669728866/sqillz-2/fallbacks/document_trwouq.png';
    }

    return preparedData;
  };

  const shouldEditPost = async dataToUpdate => {
    try {
      await withLoading(() =>
        editQuickPost({ companyId, showAsHero, ...dataToUpdate })
      );
      handleClose();
      onSuccess('Post edited');
    } catch (error) {
      console.log(error);
      onError('Error editing post');
    }
  };

  const onSubmit = async data => {
    const isEditing = Boolean(defaultValues);

    let postType = 'link';

    if (documents) {
      postType = 'document';
    }

    try {
      if (isEditing) {
        const changedFields = Object.keys(dirtyFields);

        const dataToUpdate = { id: defaultValues.id, defaultStream };
        changedFields.forEach(item => {
          dataToUpdate[item] = data[item];
        });

        shouldEditPost(dataToUpdate);
      } else {
        const dataToAdd = prepareData({ ...data, postType });
        try {
          await withLoading(() => addQuickPost({ companyId, ...dataToAdd }));
          handleClose();
          onSuccess('Post added');
        } catch (error) {
          onError('Error adding post');
        }
      }
    } catch (error) {}
  };

  const onClickChooseStream = stream => {
    setSelectedStream([stream]);
    setValue('stream', stream, { shouldValidate: true, shouldDirty: true });
  };

  const onClickChooseDocument = document => {
    setSelectedDocument([document]);
    setValue('document', document, { shouldValidate: true, shouldDirty: true });
  };

  const handleShowAsHeroChange = () => {
    setValue('showAsHero', !showAsHero, {
      shouldValidate: true,
      shouldDirty: true
    });
    setShowAsHero(!showAsHero);
  };

  const checkIfFieldIsValid = field => {
    if (defaultValues) {
      return !getFieldState(field).error;
    }
    return !getFieldState(field).isDirty ? false : !getFieldState(field).error;
  };

  const checkIfThereAreChanges = () => {
    return isDirty;
  };

  const renderTextFields = () =>
    data.map(({ id, ...rest }, index) => (
      <>
        <TextFieldSection key={id} {...rest} />
        {index === 0 && dirtyFields['url'] && !errors['url'] && (
          <LinkPreview
            setImageUrl={setPreviewData}
            url={
              weblink.startsWith('https://') ? weblink : 'https://' + weblink
            }
          />
        )}
      </>
    ));
  const data = [
    documents
      ? {
          id: 'document',
          name: 'document',
          title: t('document'),
          placeholder: t('selectDocument'),
          control,
          register,
          isValid: selectedDocument.length > 0,
          onOptionClick: onClickChooseDocument,
          options: [
            {
              values: documents
            }
          ],
          selectedOption: selectedDocument
        }
      : {
          id: 'url',
          placeholder: t('placeholderLink'),
          title: t('weblink'),
          isValid: checkIfFieldIsValid('url'),
          control,
          name: 'url',
          defaultValue: defaultValues?.url,
          rules: {
            pattern: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
          }
        },
    {
      id: 'title',
      placeholder: t('title'),
      title: t('title'),
      isValid: checkIfFieldIsValid('title'),
      control,
      name: 'title',
      defaultValue: defaultValues?.title
    },
    {
      id: 'description',
      placeholder: t('writeSomething'),
      title: t('description'),
      isValid: checkIfFieldIsValid('description'),
      control,
      name: 'description',
      defaultValue: defaultValues?.description,
      multiline: true,
      rules: {
        maxLength: 150
      }
    },
    {
      id: 'hashtags',
      placeholder: t('placeholderHashtags'),
      title: t('hashtags'),
      isValid: !errors['hashtags'],
      control,
      name: 'hashtags',
      defaultValue: defaultValues?.hashtags,
      multiline: true,
      rules: {
        maxLength: 150,
        required: false
      }
    },
    {
      id: 'stream',
      name: 'stream',
      title: t('stream'),
      placeholder: t('selectStream'),
      control,
      register,
      isValid: selectedStream.length > 0,
      // isValid: true,
      onOptionClick: onClickChooseStream,
      options: [
        {
          values: streams?.map(({ id, name, isOnboardingStream }) => ({
            id,
            name,
            isOnboardingStream
          }))
        }
      ],
      selectedOption: selectedStream
    }
  ];

  const onDeletPostPress = () => {
    toggleActionModal();
  };

  const toggleActionModal = () => {
    setActionModalOpen(prevState => !prevState);
  };

  const handleDeletePost = async () => {
    try {
      await withLoading(() => deletePost({ companyId, ...defaultValues }));
      handleClose();
      onSuccess('Post deleted');
    } catch (error) {
      onError('Error deleting post');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      handleClose={
        checkIfThereAreChanges() ? handleConfirmationModal : handleClose
      }
      isFormValid={isValid && isDirty}
      isLoading={isLoading}
    >
      <form id="hook-form" noValidate onSubmit={handleSubmit(onSubmit)}>
        {renderTextFields()}
        <div className={classes.checkBoxSection}>
          <CheckBox
            {...register('showAsHero', { required: false })}
            whiteBackground
            checked={showAsHero}
            onChange={handleShowAsHeroChange}
          />
          <Typography variant="subtitle2">{t('showAsHero')}</Typography>
        </div>
        {defaultValues && (
          <Box ml={3}>
            <IconLabelButton
              icon={() => <DeleteOutline />}
              label={t('deletePost')}
              underline
              color={theme.palette.secondary.main}
              onClick={onDeletPostPress}
            />
          </Box>
        )}
      </form>
      <ActionModal
        open={isActionModalOpen}
        title={t('deletePostConfirmation')}
        primaryButtonTitle={t('deletePost')}
        onSecondaryButtonPress={toggleActionModal}
        onPrimaryButtonPress={handleDeletePost}
      />
    </Modal>
  );
};

QuickPostModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  introText: PropTypes.string,
  handleConfirmationModal: PropTypes.func,
  handleClose: PropTypes.func,
  onClickSave: PropTypes.func,
  addLearerSpinner: PropTypes.bool,
  defaultValues: any,
  documents: any, //TODO: add shape
  streams: any //TODO: add shape
};

export default QuickPostModal;

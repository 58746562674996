import React from 'react';
import { SvgIcon } from '@material-ui/core';

const FormatOrderedList = props => {
  return (
    <SvgIcon {...props}>
      <path
        d="M4.09961 15.8333H5.76628V16.25H4.93294V17.0833H5.76628V17.5H4.09961V18.3333H6.59961V15H4.09961V15.8333ZM4.93294 8.33333H5.76628V5H4.09961V5.83333H4.93294V8.33333ZM4.09961 10.8333H5.59961L4.09961 12.5833V13.3333H6.59961V12.5H5.09961L6.59961 10.75V10H4.09961V10.8333ZM8.26628 5.83333V7.5H19.9329V5.83333H8.26628ZM8.26628 17.5H19.9329V15.8333H8.26628V17.5ZM8.26628 12.5H19.9329V10.8333H8.26628V12.5Z"
        fill="#181441"
      />
    </SvgIcon>
  );
};

export default FormatOrderedList;

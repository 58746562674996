import React, { useEffect, useRef, useState } from 'react';
import ViewHeader from 'src/components/ViewHeader';
import { useTranslation } from 'react-i18next';
import Tabs from 'src/components/Tabs';
import TabPanel from 'src/components/TabPanel/TabPanel';
import { StreamsSubView } from './components/streams/StreamsSubView';
import { DocumentsSubView } from './components/document/DocumentsSubView';
import { QuickPostSubView } from './components/quick-post/QuickPostSubView';
import IconLabelButton from 'src/components/Buttons/IconLabelButton';
import styled from 'styled-components';
import CounterText from 'src/components/CounterText/CounterText';
import useStreams from 'src/api/streams/useStreams';
import usePostsObserver from 'src/api/posts/usePostsObserver';
import useDocumentsObserver from 'src/api/documents/useDocumentsObserver';
import QuickPostModal from './components/quick-post/QuickPostModal';
import useMediaUpload from 'src/hooks/useMediaUpload';
import useUser from 'src/hooks/useUser';
import { addDocument } from 'src/api/documents/addDocument';
import useToast from 'src/hooks/useToast';
import ConfirmationModal from 'src/components/ConfirmationModal';
import { useLocations } from 'src/api';
import useAllTeams from 'src/api/teams/useAllTeams';
import { removePdfSuffix } from 'src/utils/removePdfSuffix';

export const ContentView = () => {
  const { t } = useTranslation();
  const tabs = [t('streams'), t('documents'), t('quickPost')];
  const [value, setValue] = useState(0);
  const hiddenFileInput = useRef(null);
  const [displayAddStreamModal, setDisplayAddStreamModal] = useState(false);
  const [isOpen, setIsOpen] = useState(null);
  const [quickPost, setQuickPost] = useState(null);
  const isStreamsTab = value === 0;
  const isDocumentsTab = value === 1;
  const isQuickPostTab = value === 2;
  const [selectedFile, setSelectedFile] = useState();
  const [url, isUploading] = useMediaUpload(selectedFile);
  const { companyId, name, id, jobTitle } = useUser();

  const [streams, isLoading, error] = useStreams();
  const [posts] = usePostsObserver();
  const [documents] = useDocumentsObserver();
  const { onError, onSuccess } = useToast();

  const [teams, isLoadingTeams] = useAllTeams();
  const [locations, isLoadingLocations] = useLocations();

  useEffect(() => {
    const setDocument = async () => {
      const newDocument = {
        author: {
          id,
          name: name.full,
          jobTitle: jobTitle ?? ''
        },
        title: removePdfSuffix(selectedFile?.name),
        document: {
          type: 'document',
          url: url.url
        }
      };
      try {
        await addDocument({ companyId, ...newDocument });
        onSuccess(t('success.upload'));
      } catch (error) {
        onError(t('error.uploadFailed'), () => {});
      } finally {
        setSelectedFile(null);
      }
    };

    if (!!url.url) {
      setDocument();
      setSelectedFile(null);
    }
  }, [url, companyId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );

  const hideQuickPostModal = () => {
    if (quickPost) {
      setQuickPost(null);
    }
    if (isOpen) {
      setIsOpen(null);
    }
    setDisplayConfirmationModal(false);
  };

  const openQuickPostModal = modal => {
    setIsOpen(modal);
  };

  const addStream = () => {
    return (
      <IconLabelButton
        label={t('addStream')}
        onClick={() => setDisplayAddStreamModal(true)}
        underline
      />
    );
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleUpload = event => {
    const file = event.target.files[0]; // get the file
    setSelectedFile(file);
  };

  const pickDocument = () => {
    return (
      <>
        <Input
          type="file"
          onChange={handleUpload}
          name="pdf"
          accept=".pdf"
          ref={hiddenFileInput}
        />
        <IconLabelButton
          label={t('addDocument')}
          onClick={handleClick}
          underline
        />
      </>
    );
  };

  const addQuickPost = () => {
    return (
      <>
        <IconLabelButton
          label={t('postWeblink')}
          onClick={() => openQuickPostModal('link')}
          underline
        />
        <Padding />
        <IconLabelButton
          label={t('postDocument')}
          onClick={() => openQuickPostModal('document')}
          underline
        />
      </>
    );
  };

  const handleListItemClick = selectedItem => {
    setQuickPost(selectedItem);
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ViewHeader title={t('content')} />
      <Tabs tabs={tabs} handleChange={handleChange} value={value}>
        {isStreamsTab && addStream()}
        {isDocumentsTab && pickDocument()}
        {isQuickPostTab && addQuickPost()}
      </Tabs>
      <TabPanel value={value} index={0}>
        <CounterText>
          {t('streamsCount', { count: streams?.length ?? 0 })}
        </CounterText>
        <StreamsSubView
          streams={streams}
          locations={locations}
          teams={teams}
          displayAddStreamModal={displayAddStreamModal}
          setDisplayAddStreamModal={() =>
            setDisplayAddStreamModal(prevState => !prevState)
          }
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CounterText>
          {t('documentsCount', { count: documents.length })}
        </CounterText>
        <DocumentsSubView documents={documents} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CounterText>{t('postsCount', { count: posts.length })}</CounterText>
        <QuickPostSubView posts={posts} onListItemClick={handleListItemClick} />
        {(isOpen === 'link' || quickPost?.postType === 'link') && (
          <QuickPostModal
            isOpen
            handleConfirmationModal={() => setDisplayConfirmationModal(true)}
            handleClose={hideQuickPostModal}
            title={quickPost ? t('editWeblink') : t('shareWeblink')}
            defaultValues={quickPost}
            streams={streams}
          />
        )}
        {(isOpen === 'document' || quickPost?.postType === 'document') && (
          <QuickPostModal
            isOpen
            handleConfirmationModal={() => setDisplayConfirmationModal(true)}
            handleClose={hideQuickPostModal}
            title={quickPost ? t('editDocument') : t('shareDocument')}
            documents={documents}
            defaultValues={quickPost}
            streams={streams}
          />
        )}
        {displayConfirmationModal && (
          <ConfirmationModal
            open={displayConfirmationModal}
            title={
              !quickPost ? t('exitWithoutPublishing') : t('exitWithoutSave')
            }
            subTitle={!quickPost ? t('exitWarning1') : t('exitWarning2')}
            primaryButtonTitle={
              !quickPost ? t('CONTINUE EDITING') : t('CONTINUE EDITING')
            }
            secondaryButtonTitle={
              !quickPost ? t('exitWithoutPublish') : t('exitWithoutPublish2')
            }
            onSecondaryButtonPress={hideQuickPostModal}
            onPrimaryButtonPress={() => setDisplayConfirmationModal(false)}
          />
        )}
      </TabPanel>
    </div>
  );
};

const Padding = styled.div`
  margin: 16px;
`;

const Input = styled.input`
  display: none;
`;

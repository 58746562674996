import {
  Box,
  makeStyles,
  Paper,
  Typography,
  useTheme
} from '@material-ui/core';
import React from 'react';
import PropTypes, { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import LocationsIcon from 'src/icons/LocationsIcon';
import TeamsIcon from 'src/icons/TeamsIcon';
import ChecklistsDark from 'src/icons/ChecklistsDark';
import ToolTip from './ToolTip';

const useStyles = makeStyles(theme => ({
  paperCard: {
    marginBottom: theme.spacing(2),
    background: theme.palette.common.white,
    borderRadius: 12,
    border: `1px solid ${theme.palette.common.white}`,
    padding: theme.spacing(3),
    '&:hover': {
      border: '1px solid #6E26FC'
    },
    width: '100%'
  },
  statusText: {
    fontSize: 12,
    color: theme.palette.text.hint,
    fontWeight: '600',
    marginBottom: 4
  },
  statusTextPublished: {
    color: theme.palette.primary.main,
  },
  locationTitleText: {
    fontSize: 12,
    color: theme.palette.text.hint,
    fontWeight: '600',
    marginBottom: 4
  },
  locationNameText: {
    color: theme.palette.grey[800],
    fontWeight: '700',
    fontSize: 32
  },
  locationInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  locationInfoSection: {
    display: 'flex',
    marginTop: theme.spacing(2),
    justifyContent: 'space-between',
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  locationInfoPaper: {
    background: theme.palette.background.lightBlue,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: 12,
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textIconSection: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    },
    alignItems: 'center'
  },
  roleInfo: {
    color: theme.palette.primary.main,
    fontSize: 12,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textTransform: 'capitalize',
    fontWeight: '600'
  },
  value: {
    color: theme.palette.background.dark
  },
  cardRoot: {
    display: 'flex',
    '& > *': {
      '&:not(:first-child)': {
        marginLeft: theme.spacing(2)
      }
    }
  }
}));

const ChecklistCard = ({
  title,
  isPublished,
  isDraft,
  locationsCount,
  teamsCount,
  taskCount,
  onEditClick,
  onDeleteClick
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const items = [
    {
      id: 'locations',
      Icon: LocationsIcon,
      title: t('locations'),
      value: locationsCount
    },
    {
      id: 'teams',
      Icon: TeamsIcon,
      title: t('teams'),
      value: teamsCount
    },
    {
      id: 'tasks',
      Icon: ChecklistsDark,
      title: t('tasks'),
      value: taskCount
    },
  ];

  return (
    <div className={classes.cardRoot}>
      <Paper elevation={0} className={classes.paperCard}>
        <Box flexDirection="row" display="flex" justifyContent="space-between">
          <Box>
            {isPublished && (
            <Typography className={[classes.statusText, classes.statusTextPublished]} variant="h5">
              {t('published')}
            </Typography>)}
            {isDraft && (
            <Typography className={classes.statusText} variant="h5">
              {t('draft')}
            </Typography>)}
            <div className={classes.locationInfo}>
              <Typography className={classes.locationNameText} variant="h3">
                {title}
                <br />
              </Typography>
            </div>
          </Box>
          <Box position="relative" right={-8} top={-4}>
            <ToolTip onClickEdit={onEditClick} onClickDelete={onDeleteClick} />
          </Box>
        </Box>
        <div className={classes.cardRoot}>
          {items.map(({ Icon, title, value, id }) => (
            <Paper key={id} elevation={0} className={classes.locationInfoPaper}>
              <div className={classes.textIconSection}>
                <Icon style={{ color: theme.palette.text.hint }} />
                <Typography className={classes.locationTitleText} variant="h5">
                  {title}
                </Typography>
              </div>
              <Typography className={classes.value} variant="h3">
                {value}
              </Typography>
            </Paper>
          ))}
        </div>
      </Paper>
    </div>
  );
};

ChecklistCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  isPublished: PropTypes.bool,
  isDraft: PropTypes.bool,
  locationsCount: PropTypes.number,
  teamsCount: PropTypes.number,
  taskCount: PropTypes.number,
  onEditClick: func,
  onDeleteClick: func
};

export default ChecklistCard;

export const removeWhitespace = Object.defineProperty(
  String.prototype,
  'removeWhitespace',
  {
    value: function() {
      return this.replace(/\s/g, '');
    }
  }
);

export const format = Object.defineProperty(String.prototype, 'format', {
  value: function() {
    return '+46 ' + this;
  }
});

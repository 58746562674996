import React from 'react';
import { SvgIcon } from '@material-ui/core';

const DeleteOutline = props => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2885_69500)">
          <path
            d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM8 9H16V19H8V9ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z"
            fill="#0B0B0C"
          />
        </g>
        <defs>
          <clipPath id="clip0_2885_69500">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
      ;
    </SvgIcon>
  );
};

export default DeleteOutline;

import { firestore, getCompanyRef } from 'src/api/firebase';
import firebase from 'firebase/app';

export const deleteUser = ({ companyId, ...user }) => {
  var batch = firestore.batch();
  const root = getCompanyRef(companyId);
  const { teams, locations, id } = user;

  teams.forEach(({ id }) => {
    const teamRef = root.collection('teams').doc(id);
    batch.update(teamRef, {
      usersCount: firebase.firestore.FieldValue.increment(-1)
    });
  });

  locations.forEach(({ id }) => {
    const locationRef = root.collection('locations').doc(id);
    batch.update(locationRef, {
      usersCount: firebase.firestore.FieldValue.increment(-1)
    });
  });

  const userRef = root.collection('users').doc(id);

  batch.delete(userRef);

  return batch.commit();
};

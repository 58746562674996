import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TextFieldSection from '../../TextFieldSection';
import { useForm } from 'react-hook-form';
import { addTeam } from 'src/api/teams/addTeam';
import { useCompanyId } from 'src/api/company/useCompanyId';
import useToast from 'src/hooks/useToast';
import CheckBox from 'src/components/CheckBox';
import { editTeam } from 'src/api/teams/editTeam';
import { useLoading } from 'src/hooks';

const useStyles = makeStyles(theme => ({
  contentContainer: {
    maxWidth: '80%',
    marginTop: '-1rem'
  },
  textFieldSection: {
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  textFieldTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  textFieldPlacement: {
    marginLeft: '1rem',
    marginTop: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: '0.4rem'
    }
  },
  description: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  divider: {
    marginBottom: theme.spacing(2)
  },
  checkBoxSection: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3.5)
  },
  sectionStyle: {
    marginBottom: theme.spacing(1)
  }
}));

const AddTeamModal = ({
  isOpen,
  title,
  introText,
  defaultValues,
  handleClose,
  onSuccessfulSubmit,
  onSuccessfulEdit
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isLoading, withLoading } = useLoading();
  const { companyId } = useCompanyId();
  const { onError, onSuccess } = useToast();
  const [isChatEnabled, setChatEnabled] = useState(
    defaultValues?.isChatEnabled ?? true
  );

  const isEditing = defaultValues?.name;

  const {
    handleSubmit,
    formState: { isValid, errors },
    control,
    getFieldState,
    register,
    setValue
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      name: defaultValues?.name ?? 'Hello world!',
      isChatEnabled
    }
  });
  const checkIfFieldIsValid = field => {
    if (defaultValues) {
      return !getFieldState(field).error;
    }
    return !getFieldState(field).isDirty ? false : !getFieldState(field).error;
  };

  const fields = [
    {
      id: 'name',
      placeholder: t('nameThisTeam'),
      title: t('name'),
      isValid: checkIfFieldIsValid('name'),
      control,
      name: 'name',
      defaultValue: defaultValues?.name
    }
  ];

  const handleChatEnabledChange = () => {
    setValue('isChatEnabled', !isChatEnabled, {
      shouldValidate: true,
      shouldDirty: true
    });
    setChatEnabled(!isChatEnabled);
  };

  const onTeamAdded = (msg, payload) => {
    onSuccess(msg || t('success'));
    if (isEditing) {
      onSuccessfulEdit?.(payload) ?? handleClose();
    } else {
      onSuccessfulSubmit?.(payload) ?? handleClose();
    }
  };

  const onSubmit = async ({ name, isChatEnabled }) => {
    withLoading(async () => {
      try {
        if (defaultValues?.name) {
          const result = await editTeam({
            name,
            companyId,
            team: defaultValues,
            isChatEnabled
          });
          onTeamAdded(t('changesSuccess'), result);
        } else {
          const values = {
            companyId,
            name,
            isChatEnabled
          };
          const newTeam = await addTeam(values);
          onTeamAdded(t('teamAdded'), newTeam);
        }
      } catch (error) {
        onError(error.message);
      }
    });
  };

  return (
    <form
      className={classes.contentContainer}
      id="hook-form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Modal
        isOpen={isOpen}
        title={title}
        introText={introText}
        handleClose={handleClose}
        isFormValid={isValid}
        isLoading={isLoading}
      >
        {fields.map(({ id, ...rest }) => (
          <TextFieldSection key={id} {...rest} />
        ))}
        <div className={classes.checkBoxSection}>
          <CheckBox
            {...register('isChatEnabled')}
            whiteBackground
            checked={isChatEnabled}
            onChange={handleChatEnabledChange}
          />
          <Typography variant="subtitle2">{t('Enable chat')}</Typography>
        </div>
      </Modal>
    </form>
  );
};

AddTeamModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  introText: PropTypes.string,
  handleClose: PropTypes.func,
  onSuccessfulSubmit: PropTypes.func,
  onSuccessfulEdit: PropTypes.func,
  defaultValues: PropTypes.object,
  learnersList: PropTypes.array
};

export default AddTeamModal;

const { default: i18n } = require('src/i18n');

export const getErrorMessage = errCode => {
  if (errCode === 'auth/invalid-phone-number') {
    return i18n.t('err.invalindPhone');
  }

  return i18n.t('err.default');
};

export const mockAsycReqFailure = () =>
  new Promise((res, rej) => setTimeout(() => rej('faking error'), 1000));

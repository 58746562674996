import { firestore, getCompanyRef } from 'src/api/firebase';
import { getTimestamp } from '../utils';
import firebase from 'firebase/app';

export const addQuickPost = ({ companyId, ...post }) => {
  var batch = firestore.batch();
  const root = getCompanyRef(companyId);

  const postDocId = root.collection('posts').doc().id;
  const postRef = root.collection('posts').doc(postDocId);
  const slidesDocId = postRef.collection('slides').doc().id;
  const slideRef = postRef.collection('slides').doc(slidesDocId);

  const addNewPost = () => {
    const isDocument = post.postType === 'document';
    const isLink = post.postType === 'link';

    const newPost = {
      ...post,
      isQuickPost: true,
      id: postDocId,
      createdAt: getTimestamp(),
      status: ''
    };

    if (isDocument) {
      newPost.isDocument = true;
    }
    if (isLink) {
      newPost.isLink = true;
    }

    batch.set(postRef, newPost, { merge: true });
  };

  const addNewSlide = () => {
    const newSlide = {
      index: 0,
      postId: postDocId,
      streamId: post.stream.id,
      id: slidesDocId,
      data: {
        description: post?.description ?? '',
        name: post?.document?.name ?? '',
        url: post?.url ?? '',
        type: post?.postType ?? 'link'
      }
    };
    batch.set(slideRef, newSlide);
  };

  const updateStreamPostsCount = () => {
    const streamRef = root.collection('streams').doc(post.stream.id);
    batch.set(
      streamRef,
      {
        postsCount: firebase.firestore.FieldValue.increment(1)
      },
      { merge: true }
    );
  };

  updateStreamPostsCount();
  addNewPost();
  addNewSlide();

  return batch.commit();
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { auth } from '../api/firebase';
import { getUserData } from '../api/user/getUserData';

export const AuthContext = React.createContext();

// eslint-disable-next-line
const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(undefined);
  const [error, setError] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const clearError = () => setError(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      if (user) {
        const token = await user.getIdTokenResult();
        let { companyId, isAdmin } = token.claims;

        if (isAdmin) {
          let userData = await getUserData({ user, companyId });
          setCompanyId(companyId);
          setUserData({ ...userData, companyId });
          setCurrentUser(user);
          navigate('/app');
        } else {
          setError(t('err.unauthorized'));
          await auth.signOut();
        }
      } else {
        setCurrentUser(null);
        setUserData(null);
        setCompanyId(null);
        navigate('/');
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        companyId,
        userData,
        error,
        clearError
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

import { firestore, getCompanyRef } from 'src/api/firebase';

export const deletePost = ({ companyId, ...post }) => {
  var batch = firestore.batch();
  const root = getCompanyRef(companyId);
  const { id } = post;

  const postRef = root.collection('posts').doc(id);

  batch.delete(postRef);

  return batch.commit();
};

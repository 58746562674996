import { firestoreArrayRemove } from '../utils';

export const removeStreamFromTeam = ({ batch, teamRef, streamDocId }) => {
  batch.set(
    teamRef,
    {
      streams: firestoreArrayRemove(streamDocId)
    },
    { merge: true }
  );
};

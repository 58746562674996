import { firestore, getCompanyRef } from 'src/api/firebase';

export const deleteDocument = ({ companyId, ...doc }) => {
  var batch = firestore.batch();
  const root = getCompanyRef(companyId);
  const { id } = doc;

  const docRef = root.collection('documents').doc(id);

  batch.delete(docRef);

  //TODO: DELETE DOC FROM CLOUDINARY

  return batch.commit();
};

import { createMuiTheme } from '@material-ui/core';

import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      input: {
        '&::placeholder': {
          color: '#9A98A3',
          opacity: 1,
          fontSize: 14,
          fontWeight: 500,
          fontFamily: 'Inter'
        },
        padding: '1.5px 0px'
      }
    }
  },
  palette: {
    background: {
      dark: '#24103D',
      default: '#F4F6F8',
      paper: '#F4F6F8',
      lightBlue: '#F8FAFD'
    },
    primary: {
      light: '#CEEFE7',
      mediumLight: '#7CDEC7',
      main: '#6E26FC',
      surface: '#EFF3F9',
      purple: '#6E26FC'
    },
    secondary: {
      main: '#0B0B0C',
      surface: '#433F6B'
    },
    transparent: {
      main: 'transparent'
    },
    text: {
      primary: '#0B0B0C',
      secondary: '#9A98A3',
      hint: '#9A98A3'
    },
    divider: '#E1EAF0',
    error: {
      main: '#C6355C'
    },
    success: {
      main: '#66A860'
    },
    grey: {
      300: '#AAA8BB',
      800: '#323138',
      400: '#9A98A3',
      500: '#A2A0AD',
      99: '#0B0B0C',
      5: '#F1F0F7'
    },
    action: {
      disabled: '#AAA8BB',
      disabledBackground: 'rgba(227, 225, 235, 1)'
    }
  },
  shadows,
  typography,
  zIndex: {
    modal: 1600
  }
});

export default theme;

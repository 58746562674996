import { makeStyles } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import { RichUtils, Modifier, EditorState } from 'draft-js';
import { bool, func, node, object, string } from 'prop-types';
import React, {useState} from 'react';
import EmojiComponent from './EmojiComponent';

const useStyles = makeStyles(theme => ({
  toggleButton: () => ({
    width: 45,
    border: 0,
    borderRadius: 23,
  })
}));

export const ToolbarButton = ({
  editorState,
  onChange,
  children,
  config,
  type,
  style
}) => {
  const classes = useStyles();
  const [emojiExpanded, setEmojiExpanded] = useState(false);
  const inlineStyle = editorState.getCurrentInlineStyle();
  const currentBlock = RichUtils.getCurrentBlockType(editorState);

  let isSelected = inlineStyle.has(style) || currentBlock === style;

  const handleClick = () => {
    let newState;
    if (type === 'inline') {
      newState = RichUtils.toggleInlineStyle(editorState, style);
    }

    if (type === 'block') {
      newState = RichUtils.toggleBlockType(editorState, style);
    }

    if (newState) {
      onChange(newState);
    }
  };

  const addEmoji = emoji => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      emoji,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    setEmojiExpanded(false);
  };

  if (type === 'emoji') {
    return (
      <>
        <ToggleButton
          onClick={() => setEmojiExpanded(!emojiExpanded)}
          classes={{ root: classes.toggleButton }}
          selected={isSelected}
        >
          {children}
        </ToggleButton>
        <EmojiComponent
          config={config}
          onChange={addEmoji}
          expanded={emojiExpanded}
          doExpand={() => setEmojiExpanded(true)}
          doCollapse={() => setEmojiExpanded(false)}
        />
      </>
    );
  }

  return (
    <ToggleButton
      onClick={handleClick}
      classes={{ root: classes.toggleButton }}
      selected={isSelected}
    >
      {children}
    </ToggleButton>
  );
};

ToolbarButton.propTypes = {
  editorState: object,
  modalHandler: object,
  config: object,
  onChange: func,
  first: bool,
  last: bool,
  type: string,
  style: string,
  children: node
};

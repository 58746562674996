import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  makeStyles,
  Typography
} from '@material-ui/core';
import { bool, func, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-rounded': {
      borderRadius: 12
    }
  },
  dialog: {
    background: theme.palette.background.lightBlue,
    width: 550,
    padding: theme.spacing(5)
  },
  title: {
    color: theme.palette.secondary.main,
    maxWidth: 430
  },
  textContainer: {
    marginBottom: theme.spacing(5),
    '& > *': {
      // textAlign: 'center'
    }
  },
  title: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px'
  },
  text: {
    marginTop: '8px',
    fontSize: '14px',
    color: '#5B5971'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  primaryButton: {
    borderRadius: 12,
    color: theme.palette.common.white,
    fontWeight: 700,
    fontSize: 12,
    width: '100%',
    height: 48
  },
  secondaryButton: {
    borderRadius: 12,
    background: theme.palette.common.white,
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: 12,
    width: '100%',
    border: `2px solid ${theme.palette.text.primary}`,
    height: 48
  }
}));

const ActionModal = ({
  title,
  primaryButtonTitle,
  secondaryButtonTitle,
  onPrimaryButtonPress,
  onSecondaryButtonPress,
  open,
  isLoading
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={open} className={classes.root}>
      <div className={classes.dialog}>
        <Box className={classes.textContainer}>
          <Typography className={classes.title}>{title}</Typography>
        </Box>
        <div className={classes.buttons}>
          <Button
            className={classes.secondaryButton}
            variant="contained"
            onClick={!isLoading && onSecondaryButtonPress}
          >
            {secondaryButtonTitle ?? t('cancel')}
          </Button>
          <Box mx={1} />
          <Button
            className={classes.primaryButton}
            variant="contained"
            color="primary"
            onClick={!isLoading && onPrimaryButtonPress}
          >
            {isLoading ? (
              <CircularProgress size={24} color="white" />
            ) : (
              primaryButtonTitle ?? t('confirm')
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

ActionModal.propTypes = {
  title: string,
  open: bool,
  isLoading: bool,
  primaryButtonTitle: string,
  secondaryButtonTitle: string,
  onPrimaryButtonPress: func,
  onSecondaryButtonPress: func
};

export default ActionModal;

import {
  Box,
  makeStyles,
  Typography,
  CircularProgress
} from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import PropTypes, { bool, func } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BackButton from '../Buttons/BackButton';
import ErrorMessage from '../ErrorMessage';

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: theme.spacing(2) / 1.5,
    minWidth: 112,
    marginLeft: theme.spacing(2),
    height: 48,
    '&:hover': {
      borderWidth: 2
    },
    '&:disabled': {
      border: '2px solid #9A98A3'
    }
  },
  saveButton: {
    border: '2px solid #6E26FC',
  },
  saveDraftButton: {
    border: '2px solid #6E26FC',
  },
  buttonText: {
    fontSize: 12,
    fontWeight: 700
  },
  enabledText: {
    color: theme.palette.primary.main
  },
  disabledText: {
    color: theme.palette.grey[400]
  }
}));

const ModalNavBar = ({
  handleClose,
  onSave,
  onSaveDraft,
  navBarRightButtonTitle,
  shouldShowMessage,
  closeMessage,
  isLoading,
  isLoadingDraft,
  hideRightButton,
  isFormValid
}) => {
  const classes = useStyles(isFormValid);
  const { t } = useTranslation();

  return (
    <Box component="div" display="flex" justifyContent="space-between">
      {handleClose ? <BackButton onClick={handleClose} /> : <Box />}
      {!hideRightButton && (
        <Box>
          {onSaveDraft && (
          <Button
            style={
              navBarRightButtonTitle?.length > 15 ? { width: '240px' } : {}
            }
            variant="outlined"
            color="primary"
            className={[classes.button, classes.saveDraftButton]}
            form="hook-form"
            disabled={!isFormValid}
            onClick={typeof onSaveDraft === 'function' ? onSaveDraft : undefined}
            type="submit"
          >
            {isLoadingDraft ? (
              <CircularProgress size={24} />
            ) : (
              <Typography
                className={clsx(
                  classes.buttonText,
                  isFormValid ? classes.enabledText : classes.disabledText
                )}
              >
                {navBarRightButtonTitle !== undefined
                  ? navBarRightButtonTitle
                  : t('saveAsDraft')}
              </Typography>
            )}
          </Button>)}
          <Button
            style={
              navBarRightButtonTitle?.length > 15 ? { width: '240px' } : {}
            }
            variant="outlined"
            color="primary"
            className={[classes.button, classes.saveButton]}
            form="hook-form"
            disabled={!isFormValid}
            onClick={typeof onSave === 'function' ? onSave : undefined}
            type="submit"
          >
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <Typography
                className={clsx(
                  classes.buttonText,
                  isFormValid ? classes.enabledText : classes.disabledText
                )}
              >
                {navBarRightButtonTitle !== undefined
                  ? navBarRightButtonTitle
                  : onSaveDraft ? t('saveAndPublish') : t('save')} 
              </Typography>
            )}
          </Button>
          <Box position="absolute" width={112}>
            <ErrorMessage
              successMessage={t('saved')}
              isOpen={shouldShowMessage}
              error={false}
              closeMessage={closeMessage}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

ModalNavBar.propTypes = {
  handleClose: PropTypes.func,
  onSave: PropTypes.func,
  onSaveDraft: PropTypes.func,
  closeMessage: func,
  navBarRightButtonTitle: PropTypes.string,
  shouldShowMessage: bool,
  isFormValid: bool,
  hideRightButton: bool,
  isLoading: bool,
  isLoadingDraft: bool
};

export default ModalNavBar;

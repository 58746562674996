import { firestoreArrayRemove } from '../utils';
export const removeTeamFromStream = ({ batch, streamRef, teamID }) => {
  batch.set(
    streamRef,
    {
      teams: firestoreArrayRemove(teamID)
    },
    { merge: true }
  );
};

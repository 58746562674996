import React, { useState } from 'react';
import ViewHeader from 'src/components/ViewHeader';
import { useTranslation } from 'react-i18next';
import { ChecklistsSubView } from './components/checklists/ChecklistsSubView';
import IconLabelButton from 'src/components/Buttons/IconLabelButton';
import styled from 'styled-components';
import useChecklists from 'src/api/checklists/useChecklists';
import { useLocations } from 'src/api';
import useAllTeams from 'src/api/teams/useAllTeams';

export const ChecklistsView = () => {
  const { t } = useTranslation();
  const [displayAddChecklistModal, setDisplayAddChecklistModal] = useState(false);

  const [checklists] = useChecklists();
  const [teams] = useAllTeams();
  const [locations] = useLocations();

  const addChecklist = () => {
    return (
      <IconLabelButton
        label={t('createChecklist')}
        onClick={() => setDisplayAddChecklistModal(true)}
        underline
      />
    );
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ViewHeader title={t('checklists')} />
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
        {addChecklist()}
        </div>
        <ChecklistsSubView
          checklists={checklists}
          locations={locations}
          teams={teams}
          defaultValues={{availableForAllTeams: true, availableForAllLocations: true}}
          displayAddChecklistModal={displayAddChecklistModal}
          setDisplayAddChecklistModal={() =>
            setDisplayAddChecklistModal(prevState => !prevState)
          }
        />
    </div>
  );
};

const Padding = styled.div`
  margin: 16px;
`;

const Input = styled.input`
  display: none;
`;

import React from 'react';
import { SvgIcon } from '@material-ui/core';

const UsersIcon = props => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_3984_72976)">
        <path
          d="M12.25 12C14.46 12 16.25 10.21 16.25 8C16.25 5.79 14.46 4 12.25 4C10.04 4 8.25 5.79 8.25 8C8.25 10.21 10.04 12 12.25 12ZM12.25 14C9.58 14 4.25 15.34 4.25 18V20H20.25V18C20.25 15.34 14.92 14 12.25 14Z"
          fill="#9A98A3"
        />
      </g>
      <defs>
        <clipPath id="clip0_3984_72976">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.25)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default UsersIcon;

import React, { useState } from 'react';
import AddStreamModal from './StreamModal';
import { DisplayAllStreams } from './DisplayAllStreams';
import PropTypes, { any } from 'prop-types';
import ActionModal from 'src/components/ActionModal';
import { useTranslation } from 'react-i18next';
import { deleteStream } from 'src/api/streams/deleteStream';
import { useCompanyId } from 'src/api/company/useCompanyId';
import useToast from 'src/hooks/useToast';
import { useLoading } from 'src/hooks';

export const StreamsSubView = ({
  displayAddStreamModal,
  setDisplayAddStreamModal,
  streams,
  locations,
  teams,
  ...props
}) => {
  const [streamToDelete, setStreamToDelete] = useState(null);
  const [streamToEdit, setStreamToEdit] = useState(null);
  const { t } = useTranslation();
  const { onError, onSuccess } = useToast();
  const { isLoading, withLoading } = useLoading();
  const { companyId } = useCompanyId();

  const handleDeleteClick = item => {
    setStreamToDelete(item);
  };

  const handleEditClick = item => {
    setStreamToEdit(item);
  };

  const shouldDeleteStream = async () => {
    try {
      await withLoading(() => deleteStream({ companyId, ...streamToDelete }));
      onSuccess(t('streamDeleted'));
      setStreamToDelete(null);
    } catch (error) {
      console.log(error);
      onError('Error deleting stream');
    }
  };

  return (
    <>
      <DisplayAllStreams
        {...props}
        onDeleteClick={handleDeleteClick}
        onEditClick={handleEditClick}
        streams={streams}
      />
      {displayAddStreamModal && (
        <AddStreamModal
          isOpen
          handleClose={setDisplayAddStreamModal}
          locations={locations}
          teams={teams}
        />
      )}
      {streamToDelete && (
        <ActionModal
          open
          title={t('deleteStreamConfirmation')}
          onPrimaryButtonPress={shouldDeleteStream}
          onSecondaryButtonPress={() => setStreamToDelete(null)}
          isLoading={isLoading}
        />
      )}
      {streamToEdit && (
        <AddStreamModal
          defaultValues={streamToEdit}
          isOpen
          locations={locations}
          handleClose={() => setStreamToEdit(null)}
          teams={teams}
        />
      )}
    </>
  );
};

//TODO: fix any type
StreamsSubView.propTypes = {
  displayAddStreamModal: PropTypes.bool,
  setDisplayAddStreamModal: PropTypes.func,
  streams: any,
  locations: any,
  teams: any
};

import { useEffect, useState } from 'react';
import { getCompanyRef } from 'src/api/firebase';
import { useCompanyId } from '../company/useCompanyId';

const useUsersByLocationId = ({ locationId }) => {
  const { companyId } = useCompanyId();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);

  const root = getCompanyRef(companyId);

  const observeAllUsers = observer => {
    return root
      .collection('users')
      .where('locationIds', 'array-contains', locationId)
      .onSnapshot(observer);
  };

  useEffect(() => {
    try {
      const unsubscribe = observeAllUsers({
        next: async snap => {
          let items = [];
          for (const doc of snap.docs) {
            items.push({
              id: doc.id,
              ...doc.data()
            });
          }
          setUsers(items);
          setLoading(false);
        },
        error: setError
      });
      return unsubscribe;
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }, []);

  return [users, isLoading, error];
};

export default useUsersByLocationId;

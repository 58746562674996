import { Box, Button, Dialog, makeStyles, Typography } from '@material-ui/core';
import { bool, func, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-rounded': {
      borderRadius: 12
    }
  },
  dialog: {
    background: theme.palette.background.lightBlue,
    width: 550,
    padding: theme.spacing(5)
  },
  title: {
    maxWidth: 430,
    fontSize: 32,
    color: '#0B0B0C',
    fontWeight: 700
  },
  subTitle: {
    fontSize: 20,
    color: '#0B0B0C',
    marginTop: 20
  },
  textContainer: {
    marginBottom: theme.spacing(5),
    '& > *': {
      // textAlign: 'center'
    }
  },
  text: {
    marginTop: 8,
    fontSize: 14,
    color: '#5B5971'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  primaryButton: {
    borderRadius: 12,
    color: theme.palette.common.white,
    fontWeight: 700,
    fontSize: 12,
    width: '100%',
    height: 48
  },
  secondaryButton: {
    borderRadius: 12,
    background: theme.palette.common.white,
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: 12,
    width: '100%',
    border: `2px solid ${theme.palette.text.primary}`,
    height: 48
  }
}));

const ConfirmationModal = ({
  title,
  subTitle,
  primaryButtonTitle,
  secondaryButtonTitle,
  onPrimaryButtonPress,
  onSecondaryButtonPress,
  open
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={open} className={classes.root}>
      <div className={classes.dialog}>
        <Box className={classes.textContainer}>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.subTitle}>{subTitle}</Typography>
        </Box>
        <div className={classes.buttons}>
          <Button
            className={classes.secondaryButton}
            variant="contained"
            onClick={onSecondaryButtonPress}
          >
            {secondaryButtonTitle ?? t('cancel')}
          </Button>
          <Box mx={1} />
          <Button
            className={classes.primaryButton}
            variant="contained"
            color="primary"
            onClick={onPrimaryButtonPress}
          >
            {primaryButtonTitle ?? t('confirm')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

ConfirmationModal.propTypes = {
  title: string,
  open: bool,
  primaryButtonTitle: string,
  secondaryButtonTitle: string,
  subTitle: string,
  onPrimaryButtonPress: func,
  onSecondaryButtonPress: func
};

export default ConfirmationModal;

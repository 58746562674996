import React from 'react';
import { makeStyles } from '@material-ui/core';
import ViewHeader from 'src/components/ViewHeader';
import { useTranslation } from 'react-i18next';
import Overview from './Overview';
import useInsightsObserver from 'src/api/insights/useInsightsObserver';

const InsightsView = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [insights, isLoading, error] = useInsightsObserver();

  return (
    <div>
      <div className={classes.hideOnPrint}>
        <ViewHeader title={t('insights')} />
      </div>
      <Overview data={insights} />
    </div>
  );
};

export default InsightsView;

const useStyles = makeStyles(theme => ({
  tabsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  margin: {
    width: theme.spacing(3),
    display: 'inline-block'
  },
  img: {
    flex: 1,
    width: undefined,
    height: undefined
  },
  imgA: {},
  tabPrint: {},
  printing: {
    paddingBottom: theme.spacing(7),
    '@media print{@page {size: landscape}}': {}
  },
  hideOnPrint: {
    '@media print': {
      display: 'none'
    }
  },
  titlePrint: {}
}));

import { getCompanyRef } from 'src/api/firebase';
import { getTimestamp } from '../utils';

export const addDocument = ({ companyId, ...doc }) => {
  const root = getCompanyRef(companyId);
  const ref = root.collection('documents');

  const docId = ref.doc().id;
  const newDocument = {
    ...doc,
    createdAt: getTimestamp(),
    id: docId
  };

  return ref.doc(docId).set(newDocument);
};

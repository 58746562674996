import React from 'react';
import PropTypes from 'prop-types';
import QuickPostTableList from './QuickPostTableList';

export const QuickPostSubView = ({ posts, ...rest }) => {
  const columns = [
    { name: 'title' },
    { name: 'date' },
    { name: 'stream' },
    { name: 'uploadedBy' }
  ];

  return (
    <QuickPostTableList
      columns={columns}
      avatar={true}
      data={posts}
      fullData={posts}
      {...rest}
    />
  );
};

QuickPostSubView.propTypes = {
  displayAddWebLinkModal: PropTypes.bool,
  handleClose: PropTypes.any,
  posts: PropTypes.any,
  setDisplayAddDocumentModal: PropTypes.any,
  displayAddDocumentModal: PropTypes.bool
};

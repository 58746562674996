import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  makeStyles,
  Toolbar,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { string } from 'prop-types';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from 'src/providers/AuthProvider';
import ArrowBack from 'src/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.lightBlue,
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2.5)
  },
  avatar: {
    width: 48,
    height: 48,
    background: theme.palette.common.white
  }
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();
  const { userData, currentUser } = useContext(AuthContext);

  const firstName = 'First Name';
  const lastName = 'Last Name';
  const avatar = null;

  const [notifications] = useState([1]);
  let navigate = useNavigate();
  let location = useLocation();

  let shouldShowBackButton =
    location.pathname.includes('/app/courses/') ||
    location.pathname.includes('/app/users/') ||
    location.pathname.includes('/app/polls/');

  const onBackClick = () => {
    navigate(-1);
  };

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        {shouldShowBackButton && (
          <>
            <Box mx={13.5} />
            <IconButton color="secondary" onClick={onBackClick}>
              <ArrowBack />
            </IconButton>
          </>
        )}
        <Box flexGrow={1} />
        {avatar && <Avatar className={classes.avatar} />}
        <Box m={1} />
        <Typography variant="h6">{`${firstName} ${lastName}`}</Typography>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: string
};

export default TopBar;

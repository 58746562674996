import { firestoreArrayUnion } from '../utils';
export const addStreamToTeam = ({ batch, teamRef, streamDocId }) => {
  batch.set(
    teamRef,
    {
      streams: firestoreArrayUnion(streamDocId)
    },
    { merge: true }
  );
};

import { Avatar, Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes, { any } from 'prop-types';
import React, { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CheckBox from 'src/components/CheckBox';
import { useCompanyId } from 'src/api/company/useCompanyId';
import { deleteUser } from 'src/api/users/deleteUser';
import ActionModal from 'src/components/ActionModal';
import Modal from 'src/components/Modals/Modal';
import TextFieldSection from 'src/components/TextFieldSection';
import { addStream } from 'src/api/streams/addStream';
import { editStream } from 'src/api/streams/editStream';
import useToast from 'src/hooks/useToast';
import {
  useAllLocationsOption,
  useAllTeamsOption,
  useLoading
} from 'src/hooks';

const useStyles = makeStyles(theme => ({
  textFieldSection: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  textFieldTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  textFieldPlacement: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  checkBoxSection: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3.5)
  },
  dropdownContainer: {
    background: theme.palette.common.white,
    borderRadius: 8,
    border: `1px solid ${theme.palette.primary.surface}`,
    padding: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'space-between'
  },
  dropdownMenuItem: {
    color: theme.palette.secondary.main,
    fontSize: 14,
    fontWeight: 400,
    padding: theme.spacing(-2),
    minWidth: 550
  }
}));

const rolesData = [
  { id: 'learner', name: 'learner' },
  { id: 'creator', name: 'creator' },
  { id: 'admin', name: 'admin' }
];

const StreamModal = ({
  isOpen,
  handleClose,
  defaultValues,
  locations,
  teams
}) => {
  const { t } = useTranslation();
  const { companyId } = useCompanyId();
  const classes = useStyles();

  const allTeamsOption = useAllTeamsOption();
  const allLocationsOption = useAllLocationsOption();

  const defaultLocations = locations?.filter(item =>
    defaultValues?.locations?.includes(item.id)
  );

  if (defaultValues?.availableForAllLocations) {
    defaultLocations.push(allLocationsOption);
  }

  const defaultTeams = teams.filter(item =>
    defaultValues?.teams?.includes(item.id)
  );

  if (defaultValues?.availableForAllTeams) {
    defaultTeams.push(allTeamsOption);
  }

  const [selectedLocation, setSelectedLocation] = useState(
    defaultLocations ?? []
  );

  const [isActionModalOpen, setActionModalOpen] = useState(false);
  const defaultRoles = rolesData.filter(item =>
    defaultValues?.roles?.includes(item.name)
  );

  const [isOnboardingStream, setIsOnboardingStream] = useState(
    defaultValues?.isOnboardingStream ?? false
  );

  const [selectedTeam, setSelectedTeam] = useState(defaultTeams ?? []);
  const [selectedRole, setSelectedRole] = useState(defaultRoles ?? []);
  const { isLoading, withLoading } = useLoading();
  const { onError, onSuccess } = useToast();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { isValid, errors, isDirty, dirtyFields },
    control,
    getFieldState
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: useMemo(
      () => ({
        isOnboardingStream: defaultValues?.isOnboardingStream ?? false,
        name: defaultValues?.name,
        roles: selectedRole,
        locations: selectedLocation,
        teams: selectedTeam
      }),
      [selectedTeam]
    )
  });

  const handleEditStream = async dataToUpdate => {
    try {
      await editStream({ companyId, ...dataToUpdate });
      onSuccess(t('streamEdited'));
      handleClose();
    } catch (error) {
      console.log({ error });
      onError(t('streamEditError'), error.message);
    }
  };

  const shouldCreateStream = async data => {
    await addStream({ companyId, ...data });
    onSuccess(t('streamCreated'));
    handleClose();
  };

  const onSubmit = async data => {
    const isEditing = Boolean(defaultValues);
    try {
      if (isEditing) {
        const changedFields = Object.keys(dirtyFields);
        const dataToUpdate = { id: defaultValues.id };
        changedFields.forEach(item => {
          dataToUpdate[item] = data[item];
        });

        if (changedFields?.includes('locations')) {
          dataToUpdate.prevLocations = defaultLocations;
        }

        if (changedFields?.includes('teams')) {
          dataToUpdate.prevTeams = defaultTeams
            ?.filter(item => item?.streams?.includes(defaultValues.id))
            .map(item => item.id);
        }

        await withLoading(() => handleEditStream(dataToUpdate));
      } else {
        await withLoading(() => shouldCreateStream(data));
      }
    } catch (error) {
      console.log({ error });
      onError(t('streamCreateError'));
    }
  };

  const onClickChooseRole = role => {
    const isRoleAlreadySelected = selectedRole.some(
      item => item.id === role.id
    );

    var roles = [];
    if (isRoleAlreadySelected) {
      roles = selectedRole.filter(item => item.id !== role.id);
    } else {
      roles = [...selectedRole, role];
    }
    setValue('roles', roles, { shouldValidate: true, shouldDirty: true });
    setSelectedRole(roles);
  };

  const onClickChooseLocation = location => {
    const isAllLocationsOption = location.id === allLocationsOption.id;
    const isLocationAlreadySelected = selectedLocation.some(
      item => item.id === location.id
    );
    const areAllLocationsSelected = selectedLocation.some(
      item => item.id === allLocationsOption.id
    );

    if (isAllLocationsOption) {
      handleAllLocationsOption(isLocationAlreadySelected);
    } else {
      handleLocationSelection(
        isLocationAlreadySelected,
        areAllLocationsSelected,
        location
      );
    }
  };

  const handleAllLocationsOption = isLocationAlreadySelected => {
    if (isLocationAlreadySelected) {
      resetLocationSelection();
    } else {
      selectAllLocations();
    }
  };

  const resetLocationSelection = () => {
    setValue('locations', [], { shouldValidate: true, shouldDirty: true });
    setSelectedLocation([]);
  };

  const selectAllLocations = () => {
    const allLocations = [allLocationsOption, ...locations];
    setValue('locations', allLocations, {
      shouldValidate: true,
      shouldDirty: true
    });
    setSelectedLocation(allLocations);
  };

  const handleLocationSelection = (
    isLocationAlreadySelected,
    areAllLocationsSelected,
    location
  ) => {
    let newLocations;

    if (isLocationAlreadySelected) {
      newLocations = removeSelectedLocation(location, areAllLocationsSelected);
    } else {
      newLocations = addSelectedLocation(location);
    }

    setValue('locations', newLocations, {
      shouldValidate: true,
      shouldDirty: true
    });
    setSelectedLocation(newLocations);
  };

  const removeSelectedLocation = (location, areAllLocationsSelected) => {
    let newLocations = selectedLocation.filter(item => item.id !== location.id);

    if (areAllLocationsSelected) {
      return newLocations.filter(item => item.id !== allLocationsOption.id);
    }
    return newLocations;
  };

  const addSelectedLocation = location => {
    const newLocations = [...selectedLocation, location];

    return newLocations;
  };

  const onClickChooseTeam = team => {
    const isAllTeamsOption = team.id === allTeamsOption.id;
    const isTeamAlreadySelected = selectedTeam.some(
      item => item.id === team.id
    );
    const areAllTeamsSelected = selectedTeam.some(
      item => item.id === allTeamsOption.id
    );

    if (isAllTeamsOption) {
      handleAllTeamsOption(isTeamAlreadySelected);
    } else {
      handleTeamSelection(isTeamAlreadySelected, areAllTeamsSelected, team);
    }
  };

  const handleAllTeamsOption = isTeamAlreadySelected => {
    if (isTeamAlreadySelected) {
      resetTeamSelection();
    } else {
      selectAllTeams();
    }
  };

  const resetTeamSelection = () => {
    setValue('teams', [], { shouldValidate: true, shouldDirty: true });
    setSelectedTeam([]);
  };

  const selectAllTeams = () => {
    const allTeams = [allTeamsOption, ...teams];
    setValue('teams', allTeams, { shouldValidate: true, shouldDirty: true });
    setSelectedTeam(allTeams);
  };

  const handleTeamSelection = (
    isTeamAlreadySelected,
    areAllTeamsSelected,
    team
  ) => {
    let newTeams;

    if (isTeamAlreadySelected) {
      newTeams = removeSelectedTeam(team, areAllTeamsSelected);
    } else {
      newTeams = addSelectedTeam(team);
    }

    setValue('teams', newTeams, { shouldValidate: true, shouldDirty: true });
    setSelectedTeam(newTeams);
  };

  const removeSelectedTeam = (team, areAllTeamsSelected) => {
    let newTeams = selectedTeam.filter(item => item.id !== team.id);

    if (areAllTeamsSelected) {
      return newTeams.filter(item => item.id !== allTeamsOption.id);
    }
    return newTeams;
  };

  const addSelectedTeam = team => {
    const newTeams = [...selectedTeam, team];

    return newTeams;
  };

  const checkIfFieldIsValid = field => {
    if (defaultValues) {
      return !getFieldState(field).error;
    }
    return !getFieldState(field).isDirty ? false : !getFieldState(field).error;
  };

  const data = [
    {
      id: 'name',
      placeholder: t('streamPlaceholder'),
      title: t('name'),
      isValid: checkIfFieldIsValid('name'),
      control,
      name: 'name',
      defaultValue: defaultValues?.name
    },
    {
      id: 'roles',
      name: 'roles',
      control,
      register,
      title: t('post'),
      subtitle: t('selectRoleHint'),
      placeholder: t('selectRole'),
      isValid: !!selectedRole.length,
      onOptionClick: onClickChooseRole,
      options: [
        {
          values: rolesData
        }
      ],
      selectedOption: selectedRole
    },
    {
      id: 'locations',
      name: 'locations',
      title: t('location'),
      subtitle: t('selectLocationHint'),
      control,
      register,
      placeholder: t('selectLocation'),
      isValid: selectedLocation.length > 0,
      onOptionClick: onClickChooseLocation,
      options: [
        {
          values: [allLocationsOption]
        },
        {
          values: locations
        }
      ],
      selectedOption: selectedLocation
    },
    {
      id: 'teams',
      name: 'teams',
      title: t('team'),
      subtitle: t('selectTeamsHint'),
      control,
      register,
      placeholder: t('selectTeam'),
      isValid: selectedTeam.length > 0,
      onOptionClick: onClickChooseTeam,
      options: [
        {
          values: [allTeamsOption]
        },
        {
          values: teams
        }
      ],
      selectedOption: selectedTeam
    }
  ];

  const handleCheckboxChange = () => {
    setValue('isOnboardingStream', !isOnboardingStream, {
      shouldValidate: true,
      shouldDirty: true
    });
    setIsOnboardingStream(!isOnboardingStream);
  };

  const toggleActionModal = () => {
    setActionModalOpen(prevState => !prevState);
  };

  const handleDeleteUser = async () => {
    try {
      await withLoading(() => deleteUser({ companyId, ...defaultValues }));
      handleClose();
    } catch (error) {}
  };

  return (
    <Modal
      isOpen={isOpen}
      title={defaultValues ? t('editStream') : t('addStream')}
      handleClose={handleClose}
      isFormValid={
        isValid &&
        isDirty &&
        (selectedTeam?.length > 0 || selectedLocation?.length > 0)
      }
      isLoading={isLoading}
    >
      <form id="hook-form" noValidate onSubmit={handleSubmit(onSubmit)}>
        {defaultValues?.url && (
          <Box ml={3} mt={3} mb={7}>
            <Avatar
              src={defaultValues.url}
              alt={'user'}
              style={{ width: 124, height: 124 }}
            />
          </Box>
        )}
        {data.map(({ id, ...rest }) => (
          <TextFieldSection key={id} {...rest} />
        ))}
        {defaultValues ? (
          <Box ml={3}></Box>
        ) : (
          <div className={classes.checkBoxSection}>
            <CheckBox
              {...register('isOnboardingStream')}
              whiteBackground
              checked={isOnboardingStream}
              onChange={handleCheckboxChange}
            />
            <Typography variant="subtitle2">{t('onboardingStream')}</Typography>
          </div>
        )}
      </form>
      <ActionModal
        open={isActionModalOpen}
        title={t('deleteUserConfirmation')}
        primaryButtonTitle={t('deleteUser')}
        onSecondaryButtonPress={toggleActionModal}
        onPrimaryButtonPress={handleDeleteUser}
      />
    </Modal>
  );
};

//TODO: fix any type
StreamModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  introText: PropTypes.string,
  handleClose: PropTypes.func,
  onClickSave: PropTypes.func,
  addLearerSpinner: PropTypes.bool,
  defaultValues: any,
  locations: any,
  teams: any
};

export default StreamModal;

import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography,
  useTheme
} from '@material-ui/core';
import React from 'react';
import PropTypes, { func, oneOf } from 'prop-types';
import Users from 'src/icons/Users';
import User from 'src/icons/User';
import { useTranslation } from 'react-i18next';
import ToolTip from 'src/views/content/components/streams/ToolTip';
import ChevronRight from 'src/icons/ChevronRight';

const useStyles = makeStyles(theme => ({
  paperCard: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    background: theme.palette.common.white,
    borderRadius: 12,
    border: `1px solid transparent`,
    '&:hover': {
      border: `1px solid #6E26FC`
    }
  },
  titleText: {
    fontSize: 12,
    fontWeight: '600',
    color: theme.palette.text.hint,
    // marginTop: 8,
    marginBottom: 4
  },
  locationNameText: {
    color: theme.palette.grey[800],
    fontWeight: '700',
    fontSize: 32
  },
  locationInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    // marginBottom: theme.spacing(0.5),
    alignItems: 'center'
  },
  infoSection: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  locationInfoPaper: {
    background: theme.palette.background.lightBlue,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: 12,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textIconSection: {
    display: 'flex',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  }
}));

const ListCard = ({
  id,
  title,
  numberOfTeams,
  numberOfLearners,
  onClick,
  subHeader,
  onDeleteClick,
  onEditClick,
  type
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Paper elevation={0} className={classes.paperCard}>
      <Box flexDirection="row" display="flex" justifyContent="space-between">
        <Box>
          <Typography className={classes.titleText} variant="h5">
            {subHeader}
          </Typography>
          <div className={classes.locationInfo}>
            <Typography className={classes.locationNameText} variant="h3">
              {title}
            </Typography>
          </div>
        </Box>
        <Box position="relative" right={-8} top={-8} display="flex">
          <Box>
            <IconButton onClick={onClick}>
              <ChevronRight />
            </IconButton>
          </Box>
          <ToolTip onClickEdit={onEditClick} onClickDelete={onDeleteClick} />
        </Box>
      </Box>
      <div className={classes.infoSection}>
        <Grid container spacing={1}>
          {type === 'location' && (
            <Grid item xs>
              <Paper elevation={0} className={classes.locationInfoPaper}>
                <div className={classes.textIconSection}>
                  <Users style={{ color: theme.palette.primary.main }} />
                  <Typography className={classes.titleText} variant="h5">
                    {t('teams')}
                  </Typography>
                </div>
                <Typography className={classes.locationNameText} variant="h3">
                  {numberOfTeams ?? 0}
                </Typography>
              </Paper>
            </Grid>
          )}
          <Grid item xs>
            <Paper elevation={0} className={classes.locationInfoPaper}>
              <div className={classes.textIconSection}>
                <User style={{ color: theme.palette.primary.main }} />
                <Typography className={classes.titleText} variant="h5">
                  {t('users')}
                </Typography>
              </div>
              <Typography className={classes.locationNameText} variant="h3">
                {numberOfLearners ?? 0}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

ListCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  subHeader: PropTypes.string,
  numberOfTeams: PropTypes.number,
  numberOfLearners: PropTypes.number,
  onDeleteClick: func,
  onEditClick: func,
  onClick: func,
  type: oneOf(['stream', 'location', 'team'])
};

ListCard.defaultProps = {
  onClick: () => {}
};

export default ListCard;

import { firestore, getCompanyRef } from 'src/api/firebase';

export const deleteChecklist = async ({ companyId, ...checklist }) => {
  const batch = firestore.batch();
  const root = getCompanyRef(companyId);

  if (checklist?.id) {
    const checklistRef = root.collection('checklists').doc(checklist.id);
    const tasksSnapshot = await checklistRef.collection('tasks').get();
    
    tasksSnapshot.forEach(doc => {
      batch.delete(doc.ref);
    });

    batch.delete(checklistRef);
  }
  
  return batch.commit();
};

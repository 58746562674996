import firebase from 'firebase/app';
import { convertFromRaw, convertToRaw, ContentState } from 'draft-js';

export const getTimestamp = () => firebase.firestore.Timestamp.now();

export const firestoreArrayUnion = firebase.firestore.FieldValue.arrayUnion;
export const firestoreArrayRemove = firebase.firestore.FieldValue.arrayRemove;

export const truncateDraft = (draft, charCount = 70) => {
  const contentState = convertFromRaw(JSON.parse(draft));
  const blocks = contentState.getBlocksAsArray();

  let currentLength = 0;
  let isTruncated = false;
  const truncatedBlocks = [];

  blocks.forEach(block => {
    if (isTruncated) {
      return;
    }

    const blockLength = block.getLength();
    if (currentLength + blockLength > charCount) {
      isTruncated = true;
      const truncatedText = block.getText().slice(0, charCount - currentLength) + '...';

      const characterList = block.getCharacterList().slice(0, charCount - currentLength);
      const newBlock = block.merge({
        text: truncatedText,
        characterList: characterList
      });

      truncatedBlocks.push(newBlock);
    } else {
      truncatedBlocks.push(block);
    }

    currentLength += blockLength + 1;
  });

  if (isTruncated) {
    const newContentState = ContentState.createFromBlockArray(truncatedBlocks, contentState.getEntityMap());
    const truncatedContent = JSON.stringify(convertToRaw(newContentState));
    return truncatedContent;
  }

  return draft;
};
import _ from 'lodash';
import { useEffect, useState, useContext } from 'react';
import { AuthContext } from 'src/providers/AuthProvider';
import { getCompanyRef } from 'src/api/firebase';

const useCompletionUsers = () => {
  const { companyId } = useContext(AuthContext);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);

  const root = getCompanyRef(companyId);

  const onError = err => {
    setError(err);
    setLoading(false);
  };

  const observeAllUsers = observer => {
    return root
      .collection('users')
      .orderBy('name', 'asc')
      .onSnapshot(observer);
  };
  useEffect(() => {
    const unsubscribe = observeAllUsers({
      next: async snap => {
        let items = [];
        for (const doc of snap.docs) {
          const data = doc.data();
          const completion =
            data?.completedPosts?.length && data.insights?.totalPosts
              ? Math.floor(
                  (data.completedPosts.length / data.insights.totalPosts) * 100
                )
              : 0;
          items.push({
            id: doc.id,
            completion: `${completion > 100 ? 100 : completion}%`,
            completionValue: completion > 100 ? 100 : completion,
            ...data
          });
        }
        setUsers(_.orderBy(items, item => item.completionValue, 'desc'));
        setLoading(false);
      },
      error: onError
    });
    return () => unsubscribe();
  }, []);

  return [users, isLoading, error];
};

export default useCompletionUsers;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TextFieldSection from '../../TextFieldSection';
import { useForm } from 'react-hook-form';
import { addLocation } from 'src/api/locations/addLocation';
import { useCompanyId } from 'src/api/company/useCompanyId';
import useToast from 'src/hooks/useToast';
import { useLoading } from 'src/hooks';
import { editLocation } from 'src/api/locations';
import CheckBox from 'src/components/CheckBox';

const useStyles = makeStyles(theme => ({
  contentContainer: {
    // marginTop: '-1rem'
  },
  textFieldSection: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  textFieldTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  textFieldPlacement: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: '0.4rem'
    }
  },
  checkBoxSection: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3.5)
  }
}));

const AddLocationModal = ({
  isOpen,
  title,
  introText,
  defaultValues,
  handleClose
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { companyId } = useCompanyId();
  const { onError, onSuccess } = useToast();
  const { isLoading, withLoading } = useLoading();
  const [isChatEnabled, setChatEnabled] = useState(
    defaultValues?.isChatEnabled ?? true
  );

  const {
    handleSubmit,
    formState: { isValid, errors },
    register,
    control,
    setValue
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: defaultValues?.name,
      isChatEnabled: defaultValues?.isChatEnabled ?? true
    }
  });

  const onSubmit = async ({ name, isChatEnabled }) => {
    withLoading(async () => {
      try {
        if (defaultValues?.name) {
          await editLocation({
            companyId,
            locationId: defaultValues.id,
            dataToUpdate: { name, isChatEnabled }
          });
          onSuccess(t('locationUpdated'));
          handleClose();
        } else {
          await addLocation({ companyId, name, isChatEnabled });
          onSuccess(t('locationCreated'));
          handleClose();
        }
      } catch (error) {
        onError("Couldn't add location");
      }
    });
  };

  const handleChatEnabledChange = () => {
    setValue('isChatEnabled', !isChatEnabled, {
      shouldValidate: true,
      shouldDirty: true
    });
    setChatEnabled(!isChatEnabled);
  };

  return (
    <form
      id="hook-form"
      className={classes.contentContainer}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Modal
        isOpen={isOpen}
        title={title}
        introText={introText}
        handleClose={handleClose}
        isFormValid={isValid}
        isLoading={isLoading}
      >
        <TextFieldSection
          name="name"
          control={control}
          title={t('name')}
          placeholder={t('nameThisLocation')}
          defaultValue={defaultValues?.name}
          isValid={isValid}
          autoFocus
        />
        <div className={classes.checkBoxSection}>
          <CheckBox
            {...register('isChatEnabled')}
            whiteBackground
            checked={isChatEnabled}
            onChange={handleChatEnabledChange}
          />
          <Typography variant="subtitle2">{t('Enable chat')}</Typography>
        </div>
      </Modal>
    </form>
  );
};

AddLocationModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  introText: PropTypes.string,
  defaultValues: PropTypes.object,
  handleClose: PropTypes.func
};

export default AddLocationModal;

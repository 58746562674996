import React from 'react';
import { string } from 'prop-types';
import { Box, Divider, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import CounterText from './CounterText/CounterText';

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(15, 0, 2, 0)
  },
  text: {
    color: '#323138',
    fontFamily: 'Inter'
  }
}));

const ViewHeader = ({ title, subtitle, containerStyle }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.container, containerStyle)}>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <Typography variant="h1" className={classes.text}>
          {title}
        </Typography>
      </Box>
      <Box mt={2} />
      <Divider />
      {subtitle && (
        <Box mt={2}>
          <CounterText>{subtitle}</CounterText>
        </Box>
      )}
    </div>
  );
};

ViewHeader.propTypes = {
  title: string,
  subtitle: string,
  containerStyle: string
};

export default ViewHeader;

import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  InputAdornment,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import Button from 'src/components/Button';
import { ValidateSMS } from './ValidateSMS';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import firebase from 'firebase/app';
import { auth } from 'src/api/firebase';
import Backdrop from './BackDrop';
import { useController, useForm } from 'react-hook-form';
import { getErrorMessage } from '../utils';
import SqillzLogo from 'src/icons/SqillzLogo';
import Close from 'src/icons/Close';
import PriorityHigh from 'src/icons/PriorityHigh';
import { AuthContext } from 'src/providers/AuthProvider';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  rightContainer: {
    width: '50%',
    height: '100%'
  },
  logotype: {
    width: '52px',
    margin: '40px'
  },
  image: {
    width: '50%',
    height: '100%',
    float: 'right'
  },
  welcome: {
    color: '#0B0B0C',
    fontFamily: 'Inter',
    fontSize: '32px',
    fontWeight: 'bold',
    paddingBottom: '16px'
  },
  number: {
    color: '#6E26FC',
    fontSize: '24px',
    fontFamily: 'Inter',
    fontWeight: 'bold',
    paddingBottom: '16px'
  },
  number2: {
    color: '#9A98A3',
    fontSize: '12px',
    fontFamily: 'Inter',
    paddingTop: '16px'
  },
  smsLogo: {
    width: 400
  },
  inputRoot: {
    display: 'flex'
  },
  inputText: {
    fontSize: 16,
    fontWeight: 500
  },
  textField: {
    paddingLeft: 0,
    background: 'inherit',
    borderWidth: 0,
    '& input::placeholder': {
      color: 'rgba(170, 168, 187, 1)',
      opacity: 1,
      fontSize: 16,
      fontWeight: 500,
      fontFamily: 'Inter'
    }
  }
}));

const COUNTRY_CODE = '+46';
export const SMSAuthView = () => {
  const { t } = useTranslation();
  const { error, clearError } = useContext(AuthContext);
  const [number, setNumber] = React.useState(COUNTRY_CODE);
  const classes = useStyles();
  const navigate = useNavigate();
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [authState, setAuthState] = useState(null);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [err, setErr] = useState(null);
  const [isConfirmingCode, setIsConfirmingCode] = useState(false);

  useEffect(() => {
    if (error) {
      showErrorToast(t('err.unauthorized'), () => clearError());
    }
  }, [error]);

  const {
    handleSubmit,
    formState: { isValid },
    control
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange'
  });

  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { isTouched, isDirty },
    formState
  } = useController({
    name: 'phone',
    control,
    rules: {
      required: true,
      pattern: /^(7[02369])\s*(\d{4})\s*(\d{3})$/
    }
  });

  const showErrorToast = (message, onClose) =>
    toast(message ?? t('err.invalidCode'), {
      type: 'error',
      theme: 'dark',
      toastId: 'sms-auth',
      icon: () => <PriorityHigh color="white" style={{ color: 'white' }} />,
      closeButton: () => <Close color="white" style={{ fontSize: 16 }} />,
      onClose: onClose ?? (() => setErr(null))
    });

  useEffect(() => {
    const verifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible'
      }
    );
    setRecaptchaVerifier(verifier);
    return () => verifier.clear();
  }, []);

  const sendSMS = async phoneNumber => {
    if (!recaptchaVerifier) return;

    try {
      setAuthState(t('sendingSms'));

      auth
        .signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
        .then(confirmationResult => {
          setConfirmationResult(confirmationResult);
        })
        .catch(err => {
          setErr(getErrorMessage(err));
        });
    } catch (error) {
      navigate(
        `/login/not-found/${phoneNumber.substring(COUNTRY_CODE.length)}`
      );
    }
  };

  const handleVerificationCode = async code => {
    if (!confirmationResult) return;
    try {
      setIsConfirmingCode(true);
      const result = await confirmationResult.confirm(code);
    } catch (error) {
      setIsConfirmingCode(false);
      const result = await confirmationResult.confirm(code);
      showErrorToast();
      setErr('invalidCode');
    }
  };

  const handleResendCode = () => {
    if (recaptchaVerifier) {
      sendSMS();
    }
  };

  const onSubmit = ({ phone }) => {
    const cleanPhoneNr = phone.removeWhitespace();
    const nrWithCountry = `${COUNTRY_CODE}${cleanPhoneNr}`;
    setNumber(nrWithCountry);
    sendSMS(nrWithCountry);
  };

  const displayForm = () => {
    return (
      <>
        <Box position="absolute" left={80} top={80}>
          <SqillzLogo />
        </Box>
        <form id="hook-form" noValidate onSubmit={handleSubmit(onSubmit)}>
          <Box>
            {!confirmationResult && authState && !err && (
              <Backdrop message={authState} />
            )}
            <Box mr={4}>
              <Typography variant="h2">{t('welcome2skillz')}</Typography>
            </Box>
            <Box py={7}>
              <Typography
                variant="h3"
                style={{ color: 'rgba(170, 168, 187, 1)' }}
              >
                {t('enterNumber')}
              </Typography>
              <Box mt={1} mb={4.5}>
                <TextField
                  inputRef={ref}
                  onBlur={onBlur}
                  onChange={onChange}
                  autoFocus
                  fullWidth
                  focused
                  className={classes.textField}
                  InputProps={{
                    classes: {
                      input: classes.inputText,
                      root: classes.inputRoot
                    },
                    startAdornment: (
                      <InputAdornment>
                        <Box mr={1}>
                          <Typography className={classes.inputText}>
                            +46
                          </Typography>
                        </Box>
                      </InputAdornment>
                    ),
                    disableUnderline: true
                  }}
                />
              </Box>
              <Typography variant="body2">{t('weSendSMS')}</Typography>
            </Box>
            <Box>
              <Button fullWidth disabled={!isValid} type="submit">
                {t('continue')}
              </Button>
            </Box>
            <Box
              mt={2}
              visibility={err ? 'visible' : 'hidden'}
              form="hook-form"
            >
              <Typography>{err}</Typography>
            </Box>
          </Box>
        </form>
      </>
    );
  };

  return (
    <div className={classes.wrapper}>
      {!confirmationResult && displayForm()}
      {confirmationResult && (
        <ValidateSMS
          number={number}
          onCodeFulfill={handleVerificationCode}
          onCodeResend={handleResendCode}
          isLoading={isConfirmingCode}
        />
      )}
      <div id="recaptcha-container" />
    </div>
  );
};

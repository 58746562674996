import { CircularProgress, makeStyles } from '@material-ui/core';
import { arrayOf, bool } from 'prop-types';
import { func, number, shape, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CounterText, ListCard, NoTeamsAdded, TabPanel } from 'src/components';
import { TEAMS_TAB } from './OrganizationView';

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(2)
  }
}));

const Teams = ({
  currentIndex,
  onDeleteClick,
  onClick,
  onEditClick,
  teams,
  isLoading
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TabPanel value={currentIndex} index={TEAMS_TAB}>
      <div>
        <CounterText>
          {t(teams.length === 1 ? 'teamsCount_one' : 'teamsCount_other', {
            count: teams.length
          })}
        </CounterText>
        {isLoading ? (
          <div className={classes.content}>
            <CircularProgress />
          </div>
        ) : teams.length < 1 ? (
          <NoTeamsAdded />
        ) : (
          teams.map(item => (
            <ListCard
              key={item.id}
              id={item.id}
              title={item.name}
              numberOfLearners={item.usersCount}
              subHeader={t('team')}
              onClick={() => onClick(item)}
              onDeleteClick={() => onDeleteClick(item)}
              onEditClick={() => onEditClick(item)}
              type="team"
            />
          ))
        )}
      </div>
    </TabPanel>
  );
};

Teams.propTypes = {
  currentIndex: number.isRequired,
  onDeleteClick: func.isRequired,
  onClick: func.isRequired,
  onEditClick: func.isRequired,
  teams: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
      usersCount: number.isRequired,
      locations: shape({
        id: string.isRequired,
        name: string.isRequired
      })
    })
  ).isRequired,
  isLoading: bool.isRequired
};

export default Teams;

import React, { forwardRef, useState } from 'react';
import PropTypes, { any, arrayOf, shape, string } from 'prop-types';
import { makeStyles, Grid, Typography, Box } from '@material-ui/core';
import CircleIndicator from './CircleIndicator';
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CheckBox from 'src/components/CheckBox';
import Radio from 'src/components/Radio';
import TextInput from 'src/components/TextInput';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  textFieldSection: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  textFieldTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  textFieldPlacement: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2)
  },
  dropdownContainer: {
    background: theme.palette.common.white,
    borderRadius: 8,
    border: `1px solid ${theme.palette.primary.surface}`,
    padding: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'space-between'
  },
  listContainer: {
    padding: 0,
    marginBottom: theme.spacing(1),
    borderRadius: 8,
    border: `1px solid ${theme.palette.primary.surface}`
  },
  listItem: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    height: 56
  },
  section: {
    '&:hover': {
      visibility: 'visible',
      '&:hover $listItemSecondaryAction': {
        visibility: 'visible'
      }
    }
  },
  nested: {
    backgroundColor: theme.palette.common.white,
    borderLeft: `1px solid ${theme.palette.primary.surface}`,
    borderRight: `1px solid ${theme.palette.primary.surface}`,
    height: 50,
    '&:hover': {
      visibility: 'visible',
      '& $listItemSecondaryAction': {
        visibility: 'visible'
      }
    }
  },
  firstItem: {
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.primary.surface}`
  },
  lastItem: {
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.primary.surface}`
  },
  capitalLetter: {
    textTransform: 'capitalize'
  },
  disabled: {
    color: '#C9C9CF'
  },
  invisible: {
    visibility: 'hidden'
  }
}));

const TextFieldSection = forwardRef(
  (
    {
      title,
      subtitle,
      placeholder,
      deleteButton,
      onClickRemoveAlternative,
      sectionStyle,
      textFieldStyle,
      isValid,
      simpleCheck,
      id,
      options,
      useRadioOptions,
      onClose,
      onClick,
      onOptionClick,
      selectedOption,
      disabled,
      register,
      name,
      listItemTextClasses,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    // eslint-disable-next-line
    const renderSectionTitle = ({ sectionTitle, index }) => {
      return (
        <>
          <ListItem
            key={id}
            classes={{ container: classes.section }}
            className={clsx(classes.nested, index === 0 && classes.firstItem)}
          >
            <Typography variant="subtitle2">{sectionTitle}</Typography>
          </ListItem>
        </>
      );
    };

    // eslint-disable-next-line
    const renderOptions = ({
      values,
      withSectionTitle,
      lastOption,
      disabled
    }) => {
      return (
        <>
          {values?.map(({ id, name, ...rest }, index) => (
            <ListItem
              key={id}
              classes={{ container: classes.section }}
              className={clsx(
                classes.nested,
                index === 0 && !withSectionTitle && classes.firstItem,
                lastOption && index === values.length - 1 && classes.lastItem
              )}
            >
              <CheckBox
                checked={selectedOption?.map(item => item.id).includes(id)}
                disabled={disabled}
                onClick={() => onOptionClick({ id, name, ...rest })}
              />
              <Typography
                className={
                  disabled
                    ? [classes.capitalLetter, classes.disabled]
                    : classes.capitalLetter
                }
                variant="subtitle2"
              >
                {name}
              </Typography>
            </ListItem>
          ))}
        </>
      );
    };

    // eslint-disable-next-line
    const renderRadioOptions = ({
      values,
      withSectionTitle,
      lastOption,
      disabled
    }) => {
      return (
        <>
          {values?.map(({ id, name, ...rest }, index) => (
            <ListItem
              key={id}
              classes={{ container: classes.section }}
              className={clsx(
                classes.nested,
                index === 0 && !withSectionTitle && classes.firstItem,
                lastOption && index === values.length - 1 && classes.lastItem
              )}
            >
              <Radio
                checked={selectedOption?.map(item => item.id).includes(id)}
                disabled={disabled}
                onClick={() => onOptionClick({ id, name, ...rest })}
              />
              <Typography
                className={disabled ? classes.disabled : null}
                variant="subtitle2"
              >
                {name}
              </Typography>
            </ListItem>
          ))}
        </>
      );
    };

    const getSelectedOptionText = () => {
      const areAllTeamsSelected = selectedOption?.some(
        option => option.id === 'all-teams'
      );

      const areAllLocationsSelected = selectedOption?.some(
        option => option.id === 'all-locations'
      );

      if (areAllTeamsSelected) {
        return t('allTeams');
      }

      if (areAllLocationsSelected) {
        return t('allLocations');
      }

      if (selectedOption?.length === 0) {
        return placeholder;
      } else if (selectedOption?.length > 2) {
        return `${selectedOption[0].name}, ${
          selectedOption[1].name
        } (+${selectedOption.length - 2})`;
      } else {
        return selectedOption?.map(item => item.name).join(', ');
      }
    };

    return (
      <div className={clsx(classes.textFieldSection, sectionStyle)}>
        {!!title && (
          <div
            className={clsx(
              classes.textFieldTitle,
              title === 'invisible' ? classes.invisible : null
            )}
          >
            <CircleIndicator isValid={isValid} />
            <Typography variant="subtitle2">{title}</Typography>
          </div>
        )}
        {!!subtitle && (
          <div
            className={clsx(
              classes.textFieldTitle,
              subtitle === 'invisible' ? classes.invisible : null
            )}
          >
            <Box visibility="hidden">
              <CircleIndicator isValid={isValid} />
            </Box>
            <Box mt={1}>
              <Typography variant="subtitle2" color="textSecondary">
                {subtitle}
              </Typography>
            </Box>
          </div>
        )}
        <div className={classes.textFieldPlacement}>
          {options ? (
            <>
              <ListItem className={classes.listContainer}>
                <ListItem
                  className={classes.listItem}
                  disabled={disabled}
                  button
                  onClick={() => setOpen(prevState => !prevState)}
                  {...register(name, { required: true, ...rest.rules })}
                >
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <ListItemText
                        primary={
                          <Typography
                            className={clsx(
                              selectedOption.length > 0 &&
                                classes.capitalLetter,
                              listItemTextClasses
                            )}
                            variant="subtitle2"
                            color={
                              selectedOption.length > 0
                                ? 'textPrimary'
                                : 'textSecondary'
                            }
                          >
                            {getSelectedOptionText()}
                          </Typography>
                        }
                      />
                    </Grid>
                  </Grid>
                  {open ? (
                    <ExpandLess color="secondary" />
                  ) : (
                    <ExpandMore color="secondary" />
                  )}
                </ListItem>
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                {options?.map((option, index) => (
                  <React.Fragment key={`${option.sectionTitle}-${index}`}>
                    {option?.sectionTitle &&
                      renderSectionTitle({
                        sectionTitle: option.sectionTitle,
                        index
                      })}
                    {option?.values && useRadioOptions
                      ? renderRadioOptions({
                          values: option?.values,
                          disabled: !!disabled,
                          withSectionTitle: option?.sectionTitle,
                          lastOption: index === options.length - 1
                        })
                      : renderOptions({
                          values: option?.values,
                          disabled: !!disabled,
                          withSectionTitle: option?.sectionTitle,
                          lastOption: index === options.length - 1
                        })}
                  </React.Fragment>
                ))}
              </Collapse>
            </>
          ) : (
            <TextInput
              placeholder={placeholder}
              deleteButton={deleteButton}
              onClickRemoveAlternative={onClickRemoveAlternative}
              textFieldStyle={textFieldStyle}
              name={name}
              {...rest}
            />
          )}
        </div>
      </div>
    );
  }
);

TextFieldSection.propTypes = {
  values: arrayOf(
    shape({
      id: string,
      name: string
    })
  ),
  title: PropTypes.string,
  valueLength: PropTypes.number,
  subtitle: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  sectionStyle: PropTypes.string,
  textFieldStyle: PropTypes.string,
  listItemTextClasses: PropTypes.string,
  deleteButton: PropTypes.bool,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
  onClickRemoveAlternative: PropTypes.func,
  onOptionClick: PropTypes.func,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  register: any,
  isValid: PropTypes.bool,
  simpleCheck: PropTypes.bool,
  id: PropTypes.string,
  options: arrayOf(
    shape({
      id: string,
      name: string,
      sectionTitle: string,
      values: arrayOf(
        shape({
          id: string,
          name: string
        })
      )
    })
  ),
  useRadioOptions: PropTypes.bool,
  selectedOption: arrayOf(
    shape({
      id: string,
      title: string
    })
  )
};

export default TextFieldSection;

import React from 'react';
import { SvgIcon } from '@material-ui/core';

const User = props => {
  return (
    <SvgIcon {...props}>
      <svg width="20" height="20" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.5 8.5C10.71 8.5 12.5 6.71 12.5 4.5C12.5 2.29 10.71 0.5 8.5 0.5C6.29 0.5 4.5 2.29 4.5 4.5C4.5 6.71 6.29 8.5 8.5 8.5ZM8.5 10.5C5.83 10.5 0.5 11.84 0.5 14.5V16.5H16.5V14.5C16.5 11.84 11.17 10.5 8.5 10.5Z" fill="#6E26FC" />
      </svg>
    </SvgIcon>
  );
};

export default User;

import { getCompanyRef } from 'src/api/firebase';

export const getUserData = async ({ user, companyId }) => {
  const snapshot = await getCompanyRef(companyId)
    .collection('users')
    .doc(user.uid)
    .get();

  let userData = null;
  if (snapshot.empty) {
    return;
  }

  userData = snapshot.data();

  return { ...userData, id: snapshot.id };
};

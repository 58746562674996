import { Box } from '@material-ui/core';
import { any, node } from 'prop-types';
import React from 'react';

const TabPanel = ({ children, value, index }) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      <Box>{value === index && children}</Box>
    </div>
  );
};

TabPanel.propTypes = {
  children: node,
  index: any.isRequired,
  value: any.isRequired
};

export default TabPanel;

import { useEffect, useState } from 'react';

export const useCountdown = (start, onFinish) => {
  const [countDown, setCountDown] = useState(start);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
      } else {
        setCountDown(start);
        // onFinish();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [countDown]);

  return { countDown };
};

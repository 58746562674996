import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { getCompanyRef } from 'src/api/firebase';
import { useCompanyId } from '../company/useCompanyId';

const useInsightsObserver = () => {
  const { companyId } = useCompanyId();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [insights, setInsights] = useState(null);
  const root = getCompanyRef(companyId);

  const observeInsights = observer => {
    return root.collection('insights').onSnapshot(observer);
  };

  const reduceData = arr =>
    arr.reduce(
      (acc, item) => {
        return {
          total: acc?.total + (item?.total || 0),
          completed: acc?.completed + (item?.completed || 0)
        };
      },
      { total: 0, completed: 0 }
    );
  const reduceCounter = arr => arr.reduce((acc, item) => acc + item, 0);

  useEffect(() => {
    try {
      const unsubscribe = observeInsights({
        next: async snap => {
          let items = [];

          const defaultValues = {
            activatedUsersCount: 0,
            bookmarksCount: 0,
            commentsCount: 0,
            completedPostsCount: 0,
            completedUsersCount: 0,
            documentsCount: 0,
            likesCount: 0,
            locationsCount: 0,
            onboardingStream: {
              completed: 0,
              total: 0
            },
            postsCount: 0,
            teamsCount: 0,
            usersCount: 0,
            viewsCount: 0
          };

          for (const doc of snap.docs) {
            const { timestamp, ...rest } = doc.data();
            const {
              likesCount,
              commentsCount,
              bookmarksCount,
              viewsCount,
              postsCount,
              completedPostsCount,
              completedUsersCount,
              ...values
            } = rest;

            const item = {
              ...defaultValues,
              id: doc.id,
              date: dayjs(doc.id).format('YYYY-MM-DD'),
              ...rest
            };

            item.engagement = {
              name: dayjs(doc.id).format('MMM'),
              value: completedPostsCount || 0
            };

            items.push(item);
          }
          const engagement = items.map(item => item.engagement);

          const activatedUsersCount = reduceCounter(
            items.map(item => item.activatedUsersCount)
          );

          const onboardingStream = reduceData(
            items.map(item => item.onboardingStream)
          );

          const postsCount = reduceCounter(items.map(item => item.postsCount));

          const completedPostsCount = reduceCounter(
            items.map(item => item.completedPostsCount)
          );

          const commentsCount = reduceCounter(
            items.map(item => item.commentsCount)
          );

          const likesCount = reduceCounter(items.map(item => item.likesCount));

          const documentsCount = reduceCounter(
            items.map(item => item.documentsCount)
          );

          const bookmarksCount = reduceCounter(
            items.map(item => item.bookmarksCount)
          );

          const teamsCount = reduceCounter(items.map(item => item.teamsCount));
          const usersCount = reduceCounter(items.map(item => item.usersCount));
          const locationsCount = reduceCounter(
            items.map(item => item.locationsCount)
          );

          const completedUsers = reduceCounter(
            items.map(item => item.completedUsersCount)
          );

          const insights = {
            engagement,
            completedPostsCount,
            onboardingStream: {
              total: onboardingStream.total,
              completed:
                onboardingStream.completed > onboardingStream.total
                  ? onboardingStream.total
                  : onboardingStream.completed
            },
            postsCount: postsCount,
            teamsCount: teamsCount,
            likesCount,
            documentsCount,
            bookmarksCount,
            commentsCount,
            usersCount,
            locationsCount,
            activatedUsers: {
              total: usersCount || 0,
              completed: activatedUsersCount
            },
            completion: {
              total: usersCount || 0,
              completed:
                completedUsers > usersCount ? usersCount : completedUsers
            }
          };

          setInsights(insights);
          setLoading(false);
        },
        error: setError
      });
      return unsubscribe;
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }, []);

  return [insights, isLoading, error];
};

export default useInsightsObserver;

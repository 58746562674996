import { useEffect, useState } from 'react';
import { getCompanyRef } from 'src/api/firebase';
import { useCompanyId } from '../company/useCompanyId';

const useLocations = () => {
  const { companyId } = useCompanyId();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [locations, setLocations] = useState([]);
  const root = getCompanyRef(companyId);

  const observeAllLocations = observer => {
    return root
      .collection('locations')
      .orderBy('name')
      .onSnapshot(observer);
  };

  useEffect(() => {
    try {
      const unsubscribe = observeAllLocations({
        next: async snap => {
          let items = [];
          for (const doc of snap.docs) {
            items.push({
              id: doc.id,
              ...doc.data()
            });
          }
          setLocations(items);
          setLoading(false);
        },
        error: setError
      });
      return unsubscribe;
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }, []);

  return [locations, isLoading, error];
};

export default useLocations;

import React from 'react';
import { SvgIcon } from '@material-ui/core';

const DisabledUncheckedIcon = props => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_45809_10)">
        <path
          d="M21 0H3C1.34315 0 0 1.34315 0 3V21C0 22.6569 1.34315 24 3 24H21C22.6569 24 24 22.6569 24 21V3C24 1.34315 22.6569 0 21 0Z"
          fill="#C9C9CF"
        />
        <path
          d="M20.5 1H3.5C2.11929 1 1 2.11929 1 3.5V20.5C1 21.8807 2.11929 23 3.5 23H20.5C21.8807 23 23 21.8807 23 20.5V3.5C23 2.11929 21.8807 1 20.5 1Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_45809_10">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default DisabledUncheckedIcon;

import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import IconLabelButton from 'src/components/Buttons/IconLabelButton';
import UserModal from 'src/components/Modals/UserModal/UserModal';
import Tabs from 'src/components/Tabs';
import ViewHeader from 'src/components/ViewHeader';
import { useCompanyId } from 'src/api/company/useCompanyId';
import ActionModal from 'src/components/ActionModal';
import useToast from 'src/hooks/useToast';
import EditTeamModal from 'src/components/Modals/AddTeamModal/AddTeamModal';
import useAllTeams from 'src/api/teams/useAllTeams';
import { useToggle, useLoading } from 'src/hooks';
import { deleteTeam } from 'src/api/teams/deleteTeam';
import AddTeamModal from 'src/components/Modals/AddTeamModal/AddTeamModal';
import useLocations from 'src/api/locations/useLocations';
import { LocationModal } from 'src/components';
import { deleteLocation } from 'src/api/locations';
import Teams from './Teams';
import Users from './Users';
import Locations from './Locations';
import storage from 'src/utils/storage';

export const LOCATIONS_TAB = 0;
export const TEAMS_TAB = 1;
export const USERS_TAB = 2;

const OrganizationView = () => {
  const { setItem, getItem } = storage();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [value, setValue] = useState(Number(getItem('tab')) || 0);
  const { companyId } = useCompanyId();
  const isLocationsTab = value === LOCATIONS_TAB;
  const isTeamsTab = value === TEAMS_TAB;
  const isUsersTab = value === USERS_TAB;

  const handleChange = (event, newValue) => {
    setItem('tab', newValue);
    setValue(newValue);
  };

  const [selectedUser, setSelectedUser] = useState(null);
  const { onSuccess, onError, onInfo } = useToast();

  const tabs = [t('locations'), t('teams'), t('users')];

  const onClickUser = user => {
    setSelectedUser(user);
  };

  const [
    isAddingLocation,
    openAddLocationModal,
    closeAddLocationModal
  ] = useToggle();

  const [
    isEdititingLocation,
    openEditLocationModal,
    closeEditLocationModal
  ] = useToggle();

  const [isAddingTeam, openAddTeam, closeAddTeam] = useToggle();
  const [addUserModalOp, setAddUserModalOpen] = useState(false);

  const [teams, isLoadingTeams] = useAllTeams();
  const [locations, isLoadingLocations] = useLocations();

  const [addLearnerSpinner] = useState(false);
  const [targetTeam, setTargetTeam] = useState(undefined);
  const [targetLocation, setTargetLocation] = useState(undefined);
  const { isLoading, withLoading } = useLoading();
  const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useToggle();

  const [isEditModalOpen, openEditModal, closeEditModal] = useToggle();

  const onEnterTeamClick = team => {
    navigate(`/app/organization/teams/${team.name.toLowerCase()}`, {
      state: { team, locations, teams }
    });
  };

  const onEnterLocationClick = location => {
    navigate(`/app/organization/locations/${location.name.toLowerCase()}`, {
      state: {
        location,
        teams,
        locations
      }
    });
  };

  const resetTargetTeam = () => {
    setTargetTeam(undefined);
  };

  const shouldDeleteTeam = async () => {
    try {
      withLoading(async () => {
        await deleteTeam({
          companyId,
          teamId: targetTeam.id
        });
        onSuccess(t('teamDeletedSuccessfully'));
        resetTargetTeam();
        closeDeleteModal();
      });
    } catch (error) {
      onError(t('failedToRemoveTeam'));
    }
  };

  const shouldDeleteLocation = async () => {
    try {
      withLoading(async () => {
        await deleteLocation({
          companyId,
          locationId: targetLocation.id
        });
        onSuccess(t('locationDeletedSuccessfully'));
        setTargetLocation();
        closeDeleteModal();
      });
    } catch (error) {
      onError(t('failedToRemoveLocation'));
    }
  };

  const handleDeleteTeam = team => {
    setTargetTeam(team);
    openDeleteModal();
  };

  const handleEditClick = team => {
    setTargetTeam(team);
    openEditModal();
  };

  const handleEditLocation = location => {
    setTargetLocation(location);
    openEditLocationModal();
  };

  const handleCloseEditModal = () => {
    setTargetTeam(undefined);
    closeEditModal();
  };

  const handleCloseAddModal = () => {
    setTargetTeam(undefined);
    closeAddTeam();
  };

  const handleCloseLocationModal = () => {
    if (targetLocation) {
      closeEditLocationModal();
    }
    setTargetLocation(undefined);
    closeAddLocationModal();
  };

  const handleDeleteLocation = location => {
    setTargetLocation(location);
    openDeleteModal();
  };

  return (
    <Box width="100%" height="100%">
      <ActionModal
        open={isDeleteModalOpen}
        title={
          targetLocation
            ? t('deleteLocationConfirmation')
            : t('deleteTeamConfirmation')
        }
        onPrimaryButtonPress={
          targetLocation ? shouldDeleteLocation : shouldDeleteTeam
        }
        onSecondaryButtonPress={closeDeleteModal}
        isLoading={isLoading}
      />
      <ViewHeader title={t('orgs')} />
      <Tabs tabs={tabs} handleChange={handleChange} value={value}>
        {isTeamsTab && (
          <IconLabelButton
            label={t('addNew')}
            onClick={openAddTeam}
            underline
          />
        )}
        {isUsersTab && (
          <Box display="flex">
            <IconLabelButton
              label={t('addNew')}
              onClick={() => setAddUserModalOpen(true)}
              underline
            />
          </Box>
        )}
        {isLocationsTab && (
          <Box display="flex">
            <IconLabelButton
              label={t('addNew')}
              onClick={openAddLocationModal}
              underline
            />
          </Box>
        )}
      </Tabs>
      <Locations
        currentIndex={value}
        onClick={onEnterLocationClick}
        onEditClick={handleEditLocation}
        onDeleteClick={handleDeleteLocation}
        locations={locations}
        isLoading={isLoadingLocations}
      />
      <Teams
        currentIndex={value}
        onClick={onEnterTeamClick}
        onDeleteClick={handleDeleteTeam}
        onEditClick={handleEditClick}
        teams={teams}
        isLoading={isLoadingTeams}
      />
      <Users currentIndex={value} onClickUser={onClickUser} />
      {selectedUser && (
        <UserModal
          isOpen={true}
          title={t('detailedInformation')}
          introText={t('learnerInfo')}
          handleClose={() => setSelectedUser(null)}
          addLearerSpinner={addLearnerSpinner}
          defaultValues={selectedUser}
          locations={locations}
          teams={teams}
        />
      )}
      {isAddingTeam && (
        <AddTeamModal
          isOpen={isAddingTeam}
          title={t('addTeam')}
          handleClose={handleCloseAddModal}
          locations={locations}
        />
      )}
      {isEditModalOpen && (
        <EditTeamModal
          isOpen={isEditModalOpen}
          title={t('editTeam')}
          introText={t('renameTeam')}
          handleClose={handleCloseEditModal}
          defaultValues={targetTeam}
          locations={locations}
        />
      )}
      {addUserModalOp && (
        <UserModal
          isOpen={addUserModalOp}
          title={t('addUser')}
          introText={t('learnerInfo')}
          handleClose={() => setAddUserModalOpen(false)}
          addLearerSpinner={addLearnerSpinner}
          teams={teams}
          locations={locations}
        />
      )}
      {(isAddingLocation || isEdititingLocation) && (
        <LocationModal
          isOpen={isAddingLocation || isEdititingLocation}
          title={isAddingLocation ? t('addLocation') : t('editLocation')}
          handleClose={handleCloseLocationModal}
          defaultValues={targetLocation}
        />
      )}
      <Outlet />
    </Box>
  );
};

export default OrganizationView;

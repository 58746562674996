import { useState, useCallback, useEffect } from 'react';

const useMediaUpload = file => {
  const [url, setUrl] = useState({ url: '', duration: 0 });
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const upload = useCallback(async () => {
    const data = new FormData();
    data.append('file', file);
    data.append('upload_preset', 'kaljwkvu');
    data.append('cloud_name', 'dwyuadnmg');
    data.append('folder', 'sqillz-2');

    try {
      setLoading(true);
      let response = await fetch(
        `https://api.cloudinary.com/v1_1/dwyuadnmg/auto/upload`,
        {
          method: 'post',
          body: data
        }
      );
      let json = await response.json();
      const duration = json?.duration;
      const url = json?.url;
      const durationInMinutes = Math.ceil(duration / 60);
      setLoading(false);
      setUrl({
        url,
        duration: durationInMinutes
      });
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(err);
    }
  });

  useEffect(() => {
    if (file) {
      upload();
    }
  }, [file]);

  return [url, isLoading, error];
};
export default useMediaUpload;

import _ from 'lodash';
import { useEffect, useState, useContext } from 'react';
import { AuthContext } from 'src/providers/AuthProvider';
import { getCompanyRef } from 'src/api/firebase';

const useActivatedUsers = () => {
  const { companyId } = useContext(AuthContext);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);

  const root = getCompanyRef(companyId);

  const onError = err => {
    setError(err);
    setLoading(false);
  };

  const observeAllUsers = observer => {
    return root
      .collection('users')
      .orderBy('name', 'asc')
      .onSnapshot(observer);
  };
  useEffect(() => {
    const unsubscribe = observeAllUsers({
      next: async snap => {
        let items = [];
        for (const doc of snap.docs) {
          const data = doc.data();
          items.push({
            id: doc.id,
            activated: !!data.signedInAt ? 'Yes' : 'No',
            ...data
          });
        }
        setUsers(_.orderBy(items, item => !item.signedInAt, 'asc'));
        setLoading(false);
      },
      error: onError
    });
    return () => unsubscribe();
  }, []);

  return [users, isLoading, error];
};

export default useActivatedUsers;

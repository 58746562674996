import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { func, string } from 'prop-types';
import { getUrlPreview } from 'src/cloud-functions/getUrlPreview';
import { useState } from 'react';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 375,
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.grey[5],
    marginLeft: theme.spacing(3)
  },
  media: {
    height: 179,
    objectFit: 'cover'
  },
  content: {
    padding: theme.spacing(1, 3)
  }
}));

const LinkPreview = ({ url, setImageUrl }) => {
  const classes = useStyles();
  const [data, setData] = useState(null);

  var re = new RegExp('^(http|https)://', 'i');
  var match = re.test(url);

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      try {
        const result = await getUrlPreview({ url });
        if (mounted) {
          setData(result.data);
          setImageUrl(checkImageUrl(result?.data?.image ?? ''));
        }
      } catch (error) {}
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, [url]);

  const checkImageUrl = imageUrl => {
    if (imageUrl?.startsWith('/static')) {
      return url + imageUrl ?? '';
    }

    return imageUrl ?? '';
  };

  const getImage = () => {
    return checkImageUrl(data?.image);
  };
  const handleClick = () => {
    if (match) {
      window.open(url, '_blank');
      return;
    }
  };

  return (
    <Card className={classes.root} elevation={0}>
      <CardActionArea onClick={handleClick}>
        {data ? (
          <>
            <CardMedia
              className={classes.media}
              component="img"
              src={getImage()}
              alt="Image not found"
              title="Contemplative Reptile"
            />
            <CardContent className={classes.content}>
              <Typography
                gutterBottom
                variant="body2"
                color="primary"
                component="h2"
              >
                {data?.title || data?.siteName}
              </Typography>
              <Typography variant="body1" color="textPrimary" component="p">
                {data?.description}
              </Typography>
            </CardContent>
          </>
        ) : (
          <Box px={2} pt={2}>
            <Skeleton variant="rect" width="100%" height={179} />
            <Box>
              <Box mb={2} mt={1}>
                <Skeleton variant="rect" width="100%" height={20} />
              </Box>
              <Box mb={1}>
                <Skeleton variant="rect" width="100%" height={20} />
              </Box>
              <Box mb={2}>
                <Skeleton variant="rect" width="100%" height={20} />
              </Box>
            </Box>
          </Box>
        )}
      </CardActionArea>
    </Card>
  );
};

LinkPreview.propTypes = {
  url: string,
  setImageUrl: func
};

export default LinkPreview;

import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { HikerIllustration } from 'src/components/Illustrations/HikerIllustration';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  emptyQueue: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.spacing(8),
    alignItems: 'center'
  },
  textSection: {
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(1)
    },
    maxWidth: 352,
    marginLeft: theme.spacing(4)
  },
  locationCard: {
    marginLeft: theme.spacing(-4)
  }
}));

const NoLocationsAdded = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div>
      <div className={classes.emptyQueue}>
        <HikerIllustration />
        <div className={classes.textSection}>
          <Typography variant="h4">{t('noLocations')}</Typography>
        </div>
      </div>
    </div>
  );
};

export default NoLocationsAdded;

import { Box, IconButton, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import ArrowRightCentered from 'src/icons/ArrowRightCentered';
import List from 'src/components/Lists/List';
import { array } from 'prop-types';

const OnBoardingTable = ({ insights }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  if (insights.length === 0) {
    return null;
  }

  const getCompletion = (completed, total) => {
    if (!total) {
      return 0;
    }

    return ((completed / total) * 100).toFixed();
  };

  return (
    <div className={classes.mainWrapper}>
      <List
        columns={[
          { name: t('insightsStreamTitle1'), align: 'left' },
          { name: t('insightsStreamTitle2'), align: 'left' },
          { name: t('insightsStreamTitle3'), align: 'left' },
          { name: t('insightsStreamTitle4'), align: 'left' }
        ]}
        fontVariant={'subtitle2'}
        defaultColor={'#181441'}
        data={insights?.map(item => ({
          [t('insightsStreamTitle1')]: item.name || '-',
          [t('insightsStreamTitle2')]: item.created || '-',
          [t('insightsStreamTitle3')]: item.posts || '-',
          [t('insightsStreamTitle4')]:
            getCompletion(item?.completedUsers.length, item?.users?.length) +
            '% ' +
            '(' +
            item?.completedUsers?.length +
            '/' +
            item?.users?.length +
            ')'
        }))}
        fullData={insights}
        RightIcon={({ obj }) => (
          <Box display="flex" justifyContent="center" alignItems="center">
            <IconButton
              onClick={() => {
                navigate('/app/insights/onboarding/streamdetail', {
                  state: obj
                });
              }}
            >
              <ArrowRightCentered />
            </IconButton>
          </Box>
        )}
      ></List>
    </div>
  );
};

export default OnBoardingTable;

OnBoardingTable.propTypes = {
  insights: array
};

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    marginTop: '1%'
  }
}));

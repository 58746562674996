import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Search = props => {
  return (
    <SvgIcon {...props}>
      <path
        d="M17 17L13.8 13.8M15.4 8.19996C15.4 12.1764 12.1765 15.3999 8.2 15.3999C4.22355 15.3999 1 12.1764 1 8.19996C1 4.22351 4.22355 0.999969 8.2 0.999969C12.1765 0.999969 15.4 4.22351 15.4 8.19996Z"
        stroke="#676570"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};

export default Search;

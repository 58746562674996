import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'src/components/Button';
import SqillzLogo from 'src/icons/SqillzLogo';
import styled from 'styled-components';

export const NumberNotFoundView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { phone } = useParams();

  const handleTryAgain = () => {
    navigate(-1);
  };

  return (
    <>
      <Box position="absolute" left={80} top={80}>
        <SqillzLogo />
      </Box>
      <Box
        display="flex"
        height="100vh"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box maxWidth={400}>
          <Typography variant="h4" color="textPrimary">
            {t('noAccountFound')}
          </Typography>
          <Box my={0.5} />
          <Typography variant="body2" color="primary">
            {/* {number} */}
            {phone.format()}
          </Typography>

          <Box my={0.5} mt={4} mb={7}>
            <Typography variant="body1" color="textPrimary">
              {t('SMSwasSentTo')}
            </Typography>
          </Box>
          <Button onClick={handleTryAgain} fullWidth>
            {t('tryAgain')}
          </Button>
        </Box>
      </Box>
    </>
  );
};

const Paragraph1 = styled.p`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 24px;
  color: #0b0b0c;
  padding: 5px;
`;
const Paragraph2 = styled.p`
  font-family: 'Inter';
  font-size: 16px;
  font-weight: bold;
  color: #6e26fc;
`;
const Paragraph3 = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #0b0b0c;
  padding: 1%;
`;

const Wrapper = styled.div`
  width: '100%';
  height: '100vh';
  display: 'flex';
  justify-content: center;
  align-items: center;
`;

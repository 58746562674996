import React from 'react';
import { SvgIcon } from '@material-ui/core';

const ArrowRight2 = props => {
  return (
    <SvgIcon {...props}>
      <svg width="26" height="18" viewBox="-1 -2 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z" fill="#323232" />
      </svg>

    </SvgIcon>
  );
};

export default ArrowRight2;

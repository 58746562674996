import { Box, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import List from 'src/components/Lists/List';
import ArrowRightCentered from 'src/icons/ArrowRightCentered';

const UsersList = ({ data, onClickUser, columns, columnsData }) => {
  const { t } = useTranslation();

  const [listIsSorted, setListIsSorted] = React.useState(false);
  const [listOfLearners, setListOfLearners] = React.useState(data);

  const sortLearnersList = () => {
    listIsSorted
      ? setListOfLearners(listOfLearners.sort((a, b) => b.localeCompare(a)))
      : setListOfLearners(listOfLearners.sort((a, b) => a.localeCompare(b)));
    setListIsSorted(!listIsSorted);
  };

  const arrayOfObjToString = arr =>
    !!arr?.length ? arr?.map(obj => obj.name).join(', ') : '-';

  const getRoles = roles => roles?.join(', ');

  const mapData = () =>
    !!data.length
      ? data?.map(user => ({
          imageUrl: user?.imageUrl,
          [t('name')]: user?.name?.full ?? 'No name',
          [t('location')]: arrayOfObjToString(user?.locations),
          [t('team')]: arrayOfObjToString(user?.teams),
          [t('role')]: getRoles(user?.roles)
        }))
      : [];

  return (
    <div>
      <div>
        <div onClick={sortLearnersList}></div>
        <List
          columns={
            columns ?? [
              { name: t('name'), align: 'left' },
              { name: t('location'), align: 'left' },
              { name: t('team'), align: 'center' },
              { name: t('role'), align: 'center' }
            ]
          }
          avatar
          fontVariant={'subtitle2'}
          defaultColor="#323138"
          data={columnsData ?? mapData()}
          fullData={data}
          RightIcon={({ obj }) => (
            <Box
              visibility={
                typeof onClickUser === 'function' ? 'visible' : 'hidden'
              }
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <IconButton onClick={() => onClickUser(obj)}>
                <ArrowRightCentered />
              </IconButton>
            </Box>
          )}
        ></List>
      </div>
    </div>
  );
};

UsersList.propTypes = {
  data: PropTypes.array,
  columnsData: PropTypes.array,
  columns: PropTypes.array,
  onClickUser: PropTypes.func
};

UsersList.defaultProps = {
  data: []
};

export default UsersList;

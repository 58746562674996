import { getCompanyRef } from 'src/api/firebase';
import { getTimestamp } from '../utils';

export const addLocation = ({ companyId, name, isChatEnabled }) => {
  const root = getCompanyRef(companyId);
  const ref = root.collection('locations');

  const newLocation = {
    name,
    teamsCount: 0,
    usersCount: 0,
    isChatEnabled,
    createdAt: getTimestamp()
  };

  return ref.doc().set(newLocation);
};

import React, { useContext } from 'react';
import { Route, Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from 'src/providers/AuthProvider';

//eslint-disable-next-line
const AuthorizedRoute = ({ children, component, ...rest }) => {
  const { currentUser } = useContext(AuthContext);
  const location = useLocation();
  let locationIsApp = location.pathname.includes('/app');

  if (!!currentUser) {
    return <Route element={component} {...rest} />;
  }
  return locationIsApp ? null : <Navigate to="/login" />;
};

export default AuthorizedRoute;

import { Box, Typography } from '@material-ui/core';
import { string } from 'prop-types';
import React from 'react';

const CounterText = ({ children }) => {
  return (
    <Box mb={3}>
      <Typography
        style={{ flex: 1, color: '#676570', fontSize: 14, fontWeight: 500 }}
        align="right"
      >
        {children}
      </Typography>
    </Box>
  );
};

CounterText.propTypes = {
  text: string,
  children: string
};

export default CounterText;

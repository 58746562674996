/* eslint-disable react/prop-types */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const ArrowRightCentered = props => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height={props.height ? props.height : "24"}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 17L14.22 11.78L9 6.56"
          stroke="#323138"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default ArrowRightCentered;

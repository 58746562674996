import React from 'react';
import { SvgIcon } from '@material-ui/core';

const PostsIcon = props => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_3984_72961)">
        <path
          d="M1 5H3V19H1V5ZM5 5H7V19H5V5ZM23 5H9V19H23V5ZM11 17L13.5 13.85L15.29 16L17.79 12.78L21 17H11Z"
          fill="#9A98A3"
        />
      </g>
      <defs>
        <clipPath id="clip0_3984_72961">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default PostsIcon;

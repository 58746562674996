import * as React from 'react';

export const HikerIllustration = () => {
  return (
    <svg
      width="224"
      height="184"
      viewBox="0 0 224 184"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M166.739 23.1036C153.808 15.7464 139.264 12.6679 124.197 11.444L125.095 13.5019L124.901 15.77L124.174 17.4404L122.149 18.7351L120.731 19.2077L119.427 22.461L118.451 27.1107L124.847 27.0327L130.99 27.2028L132.332 28.6204L132.542 31.2713L132.256 38.8813L133.943 40.8093L135.566 43.153L136.003 44.6627L135.269 46.4796L137.1 48.7525L142.243 55.9183L148.024 64.1474L152.015 70.3564L153.22 71.937L156.147 73.5909L157.158 75.6676L156.958 77.9925L155.864 79.6676L153.926 80.6008L151.564 80.3646L149.818 79.0202L148.655 75.8094L142.16 69.3027L132.455 58.5692L132.554 64.4758V73.6027L132.455 77.6192L131.29 79.1029L130.215 79.5896L126.508 79.6534L96.1975 74.8573L95.295 74.5123C94.9451 74.4413 94.6091 74.3136 94.3003 74.1343C94.2532 74.1029 94.2096 74.0665 94.1704 74.0256C94.0357 73.8697 93.8845 73.4302 93.7357 72.7545C93.4096 71.2708 93.093 68.6482 92.9773 65.3949L92.9276 64.9224L92.9395 64.0033C92.9158 62.5857 92.9395 61.0524 92.9962 59.467L93.0174 57.8132L93.1899 56.4263C93.3317 54.7299 93.5396 52.9934 93.8254 51.2545V51.2403V51.2238C94.0782 49.6999 94.3925 48.1736 94.7705 46.6757V46.6592C95.343 44.3871 96.1236 42.1726 97.1024 40.0438C96.0208 39.5824 94.9912 39.0074 94.031 38.3285L93.9932 38.3025C93.7782 38.1513 93.5632 37.9977 93.3553 37.83L91.5313 36.5045L90.2082 34.9665C89.9531 34.6908 89.7168 34.4238 89.4995 34.1655C89.1683 33.7736 88.8659 33.3582 88.5946 32.9228L88.2118 32.3676L88.252 32.1762C88.2363 32.1277 88.2253 32.0779 88.2189 32.0273C88.213 31.593 88.3249 31.1652 88.5426 30.7893L84.2898 25.6624L81.5114 20.7906L81.1759 18.4114L81.7878 16.6702L84.5663 13.7145L88.1598 10.9951C71.1748 11.8504 55.6948 14.8557 47.0168 24.3086C35.8912 36.436 36.0518 59.2875 34.3649 80.1307C32.678 100.974 29.1057 119.849 34.5256 137.519C38.9555 152.061 49.5047 165.84 62.3693 171.742C82.7281 157.651 121.553 136.966 158.701 122.675C159.36 122.086 160.029 121.492 160.709 120.893C175.937 107.438 196.258 90.6515 198.698 72.2962C201.115 53.9054 185.654 33.9056 166.739 23.1036Z"
          fill="#E0EBF5"
        />
        <path
          d="M107.302 13.8774C107.016 14.8697 106.872 15.8974 106.874 16.93C106.874 20.774 108.814 24.0462 111.529 25.2772L111.184 28.6109"
          stroke="#181441"
          strokeWidth="0.59066"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M113.768 31.7886C115.436 32.1713 117.272 32.2611 117.43 31.7224C117.667 30.8766 118.985 24.5967 119.443 22.3971C120.023 21.4543 120.44 20.4208 120.677 19.3398"
          stroke="#181441"
          strokeWidth="0.59066"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M172.69 117.581C170.717 116.905 164.263 108.366 164.263 98.2331C164.263 86.4719 176.726 64.1756 177.907 56.2301C178.661 51.1811 182.06 6.69971 207.879 6.69971C221.958 6.69971 223.133 34.2268 223.133 46.5385C223.133 75.2989 181.314 99.7972 181.314 114.736C178.51 115.888 175.629 116.838 172.69 117.581Z"
          fill="#181441"
        />
        <path
          d="M175.866 116.513C175.866 93.4493 193.078 51.4274 208.217 36.6396"
          stroke="white"
          strokeWidth="0.945056"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M76.4743 162.705C75.1559 162.313 71.5718 157.744 71.5718 151.856C71.5718 146.186 73.1642 137.917 73.1642 130.245C73.1642 125.978 71.5718 124.466 71.5718 119.389C71.5718 111.297 79.2409 108.833 81.9296 108.833C85.887 108.833 88.0039 112.218 88.0039 114.011C88.0039 116.513 87.2951 118.413 87.2951 121.182C87.2951 126.595 89.1853 127.273 88.9868 131.937C88.3961 145.572 82.7352 158.902 77.7595 161.926L76.4743 162.705Z"
          fill="#181441"
        />
        <path
          d="M76.4742 162.705C78.0902 161.311 69.0413 117.224 61.1926 109.423C52.654 100.934 45.6346 96.4518 43.3169 93.671C39.2059 88.7354 36.5881 70.1226 18.4926 70.1226C13.7059 70.1226 2.42668 74.08 0.397175 90.8287C-0.266727 96.3266 -1.47404 104.738 9.96823 116.926C17.6752 125.139 27.3147 122.311 36.7369 131.209C43.5201 137.614 42.5703 147.275 48.9045 155.437C53.9724 161.958 67.1299 170.754 76.4742 162.705Z"
          fill="#181441"
        />
        <path
          d="M74.2534 163.757C74.2534 152.692 39.3501 102.756 25.3845 95.6133"
          stroke="white"
          strokeWidth="0.945056"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M76.4742 162.705C76.1268 160.082 74.662 137.803 78.8132 129.685"
          stroke="white"
          strokeWidth="0.945056"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M48.9067 183.172C59.3496 168.257 152.657 117.37 208.711 107.972"
          stroke="#181441"
          strokeWidth="1.65385"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M104.119 31.3002V29.8117C104.119 29.0598 104.418 28.3387 104.95 27.807C105.481 27.2753 106.203 26.9766 106.955 26.9766H111.351"
          stroke="#181441"
          strokeWidth="0.59066"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M132.455 58.5688V76.8226C132.455 77.5745 132.156 78.2957 131.624 78.8274C131.093 79.3591 130.371 79.6578 129.62 79.6578H125.582"
          stroke="#181441"
          strokeWidth="0.59066"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M118.482 26.9766H129.619C130.371 26.9766 131.092 27.2753 131.624 27.807C132.156 28.3387 132.455 29.0598 132.455 29.8117V39.0567"
          stroke="#181441"
          strokeWidth="0.59066"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M106.555 165.795V171.938H89.875V169.339L100.209 164.593L106.555 165.795Z"
          stroke="#181441"
          strokeWidth="0.59066"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M142.739 159.905C143.583 161.795 145.574 166.693 145.574 166.693L137.251 171.926H129.709L128.826 169.035L138.3 161.784L142.739 159.905Z"
          stroke="#181441"
          strokeWidth="0.59066"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M109.074 2.59845C109.13 2.33923 109.134 2.0713 109.084 1.81068C109.035 1.55005 108.933 1.3021 108.786 1.08164C107.864 -0.189463 105.263 0.10823 102.976 1.75499C102.11 2.36565 101.375 3.14225 100.812 4.03966C100.737 4.0065 100.657 3.98805 100.576 3.98532C99.4651 3.98532 81.0058 13.8588 81.1807 18.414C81.3319 22.3265 85.906 28.2591 88.5781 30.7493"
          stroke="#181441"
          strokeWidth="0.59066"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M97.0648 23.3898C95.1747 21.4997 92.4648 19.2244 91.8032 18.6007C92.9609 17.9959 102.227 8.81464 103.274 7.77508C104.377 7.5378 105.415 7.06557 106.319 6.39057C107.869 5.27305 108.876 3.82239 109.076 2.61035"
          stroke="#181441"
          strokeWidth="0.59066"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M103.347 31.8596C102.303 27.7085 100.342 23.451 98.3074 23.262C94.5272 22.9123 87.9543 30.0191 88.2189 32.0368C88.3938 33.3741 92.682 38.1797 97.1143 40.0178"
          stroke="#181441"
          strokeWidth="0.59066"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M117.941 29.4995C119.651 30.5178 121.074 32.2024 122.243 34.3335"
          stroke="#181441"
          strokeWidth="0.59066"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M126.803 53.6175C127.455 63.1673 126.456 72.0461 124.993 73.192C121.686 75.7909 95.8147 75.909 94.1609 74.0189C92.5708 72.202 88.9087 31.7466 111.172 28.6113"
          stroke="#181441"
          strokeWidth="0.59066"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M116.979 14.1758C117.523 15.0807 118.109 15.9194 118.711 16.1226C118.747 16.1339 118.783 16.1473 118.817 16.1628"
          stroke="#181441"
          strokeWidth="0.59066"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M103.096 7.93604C102.894 8.19116 102.781 8.50582 102.775 8.83148C102.775 10.9862 102.832 13.8781 107.307 13.8781C107.604 12.8432 108.724 10.5704 110 9.55917C110.236 9.6584 110.822 9.90648 111.533 10.1971"
          stroke="#181441"
          strokeWidth="0.59066"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M121.187 19.0165C122.196 18.9055 125.095 18.4825 125.095 13.9179C125.095 12.12 123.377 10.8229 123.377 9.82583C123.377 8.8288 123.966 7.04264 122.65 5.76445C121.05 4.2122 118.501 4.85956 116.218 4.85956C114.073 4.85956 112.889 2.36698 109.962 2.36698C109.65 2.3573 109.342 2.43945 109.076 2.60324"
          stroke="#181441"
          strokeWidth="0.59066"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M118.583 16.3821C118.77 16.1758 119.001 16.014 119.258 15.9087C119.516 15.8035 119.794 15.7573 120.072 15.7737C120.349 15.7902 120.62 15.8687 120.863 16.0036C121.107 16.1385 121.317 16.3263 121.478 16.5532C121.639 16.78 121.747 17.0401 121.794 17.3144C121.842 17.5886 121.827 17.8699 121.751 18.1376C121.675 18.4053 121.54 18.6525 121.355 18.861C121.171 19.0695 120.943 19.2341 120.686 19.3425"
          stroke="#181441"
          strokeWidth="0.59066"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M151.367 79.9961L134.604 163.1"
          stroke="#181441"
          strokeWidth="1.65385"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M159.989 37.2563L153.092 71.4508"
          stroke="#181441"
          strokeWidth="1.65385"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M127.753 53.1588C131.061 56.9863 142.382 70.1888 148.669 75.6724C148.665 76.3046 148.789 76.931 149.032 77.5143C149.276 78.0977 149.635 78.6259 150.087 79.0676C151.838 80.8206 154.526 80.9718 156.09 79.4101C157.654 77.8484 157.508 75.1574 155.75 73.4043C155.046 72.6924 154.138 72.2175 153.151 72.0458C150.151 67.2024 140.301 52.7619 135.285 46.4702"
          stroke="#181441"
          strokeWidth="0.59066"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M119.992 35.3208C126.104 30.7775 136.181 41.7638 136.015 44.6533C135.85 47.5428 128.105 53.9503 125.456 53.9573C122.222 53.9621 114.789 39.1885 119.992 35.3208Z"
          stroke="#181441"
          strokeWidth="0.59066"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M125.489 72.4712C125.379 72.7448 125.209 72.9907 124.993 73.1918C122.931 74.8126 112.079 75.4694 103.81 75.2733C107.784 94.9919 135.233 159.241 137.414 162.653C139.11 163.017 142.989 161.321 143.594 160.229C144.18 159.175 127.89 80.7593 125.489 72.4712Z"
          fill="#181441"
        />
        <path
          d="M95.0469 74.4458C95.1154 81.2408 96.2873 158.341 98.3546 165.216C100.245 166.161 105.206 166.397 106.86 165.689C108.356 165.048 117.13 91.6505 118.794 74.7081C110.99 75.6035 98.5743 75.4735 95.0469 74.4458Z"
          fill="#181441"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="223.125" height="184" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

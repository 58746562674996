import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Delete = props => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 6.18005H19.5L18.94 17.3798C18.8336 19.5087 17.0764 21.1801 14.945 21.1801H9.055C6.92351 21.1801 5.16644 19.5087 5.05999 17.3798L4.5 6.18005ZM6.07688 7.68005L6.55811 17.3049C6.62465 18.6355 7.72281 19.6801 9.055 19.6801H14.945C16.2772 19.6801 17.3754 18.6354 17.4418 17.305L17.9232 7.68005H6.07688Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 6C8.25 4.34315 9.59315 3 11.25 3H12.75C14.4068 3 15.75 4.34314 15.75 6V7.5H8.25V6ZM11.25 4.5C10.4216 4.5 9.75 5.17157 9.75 6H14.25C14.25 5.17158 13.5784 4.5 12.75 4.5H11.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6.77997C3 6.36576 3.33579 6.02997 3.75 6.02997H20.25C20.6642 6.02997 21 6.36576 21 6.77997C21 7.19418 20.6642 7.52997 20.25 7.52997H3.75C3.33579 7.52997 3 7.19418 3 6.77997Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.25 10.6799C14.6642 10.6799 15 11.0157 15 11.4299V15.18C15 15.5942 14.6642 15.93 14.25 15.93C13.8358 15.93 13.5 15.5942 13.5 15.18V11.4299C13.5 11.0157 13.8358 10.6799 14.25 10.6799Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 10.6799C10.1642 10.6799 10.5 11.0157 10.5 11.4299V15.18C10.5 15.5942 10.1642 15.93 9.75 15.93C9.33579 15.93 9 15.5942 9 15.18V11.4299C9 11.0157 9.33579 10.6799 9.75 10.6799Z"
      />
    </SvgIcon>
  );
};

export default Delete;

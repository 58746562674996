import { CircularProgress, makeStyles } from '@material-ui/core';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CounterText,
  ListCard,
  NoLocationsAdded,
  TabPanel
} from 'src/components';
import { LOCATIONS_TAB } from './OrganizationView';

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(2)
  }
}));

const Locations = ({
  currentIndex,
  onClick,
  onDeleteClick,
  onEditClick,
  locations,
  isLoading
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TabPanel value={currentIndex} index={LOCATIONS_TAB}>
      <div>
        <CounterText>
          {t(
            locations.length === 1
              ? 'locationsCount_one'
              : 'locationsCount_other',
            {
              count: locations.length
            }
          )}
        </CounterText>
        {isLoading ? (
          <div className={classes.content}>
            <CircularProgress />
          </div>
        ) : locations.length < 1 ? (
          <NoLocationsAdded />
        ) : (
          <>
            {locations.map(item => (
              <ListCard
                key={item.id}
                id={item.id}
                title={item.name}
                numberOfLearners={item.usersCount}
                numberOfTeams={item.teamsCount}
                subHeader={t('location')}
                onClick={() => onClick(item)}
                onDeleteClick={() => onDeleteClick(item)}
                onEditClick={() => onEditClick(item)}
                type="team"
              />
            ))}
          </>
        )}
      </div>
    </TabPanel>
  );
};

Locations.propTypes = {
  currentIndex: number.isRequired,
  onClick: func.isRequired,
  onEditClick: func.isRequired,
  onDeleteClick: func.isRequired,
  locations: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
      usersCount: number.isRequired,
      teamsCount: number.isRequired
    })
  ).isRequired,
  isLoading: bool.isRequired
};

export default Locations;

import { firestore, getCompanyRef } from 'src/api/firebase';
import { getTimestamp, truncateDraft } from '../utils';

export const editChecklist = async ({ companyId, defaultTasks, ...checklist }) => {
  const batch = firestore.batch();
  const root = getCompanyRef(companyId);
  const checklistRef = root.collection('checklists').doc(checklist.id);

  // Extract relevant fields from checklist
  const {
    title,
    description,
    frequency,
    isDraft,
    isPublished,
    accessLocations,
    accessTeams,
    notificationFrequency,
    notificationTeams,
    allTeamsAccess,
    allLocationsAccess,
    tasks: updatedTasks,
  } = checklist;

  const initialTaskIds = defaultTasks?.map(task => task.id);
  const currentTaskIds = updatedTasks?.map(task => task.id);

  const checklistFrequency = frequency?.[0]?.id ?? undefined;
  const notificationTeamIds = notificationTeams?.map(nt => nt.id) ?? undefined
  const notificationFreq = notificationFrequency?.[0]?.id != null ? notificationFrequency?.[0]?.id : undefined;

  const notificationFrequencyObject = {};
  if (notificationTeamIds) notificationFrequencyObject.teamIds = notificationTeamIds;
  if (notificationFreq !== undefined) notificationFrequencyObject.frequency = notificationFreq;

  // Handle existing tasks update and new tasks addition
  let taskIdsOrder = [];
  const tasksPromises = updatedTasks?.map(async (task, index) => {
    let taskRef;
    if (task.id) {
      // Existing task
      taskRef = checklistRef.collection('tasks').doc(task.id);
      taskIdsOrder[index] = task.id; // Preserve existing task ID in order
    } else {
      // New task
      taskRef = checklistRef.collection('tasks').doc();
      taskIdsOrder[index] = taskRef.id; // Capture new task ID in order
    }

    // Define task data with current index for ordering
    const taskData = {
      ...task,
      truncatedDescription: task?.description ? truncateDraft(task.description) : '',
      index,
      updatedAt: getTimestamp(),
      ...(task.id ? {} : { createdAt: getTimestamp() }),
    };

    return { taskRef, taskData };
  }) ?? [];

  if (updatedTasks !== undefined) {
    const resolvedTasks = await Promise.all(tasksPromises);
    const taskChangeArray = resolvedTasks.map(({ taskRef, taskData }) => {
      if (taskData.id) {
        batch.update(taskRef, taskData);
      } else {
        batch.set(taskRef, taskData);
      }
      return taskRef.id;
    });
  
    // Remove tasks that are no longer present, only if updatedTasks is provided
    const removedTaskIds = initialTaskIds?.filter(id => !currentTaskIds?.includes(id)) ?? [];
    removedTaskIds.forEach(taskId => {
      const taskRef = checklistRef.collection('tasks').doc(taskId);
      batch.delete(taskRef);
    });

    // Execute all task updates/additions and capture the promise results
    await Promise.all(taskChangeArray, removedTaskIds);
  }

  const updatedChecklistData = JSON.parse(JSON.stringify({
    title,
    description,
    checklistFrequency,
    locationIds: accessLocations?.map(item => item.id),
    teamIds: accessTeams?.map(item => item.id),
    ...(Object.keys(notificationFrequencyObject).length > 0 && { notificationFrequency: notificationFrequencyObject }),
    ...(taskIdsOrder && updatedTasks && { taskIds: taskIdsOrder.filter(id => id) }),
    allTeamsAccess,
    allLocationsAccess,
    isPublished,
    isDraft,
    updatedAt: getTimestamp()
  }));

  batch.set(checklistRef, updatedChecklistData, { merge: true });

  await batch.commit();
};
import dayjs from 'dayjs';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useCompanyId } from '../company/useCompanyId';
import { getCompanyRef } from '../firebase';

export const useOnboardingStreams = () => {
  const { companyId } = useCompanyId();
  const [streams, setStreams] = useState([]);
  const [insights, setInsights] = useState({
    completed: 0,
    total: 0
  });

  useEffect(() => {
    if (!companyId) return;

    const fetchData = async () => {
      let onboardingStreams = [];

      const root = getCompanyRef(companyId);
      const streamsQuery = await root
        .collection('streams')
        .where('isOnboardingStream', '==', true)
        .get();
      if (streamsQuery.empty) {
        return;
      }

      onboardingStreams = streamsQuery.docs.map(doc => {
        const { name, createdAt, postsCount } = doc.data();
        return {
          ...doc.data(),
          id: doc.id,
          name,
          created: dayjs(createdAt?.toDate()).format('YYYY-MM-DD'),
          posts: postsCount
        };
      });

      let streamsWithUsers = [];

      for (const stream of onboardingStreams) {
        const streamUsers = [];
        const teamIds = stream.teams;

        const completedQuery = await root
          .collection('streams')
          .doc(stream.id)
          .collection('completedBy')
          .get();

        stream.completedUsers = completedQuery.docs.map(doc => doc.id);

        while (teamIds.length) {
          const teamsBatch = teamIds.splice(0, 10);

          const querySnapshot = await root
            .collection('users')
            .where('teamIds', 'array-contains-any', teamsBatch)
            .get();

          querySnapshot.docs.forEach(doc => {
            const data = doc.data();
            streamUsers.push({
              id: doc.id,
              name: data.name.full,
              location: data.locations.map(item => item.name).join(', '),
              team: data.teams.map(item => item.name).join(', '),
              completed: stream.completedUsers.includes(doc.id) ? 'Yes' : 'No'
            });
          });
        }
        stream.users = streamUsers;

        streamsWithUsers.push(stream);
      }

      const userIds = streamsWithUsers
        ?.reduce(
          (acc, curr) => _.uniqBy([...acc, ...curr.users], item => item.id),
          []
        )
        .map(item => item.id);

      const fullyOnboardedUsers = [];

      userIds?.forEach(userId => {
        const isUserFullyOnboarded = streamsWithUsers?.every(stream => {
          const user = stream?.users?.find(item => item.id === userId);
          return user?.completed === 'Yes';
        });
        if (isUserFullyOnboarded) {
          fullyOnboardedUsers.push(userId);
        }
      });

      setStreams(streamsWithUsers);
      setInsights({
        total: userIds?.length || 0,
        completed: fullyOnboardedUsers?.length || 0
      });
    };

    fetchData();
  }, [companyId]);

  return { insights, streams };
};

import {
  Box,
  makeStyles,
  Paper,
  Typography,
  useTheme
} from '@material-ui/core';
import React from 'react';
import PropTypes, { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import PostsIcon from 'src/icons/PostsIcon';
import LocationsIcon from 'src/icons/LocationsIcon';
import TeamsIcon from 'src/icons/TeamsIcon';
import UsersIcon from 'src/icons/UsersIcon';
import ToolTip from './ToolTip';

const useStyles = makeStyles(theme => ({
  paperCard: {
    marginBottom: theme.spacing(2),
    background: theme.palette.common.white,
    borderRadius: 12,
    border: `1px solid ${theme.palette.common.white}`,
    padding: theme.spacing(3),
    '&:hover': {
      border: '1px solid #6E26FC'
    },
    width: '100%'
  },
  locationTitleText: {
    fontSize: 12,
    color: theme.palette.text.hint,
    fontWeight: '600',
    marginBottom: 4
  },
  locationNameText: {
    color: theme.palette.grey[800],
    fontWeight: '700',
    fontSize: 32
  },
  locationInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  locationInfoSection: {
    display: 'flex',
    marginTop: theme.spacing(2),
    justifyContent: 'space-between',
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  locationInfoPaper: {
    background: theme.palette.background.lightBlue,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: 12,
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textIconSection: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    },
    alignItems: 'center'
  },
  roleInfo: {
    color: theme.palette.primary.main,
    fontSize: 12,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textTransform: 'capitalize',
    fontWeight: '600'
  },
  value: {
    color: theme.palette.background.dark
  },
  cardRoot: {
    display: 'flex',
    '& > *': {
      '&:not(:first-child)': {
        marginLeft: theme.spacing(2)
      }
    }
  }
}));

const StreamCard = ({
  id,
  title,
  postsCount,
  locationsCount,
  teamsCount,
  usersCount,
  roles,
  onEditClick,
  onDeleteClick
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const items = [
    {
      id: 'posts',
      Icon: PostsIcon,
      title: t('posts'),
      value: postsCount
    },
    {
      id: 'locations',
      Icon: LocationsIcon,
      title: t('locations'),
      value: locationsCount
    },
    {
      id: 'teams',
      Icon: TeamsIcon,
      title: t('teams'),
      value: teamsCount
    },
    {
      id: 'users',
      Icon: UsersIcon,
      title: t('users'),
      value: usersCount
    }
  ];

  return (
    <div className={classes.cardRoot}>
      <Paper elevation={0} className={classes.paperCard}>
        <Box flexDirection="row" display="flex" justifyContent="space-between">
          <Box>
            <Typography className={classes.locationTitleText} variant="h5">
              {t('stream')}
            </Typography>
            <div className={classes.locationInfo}>
              <Typography className={classes.locationNameText} variant="h3">
                {title}
                <br />
                <Typography className={classes.roleInfo}>{roles}</Typography>
              </Typography>
            </div>
          </Box>
          <Box position="relative" right={-8} top={-4}>
            <ToolTip onClickEdit={onEditClick} onClickDelete={onDeleteClick} />
          </Box>
        </Box>
        <div className={classes.cardRoot}>
          {items.map(({ Icon, title, value, id }) => (
            <Paper key={id} elevation={0} className={classes.locationInfoPaper}>
              <div className={classes.textIconSection}>
                <Icon style={{ color: theme.palette.text.hint }} />
                <Typography className={classes.locationTitleText} variant="h5">
                  {title}
                </Typography>
              </div>
              <Typography className={classes.value} variant="h3">
                {value}
              </Typography>
            </Paper>
          ))}
        </div>
      </Paper>
    </div>
  );
};

StreamCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  postsCount: PropTypes.number,
  locationsCount: PropTypes.number,
  teamsCount: PropTypes.number,
  usersCount: PropTypes.number,
  roles: PropTypes.arrayOf(PropTypes.string),
  onEditClick: func,
  onDeleteClick: func
};

export default StreamCard;

import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <g clipPath="url(#clip0_8985_26944)">
        <path
          fill="#0B0B0C"
          d="M11.625 8.25a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z"
        ></path>
        <path
          fill="#323232"
          d="M6.375 8.25a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25zM9 13.5c1.71 0 3.165-1.245 3.75-3h-7.5c.585 1.755 2.04 3 3.75 3z"
        ></path>
        <path
          fill="#323232"
          d="M8.992 1.5C4.853 1.5 1.5 4.86 1.5 9c0 4.14 3.353 7.5 7.492 7.5 4.148 0 7.508-3.36 7.508-7.5 0-4.14-3.36-7.5-7.508-7.5zM9 15c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_8985_26944">
          <path fill="#fff" d="M0 0H18V18H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
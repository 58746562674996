import { firestoreArrayUnion } from '../utils';
export const addStreamToLocation = ({ batch, locationRef, streamDocId }) => {
  batch.set(
    locationRef,
    {
      streams: firestoreArrayUnion(streamDocId)
    },
    { merge: true }
  );
};

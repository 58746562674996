import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Logout = props => {
  return (
    <SvgIcon {...props}>
      <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 4L13.59 5.41L16.17 8H6V10H16.17L13.59 12.58L15 14L20 9L15 4ZM2 2H10V0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H10V16H2V2Z" fill="white" />
        <path d="M0 0H2V2H0V0Z" fill="white" />
        <path d="M0 16H2V18H0V16Z" fill="white" />
      </svg>
    </SvgIcon>
  );
};

export default Logout;

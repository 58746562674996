import React from 'react';
import { Radio as MUIRadio, makeStyles } from '@material-ui/core';
import { bool } from 'prop-types';
import UncheckedRadio from 'src/icons/UncheckedRadio';
import DisabledRadio from 'src/icons/DisabledRadio';
import CheckedRadio from 'src/icons/CheckedRadio';
import DisabledCheckedRadio from 'src/icons/DisabledCheckedRadio';

const useStyles = makeStyles(theme => ({
  icon: {
    width: 22,
    height: 22
  }
}));

const Radio = ({ whiteBackground, checked, disabled, ...props }) => {
  const classes = useStyles();

  return (
    <MUIRadio
      icon={disabled ? <DisabledRadio className={classes.icon} /> : <UncheckedRadio className={classes.icon} />}
      checkedIcon={disabled ? <DisabledCheckedRadio className={classes.icon} /> : <CheckedRadio className={classes.icon} />}
      color="primary"
      disabled={disabled}
      checked={checked || false}
      edge="start"
      disableRipple
      {...props}
    />
  );
};

Radio.propTypes = {
  whiteBackground: bool,
  disabled: bool,
  checked: bool
};

export default Radio;

import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
// import AddIcon from '@material-ui/icons/Add';
import PropTypes, { bool, node, string } from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import AddIcon from 'src/icons/AddIcon';

const useStyles = makeStyles(theme => ({
  label: {
    alignItems: 'center'
  },
  button: {
    paddingRight: 0
  },
  buttonContainer: ({ underline, color }) => ({
    paddingBottom: 3,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    borderRadius: 0,
    borderBottomRadius: 0,
    borderBottom: `${underline ? '2px' : '0px'} solid ${
      color ? color : theme.palette.primary.main
    }`
  }),
  icon: {
    fontSize: 32
  }
}));

const IconLabelButton = ({
  label: Label,
  onClick,
  icon: Icon,
  underline,
  hideIcon,
  color
}) => {
  const classes = useStyles({ underline, color });

  return (
    <Button
      className={classes.buttonContainer}
      classes={{
        label: classes.label
      }}
      color={color ? 'secondary' : 'primary'}
      onClick={onClick}
    >
      {Icon ? <Icon /> : hideIcon ? null : <AddIcon />}
      <Box mx={0.5} />
      {typeof Label === 'string' ? (
        <Typography color={color ? 'secondary' : 'primary'} variant="h4">
          {Label}
        </Typography>
      ) : (
        <Label color="primary" variant="h4" />
      )}
    </Button>
  );
};

IconLabelButton.propTypes = {
  className: string,
  color: string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  icon: node,
  underline: bool,
  hideIcon: bool
};

IconLabelButton.defaultProps = {
  underline: true
};

export default IconLabelButton;

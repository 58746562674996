import React from 'react';
import {
  Typography,
  makeStyles,
  Tooltip,
  IconButton,
  withStyles,
  Box,
  ButtonBase
} from '@material-ui/core';
import More from 'src/icons/More';
import Zoom from '@material-ui/core/Zoom';
import Edit from 'src/icons/Edit';
import Delete from 'src/icons/Delete';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  paperStyle: {
    background: theme.palette.background.lightBlue,
    minWidth: 130,
    padding: theme.spacing(2),
    width: '100%',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-evenly',
    borderRadius: 8,
    border: `1px solid ${theme.palette.background.lightBlue} `,
    '&:hover': {
      border: '1px solid #6E26FC'
    }
  },
  paperTextStyle: {
    color: '#5B5971',
    marginLeft: '0.5rem'
  }
}));

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    borderRadius: 12,
    padding: '1rem'
  }
}))(Tooltip);

const ToolTip = ({ onClickEdit, onClickDelete }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <LightTooltip
        arrow
        interactive
        TransitionComponent={Zoom}
        title={
          <>
            {onClickEdit && (
              <>
                <ButtonBase
                  focusRipple
                  onClick={onClickEdit}
                  className={classes.paperStyle}
                >
                  <Edit />
                  <Box display="flex" flex={1} ml={1}>
                    <Typography className={classes.paperTextStyle} variant="h6">
                      {t('edit')}
                    </Typography>
                  </Box>
                </ButtonBase>
                <Box my={1} />
              </>
            )}
            <ButtonBase
              focusRipple
              onClick={onClickDelete}
              className={classes.paperStyle}
            >
              <Delete />
              <Box display="flex" flex={1} ml={1}>
                <Typography className={classes.paperTextStyle} variant="h6">
                  {t('delete')}
                </Typography>
              </Box>
            </ButtonBase>
          </>
        }
      >
        <IconButton>
          <More />
        </IconButton>
      </LightTooltip>
    </div>
  );
};

ToolTip.propTypes = {
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func
};

export default ToolTip;

import { Box, Grid, makeStyles } from '@material-ui/core/';
import MUIModal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalHeader from './ModalHeader';
import ModalNavBar from './ModalNavBar';

const useStyles = makeStyles(theme => ({
  headerContainer: {},
  contentContainer: {},
  wrapper: {
    zIndex: 2000,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.lightBlue,
    overflow: 'auto',
    padding: theme.spacing(6, 7),

    '@media print': {
      backgroundColor: theme.palette.common.white
    }
  }
}));

const Modal = ({
  isOpen,
  handleClose,
  title,
  introText,
  handleSave,
  handleSaveDraft,
  maybeButtonTitle,
  children,
  navBarRightButtonTitle,
  isLoading,
  isLoadingDraft,
  isFormValid,
  width,
  hideRightButton
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <MUIModal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box className={classes.wrapper}>
        <ModalNavBar
          hideRightButton={hideRightButton}
          onSave={handleSave}
          onSaveDraft={handleSaveDraft}
          handleClose={handleClose}
          navBarRightButtonTitle={navBarRightButtonTitle}
          isLoading={isLoading}
          isLoadingDraft={isLoadingDraft}
          isFormValid={isFormValid}
        />
        <Box my={1} />
        <Grid container direction="column" alignItems="center">
          <Grid container xs={width ?? 6} direction="column">
            <Grid item xs>
              <ModalHeader
                title={title}
                introText={introText}
                handleClose={handleClose}
                maybeButtonTitle={maybeButtonTitle}
              />
            </Grid>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </MUIModal>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  introText: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.object,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  handleSaveDraft: PropTypes.func,
  onSave: PropTypes.func,
  onSaveDraft: PropTypes.func,
  maybeButtonTitle: PropTypes.string,
  navBarRightButtonTitle: PropTypes.string,
  isLoading: PropTypes.bool,
  isLoadingDraft: PropTypes.bool,
  isFormValid: PropTypes.bool,
  hideRightButton: PropTypes.bool,
  width: PropTypes.number
};

export default Modal;

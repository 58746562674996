import React, { forwardRef } from 'react';
import PropTypes, { any, shape } from 'prop-types';
import {IconButton} from '@material-ui/core';
import { Box, makeStyles } from '@material-ui/core';
import Delete from 'src/icons/Delete';
import clsx from 'clsx';
import TaskTextEditor from 'src/components/TaskTextEditor/TaskTextEditor';
import InputBase from '@material-ui/core/InputBase';

const useStyles = makeStyles(theme => ({
  taskContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  textfield: {
    borderRadius: 8,
    background: 'white',
    border: `1px solid ${theme.palette.primary.surface}`,
    padding: theme.spacing(2),
    color: theme.palette.secondary.main,
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'Inter',
    lineHeight: 20,
    '& input::placeholder': {
      color: theme.palette.grey[400],
      opacity: 1,
      fontSize: 14,
      fontWeight: 500,
      fontFamily: 'Inter'
    },
    '& input': {
      padding: '1.5px 0px'
    }
  },
}));

const TaskCard = forwardRef(
  (
    {
      task,
      titlePlaceholder,
      descriptionPlaceholder,
      onTaskChange,
      onClickDeleteTask,
      isValid,
      simpleCheck,
      onOptionClick,
      register,
      ...rest
    },
  ) => {
  const classes = useStyles();

  const onDescriptionChange = (value) => {
    onTaskChange({
      ...task,
      description: value,
    });
  };

  const onTitleChange = (value) => {
    onTaskChange({
      ...task,
      title: value,
    });
  };


  return (
    <Box className={classes.taskContainer} width="100%" mb={3} {...rest} >
      <Box position="absolute" right={-52} top={4}>
        <IconButton
          onClick={() => onClickDeleteTask(task.draggableId)}
        >
          <Delete />
        </IconButton>
        {/* <ToolTip onClickDelete={() => onClickDeleteTask(task.id)} /> */}
      </Box>
      <InputBase
        {...rest}
        mb={2}
        onChange={event => onTitleChange(event.target.value)}
        name='taskTitle'
        defaultValue={task.title}
        classes={{
          root: clsx(classes.textfield)
        }}
        placeholder={titlePlaceholder}
        fullWidth
      />
      <TaskTextEditor
        placeholder={descriptionPlaceholder}
        raw={task.description}
        setText={onDescriptionChange}
      />
    </Box>
  );
});

TaskCard.propTypes = {
  task: shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: any,
    }),
  titlePlaceholder: PropTypes.string,
  descriptionPlaceholder: PropTypes.string,
  onTaskChange: PropTypes.func,
  onClickDeleteTask: PropTypes.func,
  id: PropTypes.string,
  onOptionClick: PropTypes.func,
  register: any,
  isValid: PropTypes.bool,
  simpleCheck: PropTypes.bool,
};

export default TaskCard;

import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowRightCentered from 'src/icons/ArrowRightCentered';

const useStyles = makeStyles(theme => ({
  gridContainerInner: {
    backgroundColor: theme.palette.grey[5],
    height: 32,
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(1),
    justifyContent: 'space-between',
    padding: theme.spacing(0, 3, 0, 2),
    paddingLeft: 0
  },
  gridItemInner: {
    alignItems: 'center',
    height: 32
  }
}));

const ListHead = ({ columns, hasIcon }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container direction="column" justify="space-between">
      <Grid item>
        <Grid container className={classes.gridContainerInner}>
          {columns?.map((title, index) => (
            <Grid
              item
              zeroMinWidth
              xs
              key={index}
              className={classes.gridItemInner}
            >
              <Typography
                style={{ marginTop: 5, marginLeft: 16 }}
                variant={'subtitle2'}
                noWrap
                color="textPrimary"
              >
                {t(`${title?.name ?? ''}`)}
              </Typography>
            </Grid>
          ))}
          {hasIcon && (
            <Box visibility="hidden">
              <IconButton>
                <ArrowRightCentered />
              </IconButton>
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

ListHead.propTypes = {
  columns: PropTypes.array,
  hasIcon: PropTypes.func
};

export default ListHead;

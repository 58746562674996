import { any, func } from 'prop-types';
import React from 'react';
import StreamCard from './StreamCard';

export const DisplayAllStreams = ({ streams, onDeleteClick, onEditClick }) =>
  streams?.map((element, index) => (
    <StreamCard
      key={element.id}
      title={element.name}
      postsCount={element.postsCount}
      locationsCount={element.locationsCount}
      teamsCount={element.teamsCount}
      usersCount={element.usersCount}
      roles={[element.roles.join(', ')]}
      onDeleteClick={() => onDeleteClick(element)}
      onEditClick={() => onEditClick(element)}
    />
  ));

DisplayAllStreams.propTypes = {
  streams: any,
  onDeleteClick: func,
  onEditClick: func
};

import { firestore, getCompanyRef } from 'src/api/firebase';
import { getTimestamp, truncateDraft } from '../utils';

export const addChecklist = ({ companyId, ...checklist }) => {
  var batch = firestore.batch();
  const root = getCompanyRef(companyId);

  const {
    title,
    description,
    frequency,
    isDraft = false,
    isPublished = true,
    accessLocations,
    accessTeams,
    notificationFrequency,
    notificationTeams,
    allTeamsAccess,
    allLocationsAccess,
    tasks,
  } = checklist;

  const checklistFrequency = frequency[0]?.id;

  const notificationFrequencyObject = {
    teamIds: notificationTeams.map(nt => nt.id),
    frequency: notificationFrequency[0]?.id,
  };

  const checklistDocId = root.collection('checklists').doc().id;
  const checklistRef = root.collection('checklists').doc(checklistDocId);

  const createdAt = getTimestamp();
  const updatedAt = getTimestamp();


  const newChecklist = {
    id: checklistDocId,
    title,
    description,
    checklistFrequency,
    locationIds: accessLocations.map(location => location.id),
    teamIds: accessTeams.map(team => team.id),
    notificationFrequency: notificationFrequencyObject,
    taskIds: [],
    isDraft,
    isPublished,
    allTeamsAccess,
    allLocationsAccess,
    createdAt,
    updatedAt,
  };

  // Set the Checklist document
  batch.set(checklistRef, newChecklist, { merge: true });

  // Add tasks to subcollection under the new Checklist document
  tasks.forEach((task, index) => {
    const taskRef = checklistRef.collection('tasks').doc();
    const taskData = {
      id: taskRef.id,
      title: task.title,
      createdAt,
      updatedAt,
      description: task?.description ?? '',
      truncatedDescription: task?.description ? truncateDraft(task.description) : '',
      index,
    };

    newChecklist.taskIds.push(taskRef.id); // Keep track of task IDs to store in the Checklist document
    batch.set(taskRef, taskData, { merge: true });
  });

  // After all tasks are added, update the Checklist document with taskIds
  batch.update(checklistRef, { taskIds: newChecklist.taskIds });

  // Commit the batch
  return batch.commit();
};

import React from 'react';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { string } from 'prop-types';

const IndexView = ({ title }) => {
  const { t } = useTranslation();

  return (
    <Page title={`Sqillz - ${title}`}>
      <Outlet />
    </Page>
  );
};

IndexView.propTypes = {
  title: string
};

export default IndexView;

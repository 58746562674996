import React from 'react';
import { SvgIcon } from '@material-ui/core';

const QuickPostDocument = props => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_2458_70193)">
        <path
          d="M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM6 20V4H13V9H18V20H6Z"
          fill="#6E26FC"
        />
        <path d="M4 2H6V4H4V2Z" fill="#6E26FC" />
        <path d="M4 20H6V22H4V20Z" fill="#6E26FC" />
        <path d="M18 20H20V22H18V20Z" fill="#6E26FC" />
      </g>
      <defs>
        <clipPath id="clip0_2458_70193">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default QuickPostDocument;

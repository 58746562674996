import {
  Box,
  CircularProgress,
  makeStyles,
  useTheme,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  trail: {},
  main: {
    position: 'absolute',
    left: 0
  },
  circle: {
    strokeLinecap: 'round'
  },
  textInsideCircle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

export const ProgressCircle = ({
  value,
  values,
  maybeSize,
  maybeTrailColor,
  maybeMainColor,
  maybeMainInsideText,
  maybeSubtitleInsideText,
  trailClass,
  singleColor
}) => {
  const size = maybeSize ?? 22;
  const classes = useStyles();
  const theme = useTheme();

  const getColor = (index, length) => {
    switch (index) {
      case 0:
        return length === 1
          ? maybeTrailColor || theme.palette.primary.main
          : theme.palette.primary.light;
      case 1:
        return length > 2
          ? theme.palette.primary.mediumLight
          : theme.palette.primary.main;
      case 2:
        return maybeMainColor || theme.palette.primary.main;
    }
  };
  const mapValueToProgress = (item, index) => {
    return index === 0
      ? 100
      : index === 1 && values.length > 2
      ? item.valueInPercentage + values[index + 1]?.valueInPercentage
      : item.valueInPercentage;
  };

  return (
    <div className={classes.root}>
      <Box position="relative" display="inline-flex">
        <CircularProgress
          value={100}
          variant="determinate"
          style={{ width: size, height: size }}
          // className={classes.trail}
          className={clsx(classes.trail, trailClass)}
        />
        {!!value && (
          <CircularProgress
            value={value}
            variant="determinate"
            style={{
              width: size,
              height: size,
              color: singleColor
            }}
            className={clsx(classes.main, trailClass)}
            classes={{ circle: classes.circle }}
          />
        )}
        {values &&
          values?.map((item, index) => (
            <CircularProgress
              key={index}
              value={
                values.length > 1
                  ? mapValueToProgress(item, index)
                  : item?.valueInPercentage
              }
              variant="determinate"
              style={{
                width: size,
                height: size,
                color:
                  index === 0 && values?.legend?.length > 1
                    ? theme.palette.primary.mediumLight
                    : getColor(index, values?.length)
              }}
              className={clsx(classes.main, trailClass)}
              classes={{ circle: classes.circle }}
            />
          ))}
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <div className={classes.textInsideCircle}>
            <Typography variant="h2">{maybeMainInsideText}</Typography>
            <Typography
              variant="subtitle1"
              style={{ fontSize: 14, fontWeight: '600' }}
            >
              {maybeSubtitleInsideText}
            </Typography>
          </div>
        </Box>
      </Box>
    </div>
  );
};

ProgressCircle.propTypes = {
  value: PropTypes.number.isRequired,
  maybeSize: PropTypes.number,
  maybeTrailColor: PropTypes.string,
  maybeMainColor: PropTypes.string,
  maybeMainInsideText: PropTypes.string,
  maybeSubtitleInsideText: PropTypes.string,
  values: PropTypes.array,
  trailClass: PropTypes.string,
  singleColor: PropTypes.string
};

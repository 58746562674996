import React from 'react';
import Close from 'src/icons/Close';
import PriorityHigh from 'src/icons/PriorityHigh';
import { toast } from 'react-toastify';
import Check from 'src/icons/Check';

const useToast = () => {
  const onError = (message, onClose) =>
    toast.error(message ?? t('err.invalidCode'), {
      type: 'error',
      theme: 'dark',
      toastId: 'error-toast',
      autoClose: 5000,
      icon: () => <PriorityHigh color="white" style={{ color: 'white' }} />,
      closeButton: () => <Close color="white" style={{ fontSize: 16 }} />,
      onClose: typeof onClose === 'function' && onClose
    });

  const onSuccess = (message, onClose) =>
    toast.success(message ?? t('success'), {
      type: 'success',
      theme: 'dark',
      className: 'success',
      autoClose: 5000,
      toastId: 'success-toast',
      icon: () => <Check style={{ color: 'white' }} />,
      closeButton: () => <Close color="white" style={{ fontSize: 16 }} />,
      onClose: typeof onClose === 'function' && onClose
    });

  const onInfo = (message, onClose) =>
    toast.success(message ?? t('success'), {
      type: 'info',
      theme: 'light',
      className: 'info',
      autoClose: 5000,
      toastId: 'info-toast',
      icon: () => <Check style={{ color: '#0B0B0C' }} />,
      closeButton: () => <Close color="#0B0B0C" style={{ fontSize: 16 }} />,
      onClose: typeof onClose === 'function' && onClose
    });

  return { onError, onSuccess, onInfo };
};

export default useToast;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import QuickPostTableList from '../quick-post/QuickPostTableList';
import ActionModal from 'src/components/ActionModal';
import { useTranslation } from 'react-i18next';
import { deleteDocument } from 'src/api/documents/deleteDocument';
import { useCompanyId } from 'src/api/company/useCompanyId';
import { useLoading } from 'src/hooks';
import useToast from 'src/hooks/useToast';

export const DocumentsSubView = ({
  displayAddDocumentModal,
  handleClose,
  documents
}) => {
  const columns = [{ name: 'title' }, { name: 'date' }, { name: 'uploadedBy' }];
  const { t } = useTranslation();
  const [docToDelete, setDocToDelete] = useState(null);
  const { isLoading, withLoading } = useLoading();
  const { onError, onSuccess } = useToast();

  const { companyId } = useCompanyId();

  const resetDocState = () => setDocToDelete(null);

  const shouldDeleteDocument = async () => {
    try {
      await withLoading(() => deleteDocument({ companyId, ...docToDelete }));
      resetDocState();
      onSuccess('Document deleted');
    } catch (error) {
      onError('Error deleting document');
    }
  };

  const handleIconClick = item => {
    setDocToDelete(item);
  };

  return (
    <>
      <QuickPostTableList
        data={documents}
        columns={columns}
        avatar={true}
        buttonIcon="delete"
        onIconButtonClick={handleIconClick}
        onListItemClick={handleIconClick}
      />
      {docToDelete && (
        <ActionModal
          open
          onPrimaryButtonPress={shouldDeleteDocument}
          onSecondaryButtonPress={resetDocState}
          title={t('deleteDocumentConfirmation')}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

DocumentsSubView.propTypes = {
  displayAddDocumentModal: PropTypes.bool,
  handleClose: PropTypes.func,
  documents: PropTypes.any //TODO: add shape
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { updateCompany } from 'src/api/company/updateCompany';
import { useCompanyId } from 'src/api/company/useCompanyId';
import useCompanyObserver from 'src/api/company/useCompanyObserver';
import CircleIndicator from 'src/components/CircleIndicator';
import useMediaUpload from 'src/hooks/useMediaUpload';
import ViewHeader from 'src/components/ViewHeader';
import UploadIcon from 'src/icons/UploadIcon';
import TextField from 'src/components/TextField';
import styled from 'styled-components';
import ModalNavBar from 'src/components/Modals/ModalNavBar';
import LoadingIndicator from 'src/components/LoadingIndicator/LoadingIndicator';
import useToast from 'src/hooks/useToast';

const SettingsView = () => {
  const [company, isLoading] = useCompanyObserver();
  const [companyName, setCompanyName] = useState(company?.name);
  const [previewImageUrl, setPreviewImageUrl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [{ url, duration }, error] = useMediaUpload(uploadedFile);
  const { companyId } = useCompanyId();
  const hiddenFileInput = useRef(null);
  const { t } = useTranslation();
  const title = t('settings');
  const classes = useStyles();
  const { onSuccess, onError, onInfo } = useToast();

  const shouldUpdateCompany = useCallback(async ({ name, logoURL }) => {
    const dataToUpdate = JSON.parse(JSON.stringify({ name, logoURL }));
    try {
      await updateCompany({
        companyId,
        ...dataToUpdate
      });
      onInfo(t('changesSuccess'));
      setUploadedFile(null);
    } catch (error) {
      onError(t('error.updateFailed'));
    }
  }, []);

  useEffect(() => {
    if (url) {
      shouldUpdateCompany({ name: companyName, logoURL: url });
    }
  }, [url]);

  useEffect(() => {
    return () => {
      setSelectedFile(null), setPreviewImageUrl(null);
    };
  }, []);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const determineCompanyNameLength =
    companyName?.length || companyName?.length === 0
      ? companyName?.length
      : company?.name?.length;

  const handleUpload = event => {
    if (['jpg', 'jpeg', 'png'].includes(selectedFile.name.slice(-3))) {
      setUploadedFile(selectedFile);
    } else {
      onError(t('invalidFileType'));
    }
  };

  const onSaveClick = async () => {
    if (selectedFile) {
      handleUpload();
    } else {
      shouldUpdateCompany({ name: companyName });
    }
  };

  const validation = () => {
    const nameHasChanged = companyName === company?.name;
    const dataManipulated =
      (!companyName?.length && !previewImageUrl) || nameHasChanged;
    const textIsBlank = determineCompanyNameLength === 0;
    const textIsTooLong = !(determineCompanyNameLength <= 20);
    const disabledButton = textIsTooLong || textIsBlank || dataManipulated;
    return !disabledButton;
  };

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    setSelectedFile(selectedFile);

    const previewUrl = URL.createObjectURL(selectedFile);
    setPreviewImageUrl(previewUrl);
  };

  return (
    <>
      <input
        style={{ display: 'none' }}
        type="file"
        onChange={handleFileChange}
        name="img"
        accept=".png, .jpeg"
        ref={hiddenFileInput}
      />
      <Box position="absolute" top={56} right={56}>
        <ModalNavBar onSave={onSaveClick} isFormValid={validation()} />
      </Box>
      <ViewHeader title={title} />
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <>
          <div className={classes.textFieldTitle}>
            <CircleIndicator
              isValid={
                determineCompanyNameLength <= 20 &&
                determineCompanyNameLength != 0
              }
            />
            <Typography variant="subtitle2">{t('companyName')}</Typography>
          </div>
          <div className={classes.textFieldTitle2}>
            <Typography color="textSecondary" variant="subtitle2">
              {t('companyInfos')}
            </Typography>
          </div>
          <Box>
            <Box ml={3} maxWidth={700}>
              <TextField
                defaultValue={company?.name}
                placeholder={t('giveCompanyName')}
                onChange={e => setCompanyName(e.target.value)}
              />

              <Typography
                style={{
                  marginTop: 8,
                  float: 'right',
                  color:
                    determineCompanyNameLength <= 20 &&
                    determineCompanyNameLength != 0
                      ? '#9A98A3'
                      : 'red'
                }}
                variant="subtitle2"
              >
                {determineCompanyNameLength + '/20'}
              </Typography>
            </Box>
            <div className={classes.textFieldTitle}>
              <CircleIndicator isValid={previewImageUrl || company?.logoURL} />
              <Typography variant="subtitle2">{t('Logotype')}</Typography>
            </div>
            <div className={classes.textFieldTitle2}>
              <Typography color="textSecondary" variant="subtitle2">
                {t('logoInfo')}
              </Typography>
            </div>
            <ButtonBase
              focusRipple
              className={classes.image}
              onClick={() => handleClick()}
              style={{
                width: 256,
                borderRadius: 8,
                overflow: 'hidden',
                marginLeft: 30,
                marginTop: 16
              }}
            >
              {previewImageUrl || company?.logoURL ? (
                <Image src={previewImageUrl || company?.logoURL} />
              ) : (
                <div className={classes.uploadBorder}>
                  <div className={classes.subUploadBorder}>
                    <UploadIcon style={{ fontSize: 16 }} />
                    <Box width={11} />
                    <Typography
                      variant="subtitle2"
                      className={classes.uploadText}
                    >
                      {t('UPLOAD LOGO')}
                    </Typography>
                  </div>
                </div>
              )}
            </ButtonBase>
          </Box>
        </>
      )}
    </>
  );
};

export default SettingsView;

const useStyles = makeStyles(theme => ({
  textFieldTitle: {
    color: '#323138',
    display: 'flex',
    fontFamily: 'Inter',
    alignItems: 'center',
    marginBottom: '1rem',
    marginTop: '2rem'
  },
  uploadText: {
    fontWeight: 700,
    color: '#676570',
    fontSize: 11
  },
  textFieldTitle2: {
    display: 'flex',
    fontFamily: 'Inter',
    alignItems: 'center',
    marginBottom: '1rem',
    color: '#9A98A3',
    marginLeft: 30
  },
  uploadBorder: {
    backgroundColor: 'white',
    border: '1px solid #F5F5F5',
    borderRadius: '5%',
    width: '256px',
    height: '230px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  previewImg: {
    display: 'flex',
    alignItems: 'center'
  },
  subUploadBorder: {
    cursor: 'pointer',
    backgroundColor: '#F8FAFD',
    height: '198px',
    width: '224px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'contain',
    backgroundPosition: 'center 80%'
  },
  image: {
    height: 230,
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15
      },
      '& $imageMarked': {
        opacity: 0
      },
      '& $imageTitle': {
        border: '4px solid currentColor'
      }
    }
  }
}));

const Image = styled.img`
  width: 256px;
  height: 230px;
  object-fit: contain;
  border-radius: 12px;
`;
